import { TCommonData, TWidgetProps } from '@core/types';
import React from 'react';
import { getWidgetById } from '../widgetUtils';

const Widget: React.FC<TWidgetProps<TCommonData>> = ({ widget, hooks }) => {
    const getWidget = () => {
        const widgetById = getWidgetById(widget.id, widget, hooks);
        if (widgetById) {
            return widgetById;
        } else {
            const widgetByAlternativeId = getWidgetById(widget.alternativeId, widget, hooks);
            return widgetByAlternativeId;
        }
    };

    return getWidget();
};
export default Widget;
