import React from 'react';

type TConditionalRender = {
    children: React.ReactNode;
    condition?: boolean;
};
const ConditionalRender = ({ condition, children }: TConditionalRender) => {
    if (!condition) {
        return null;
    }
    return children;
};
export { ConditionalRender };
