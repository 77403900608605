import {
    EventTypeEnum,
    IRootState,
    TButtonOption,
    TextInputEnum,
    THandleChangeProps,
    TInputData,
    TInputOption,
    TInputPhoneData,
    TItem,
    TUseCheckBoxHook,
    TUseInput,
    TUseInputPhone,
    TWAction,
    TWidget,
} from '@core/types';
import { eventBus } from '@core/utils';
import React, { useEffect, useState } from 'react';
import { selectMutableWidget, updateWidgetValue } from '../features/scenario.slice';
import { fetchScenario } from '../features/utils/sharedActions';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { useApp } from './app';

const isInputValid = (value: string, mandatory?: boolean, regex?: string): boolean => {
    let isValid = true;
    const trimValue = typeof value === 'string' ? value.trim() : value;
    if (mandatory && !regex) {
        isValid = !!trimValue;
    } else if (mandatory && regex) {
        try {
            const valid = new RegExp(regex);
            isValid = valid.test(trimValue);
        } catch (e) {
            console.log('Wrong regexp expression: ', regex);
            isValid = false;
        }
    } else if (mandatory === false && regex && trimValue) {
        try {
            const valid = new RegExp(regex);
            isValid = !valid.test(trimValue);
        } catch (e) {
            console.log('Wrong regexp expression: ', regex);
            isValid = true;
        }
    }
    return isValid;
};
export const isInputValidForTest = (value: string, mandatory?: boolean, regex?: string): boolean => isInputValid(value, mandatory, regex);
export const useInputPhone = (widget: TWidget<TInputPhoneData, TInputOption>): TUseInputPhone => {
    const dispatch = useAppDispatch();
    const { data, internalId, wid } = widget;
    let countryPrefix = '';
    if (Array.isArray(data?.items) && data?.items?.length > 0) {
        const currentItem = data.items.filter((i) => i.metaText === data.value)[0];
        countryPrefix = currentItem?.icon ?? '';
    }
    const [iso2, setIso2] = useState<string>(countryPrefix);

    const handleChangeInputPhone = (value: string, data: Record<string, string>, event?: React.SyntheticEvent<EventTarget>, formattedValue?: string) => {
        const clearFormattedValue = formattedValue?.replace(/[\s-]/g, '') ?? '';
        dispatch(updateWidgetValue({ value: clearFormattedValue, wid }));
        setIso2(data?.countryCode);
        if (internalId) {
            eventBus.emit(EventTypeEnum.VALUE, internalId, clearFormattedValue);
        }
    };
    return {
        iso2,
        handleChangeInputPhone,
    };
};
export const useInput = (widget: TWidget<TInputData, TInputOption>): TUseInput => {
    const {
        wid,
        options,
        id,
        internalId,
        data: { mandatory, regex },
    } = widget;
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const { setModal } = useApp();
    const input = useAppSelector((state) => selectMutableWidget(state, wid));
    const valueStr = input?.data?.value as string;
    const value = valueStr ?? '';
    const [isError, setError] = useState(input?.options?.isError);
    const errorMessage = input?.data?.errorMessage;
    const [isFilled, setIsFilled] = useState<boolean>(!!value);

    useEffect(() => {
        let timerId: NodeJS.Timeout;
        if (internalId) {
            timerId = setTimeout(() => {
                eventBus.emit(EventTypeEnum.IS_VALID, internalId, isInputValid(value, mandatory, regex));
            }, 100);
        }
        return () => {
            if (timerId) {
                clearTimeout(timerId);
            }
        };
    }, [internalId]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, handleChangeProps?: THandleChangeProps) => {
        let value = e?.target?.value;
        if ((e?.target.value && options?.inputType === 'number') || (e?.target.value && id === TextInputEnum.ENTERPASSCODE)) {
            value = e?.target.value.replace(/\D*/g, '');
        }
        dispatch(updateWidgetValue({ value, wid }));
        if (internalId) {
            eventBus.emit(EventTypeEnum.VALUE, internalId, value);
            eventBus.emit(EventTypeEnum.IS_VALID, internalId, isInputValid(value, mandatory, regex));
        }
        handleChangeProps?.(value, input, (action: TWAction) => dispatch(fetchScenario({ action })));
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const value = e?.target?.value;
        setError(!isInputValid(value, mandatory, regex));
        setIsFocused(false);
        setIsFilled(!!value);
    };

    const handleFocus = () => {
        setIsFilled(true);
        setIsFocused(true);
        setError(false);
    };
    const handleClickDropdown = (item: TItem) => {
        setModal(null);
        const selectedValue = (item.value as string) ?? item.title;
        setIsFilled(!!selectedValue);
        setError(!isInputValid(selectedValue, mandatory, regex));
        dispatch(updateWidgetValue({ value: selectedValue, wid }));
        if (internalId) {
            eventBus.emit(EventTypeEnum.VALUE, internalId, selectedValue);
        }
    };

    return {
        value,
        isFocused,
        isFilled,
        isError,
        errorMessage,
        handleChange,
        handleBlur,
        handleFocus,
        handleClickDropdown,
    };
};

export const useCheckBox = (widget: TWidget<TInputData, TInputOption & TButtonOption>): TUseCheckBoxHook => {
    const { wid, internalId } = widget;
    const dispatch = useAppDispatch();
    const checkbox = useAppSelector((state: IRootState) => selectMutableWidget(state, wid));
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = String(e?.target?.checked);
        dispatch(updateWidgetValue({ value, wid }));
        if (internalId) {
            eventBus.emit(EventTypeEnum.VALUE, internalId, value);
        }
    };
    return { handleChange, isChecked: Boolean(checkbox?.data?.value === 'true') };
};
