import { Loading } from '@consumer/ui';
import { AnimationIconEnum, TCommonData, TGroupWidgetProps } from '@core/types';
import React, { lazy, Suspense } from 'react';

const SuccessAnimation = lazy(() => import('./src/SuccessAnimation'));
const ErrorAnimation = lazy(() => import('./src/ErrorAnimation'));

const TypeLottie: React.FC<TGroupWidgetProps<TCommonData>> = ({ widgetId, wid, hooks }) => {
    const { widget } = hooks.useGetWidget(wid);
    const render = () => {
        switch (widgetId) {
            case AnimationIconEnum.Success:
                return <SuccessAnimation widget={widget} hooks={hooks} />;
            case AnimationIconEnum.Error:
                return <ErrorAnimation widget={widget} hooks={hooks} />;
            default:
                return false;
        }
    };
    return <Suspense fallback={<Loading />}>{render()}</Suspense>;
};

export default TypeLottie;
