import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { fonts } from '@core/theme';
import { WithPrefix } from '@core/types';

interface ISelectorProps {
    disabled?: boolean;
}

type StyledComponentProps = WithPrefix<ISelectorProps>;

export const CommonSelector = styled(Link)<StyledComponentProps>`
    font-family: ${fonts.regular};
    border: none;
    width: 100%;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    outline: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    vertical-align: top;
    cursor: ${(props) => (props.$disabled ? 'default' : 'pointer')};
    pointer-events: auto;
    font-size: 17px;
    line-height: 24px;
    border-radius: 0;
    padding: 16px 24px;
    min-height: 72px;
    text-align: left;
    color: ${(props) => props.theme.black};
    background: ${(props) => props.theme.white};

    &:hover {
        background: ${(props) => (props.$disabled ? 'none' : props.theme.grey_8_lc)};
    }
`;
export const VisualWrapper = styled.div`
    position: relative;
    display: flex;
`;
export const SelectedBadge = styled.div<ISelectorProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 16px);
    height: 100%;
    border-radius: 50%;
    background: ${(props) => props.theme.white_72_tr};
    border: 1px solid ${(props) => props.theme.purple_40_tr};
    box-sizing: border-box;
`;
export const RowContent = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;
