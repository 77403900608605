import RowComponent from '@consumer/type-row';
import { Separator } from '@consumer/type-separator';
import { Modal } from '@consumer/ui';
import { RowEnum, TButtonData, THooks, TListData, TListOption, TWidgetProps } from '@core/types';
import { ConditionalRender, convertItemToRowComponentProps } from '@core/utils';
import React from 'react';
import InputSearch from '../InputSearch';
import ListItems, { TListItemProps } from '../ListItems';
import { FirstLetter, FirstLetterRow } from '../styles';
import ListSearch from './ListSearch';
import ListSectionItems from './ListSectionItems';

type TListSelect = {
    isOpenInModal?: boolean;
    rowId?: RowEnum.SELECTOR | RowEnum.ROW_REGULAR;
} & TWidgetProps<TListData, TListOption>;
const ListSelect: React.FC<TListSelect> = ({ widget, isOpenInModal = false, rowId, hooks }) => {
    const { data } = widget;
    const searchWidget = { ...widget, options: {} };
    const { handleClick } = hooks.useList(widget);
    const { setModal } = hooks.useApp();

    const closeModal = () => {
        if (isOpenInModal) {
            setModal(
                <Modal isCanCloseModal={true} iconName="ic_arrow_back" onHandleClick={() => setModal(null)} hooks={hooks as unknown as THooks<TButtonData>}>
                    <ListSelect widget={widget} hooks={hooks} isOpenInModal={isOpenInModal} rowId={rowId} />
                </Modal>,
            );
        } else {
            setModal(null);
        }
    };

    const renderItem = ({ item, firstLetter, addLetter }: TListItemProps) => {
        return (
            <React.Fragment key={item.id}>
                <ConditionalRender condition={addLetter}>
                    <FirstLetterRow>
                        <FirstLetter>{firstLetter}</FirstLetter>
                    </FirstLetterRow>
                </ConditionalRender>
                <RowComponent {...convertItemToRowComponentProps(item, rowId)} handleClick={() => handleClick(widget.wid, item)} />
            </React.Fragment>
        );
    };

    const onInputSearchHandleClick = () => {
        setModal?.(
            <Modal isCanCloseModal={false} iconName="ic_arrow_back" hooks={hooks as unknown as THooks<TButtonData>}>
                <ListSearch widget={widget} renderItem={renderItem} hooks={hooks} closeModal={closeModal} />
            </Modal>,
        );
    };

    if (data?.items && Array.isArray(data.items) && data.items.length > 0) {
        return (
            <>
                <ListSectionItems data={data} renderItem={renderItem} />
                <ConditionalRender condition={!!data?.allItemsHeader}>
                    <Separator isWidget={false} separatorVariant={'subheader'} text={data?.allItemsHeader?.toLowerCase()} />
                </ConditionalRender>
                <ConditionalRender condition={data.items.length > 10}>
                    <InputSearch
                        widget={searchWidget}
                        asButton={true}
                        handleClick={onInputSearchHandleClick}
                        hooks={hooks}
                        closeModal={closeModal}
                        marginBottom={24}
                        marginLeft={24}
                        marginRight={24}
                    />
                </ConditionalRender>
                <ListItems items={data.items} renderItem={renderItem} sortByAlphabet={widget.options.sortByAlphabet} />
            </>
        );
    }
    return null;
};

export default ListSelect;
