import React from 'react';
import styled from 'styled-components';
import { FlagIcon } from '@consumer/ui';

export type TBadgeProps = {
    flagIcon?: string;
    size?: 'small' | 'medium';
    disabled?: boolean;
};

const BadgeContainer = styled.span<TBadgeProps>`
    display: inline-block;
    border-radius: 50%;
    background: ${({ theme }) => theme.white};
    border: 2px solid ${({ theme }) => theme.white};
    width: ${(props) => (props.size === 'medium' ? '20px' : '16px')};
    height: ${(props) => (props.size === 'medium' ? '20px' : '16px')};
`;

export const Badge = (props: TBadgeProps) => {
    return (
        <BadgeContainer size={props.size}>
            <FlagIcon name={props.flagIcon} size={props.size === 'medium' ? '20px' : '16px'} isDisabled={props.disabled} />
        </BadgeContainer>
    );
};
