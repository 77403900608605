import { ReactComponent as ic_alert_circle_small } from './svg/ic_alert_circle_small.svg';
import { ReactComponent as ic_arrow_back } from './svg/ic_arrow_back.svg';
import { ReactComponent as ic_arrow_down } from './svg/ic_arrow_down.svg';
import { ReactComponent as ic_arrow_down_left } from './svg/ic_arrow_down_left.svg';
import { ReactComponent as ic_arrow_forward } from './svg/ic_arrow_forward.svg';
import { ReactComponent as ic_arrow_forward_circle } from './svg/ic_arrow_forward_circle.svg';
import { ReactComponent as ic_arrow_retry } from './svg/ic_arrow_retry.svg';
import { ReactComponent as ic_arrow_rounded_back } from './svg/ic_arrow_rounded_back.svg';
import { ReactComponent as ic_arrow_up } from './svg/ic_arrow_up.svg';
import { ReactComponent as ic_arrow_up_right } from './svg/ic_arrow_up_right.svg';
import { ReactComponent as ic_arrows_exchange } from './svg/ic_arrows_exchange.svg';
import { ReactComponent as ic_arrows_recurring } from './svg/ic_arrows_recurring.svg';
import { ReactComponent as ic_arrows_transfers } from './svg/ic_arrows_transfers.svg';
import { ReactComponent as ic_bank } from './svg/ic_bank.svg';
import { ReactComponent as ic_camera } from './svg/ic_camera.svg';
import { ReactComponent as ic_card } from './svg/ic_card.svg';
import { ReactComponent as ic_cardp_plus } from './svg/ic_cardp_plus.svg';
import { ReactComponent as ic_cash } from './svg/ic_cash.svg';
import { ReactComponent as ic_check } from './svg/ic_check.svg';
import { ReactComponent as ic_check_circle } from './svg/ic_check_circle.svg';
import { ReactComponent as ic_check_circle_small } from './svg/ic_check_circle_small.svg';
import { ReactComponent as ic_check_circle_thin } from './svg/ic_check_circle_thin.svg';
import { ReactComponent as ic_chevron_down } from './svg/ic_chevron_down.svg';
import { ReactComponent as ic_chevron_down_big } from './svg/ic_chevron_down_big.svg';
import { ReactComponent as ic_chevron_down_small } from './svg/ic_chevron_down_small.svg';
import { ReactComponent as ic_chevron_right } from './svg/ic_chevron_right.svg';
import { ReactComponent as ic_chevron_right_big } from './svg/ic_chevron_right_big.svg';
import { ReactComponent as ic_chevron_up } from './svg/ic_chevron_up.svg';
import { ReactComponent as ic_chevron_up_big } from './svg/ic_chevron_up_big.svg';
import { ReactComponent as ic_clock_filled_small } from './svg/ic_clock_filled_small.svg';
import { ReactComponent as ic_clock_thin } from './svg/ic_clock_thin.svg';
import { ReactComponent as ic_cloud } from './svg/ic_cloud.svg';
import { ReactComponent as ic_coins } from './svg/ic_coins.svg';
import { ReactComponent as ic_document } from './svg/ic_document.svg';
import { ReactComponent as ic_dots } from './svg/ic_dots.svg';
import { ReactComponent as ic_dotted_list } from './svg/ic_dotted_list.svg';
import { ReactComponent as ic_ghost } from './svg/ic_ghost.svg';
import { ReactComponent as ic_gift } from './svg/ic_gift.svg';
import { ReactComponent as ic_info_circle } from './svg/ic_info_circle.svg';
import { ReactComponent as ic_info_circle_small } from './svg/ic_info_circle_small.svg';
import { ReactComponent as ic_life_buoy } from './svg/ic_life_buoy.svg';
import { ReactComponent as ic_passcode_dot } from './svg/ic_passcode_dot.svg';
import { ReactComponent as ic_pencil } from './svg/ic_pencil.svg';
import { ReactComponent as ic_people } from './svg/ic_people.svg';
import { ReactComponent as ic_person } from './svg/ic_person.svg';
import { ReactComponent as ic_person_camera_medium } from './svg/ic_person_camera_medium.svg';
import { ReactComponent as ic_person_medium } from './svg/ic_person_medium.svg';
import { ReactComponent as ic_picture } from './svg/ic_picture.svg';
import { ReactComponent as ic_plus } from './svg/ic_plus.svg';
import { ReactComponent as ic_plus_circle } from './svg/ic_plus_circle.svg';
import { ReactComponent as ic_plus_minus } from './svg/ic_plus_minus.svg';
import { ReactComponent as ic_power } from './svg/ic_power.svg';
import { ReactComponent as ic_search } from './svg/ic_search.svg';
import { ReactComponent as ic_settings } from './svg/ic_settings.svg';
import { ReactComponent as ic_shopping_bag } from './svg/ic_shopping_bag.svg';
import { ReactComponent as ic_speedometer } from './svg/ic_speedometer.svg';
import { ReactComponent as ic_trash } from './svg/ic_trash.svg';
import { ReactComponent as ic_truck } from './svg/ic_truck.svg';
import { ReactComponent as ic_wallet } from './svg/ic_wallet.svg';
import { ReactComponent as ic_x } from './svg/ic_x.svg';
import { ReactComponent as ic_x_circle } from './svg/ic_x_circle.svg';
import { ReactComponent as ic_x_circle_small } from './svg/ic_x_circle_small.svg';
import { ReactComponent as ic_x_circle_thin } from './svg/ic_x_circle_thin.svg';

import { ReactComponent as paysystem_visa } from './paysystems/paysystem_visa.svg';
import { ReactComponent as paysystem_mastecard } from './paysystems/paysystem_mastecard.svg';
import { ReactComponent as paysystem_mir } from './paysystems/paysystem_mir.svg';
import { ReactComponent as paysystem_unionpay } from './paysystems/paysystem_unionpay.svg';
import { ReactComponent as paysystem_napas } from './paysystems/paysystem_napas.svg';
import { ReactComponent as paysystem_verve } from './paysystems/paysystem_verve.svg';
import { ReactComponent as paysystem_elcart } from './paysystems/paysystem_elcart.svg';
import { ReactComponent as paysystem_uzcard } from './paysystems/paysystem_uzcard.svg';
import { ReactComponent as paysystem_humo } from './paysystems/paysystem_humo.svg';
import { ReactComponent as paysystem_troy } from './paysystems/paysystem_troy.svg';
import { ReactComponent as paysystem_discover } from './paysystems/paysystem_discover.svg';
import { ReactComponent as paysystem_kortimilli } from './paysystems/paysystem_kortimilli.svg';
import { ReactComponent as paysystem_amex } from './paysystems/paysystem_amex.svg';
import { ReactComponent as paysystem_arca } from './paysystems/paysystem_arca.svg';

import { ReactComponent as paysend } from './paysystems/paysend.svg';
import { ReactComponent as mastercard } from './paysystems/mastercard.svg';

export {
    ic_alert_circle_small,
    ic_arrow_back,
    ic_arrow_down,
    ic_arrow_down_left,
    ic_arrow_forward,
    ic_arrow_forward_circle,
    ic_arrow_retry,
    ic_arrow_rounded_back,
    ic_arrow_up,
    ic_arrow_up_right,
    ic_arrows_exchange,
    ic_arrows_recurring,
    ic_arrows_transfers,
    ic_bank,
    ic_camera,
    ic_card,
    ic_cardp_plus,
    ic_cash,
    ic_check,
    ic_check_circle,
    ic_check_circle_small,
    ic_check_circle_thin,
    ic_chevron_down,
    ic_chevron_down_big,
    ic_chevron_down_small,
    ic_chevron_right,
    ic_chevron_right_big,
    ic_chevron_up,
    ic_chevron_up_big,
    ic_clock_filled_small,
    ic_clock_thin,
    ic_cloud,
    ic_coins,
    ic_document,
    ic_dots,
    ic_dotted_list,
    ic_ghost,
    ic_gift,
    ic_info_circle,
    ic_info_circle_small,
    ic_life_buoy,
    ic_passcode_dot,
    ic_pencil,
    ic_people,
    ic_person,
    ic_person_camera_medium,
    ic_person_medium,
    ic_picture,
    ic_plus,
    ic_plus_circle,
    ic_plus_minus,
    ic_power,
    ic_search,
    ic_settings,
    ic_shopping_bag,
    ic_speedometer,
    ic_trash,
    ic_truck,
    ic_wallet,
    ic_x,
    ic_x_circle,
    ic_x_circle_small,
    ic_x_circle_thin,
    paysystem_visa,
    paysystem_mastecard,
    paysystem_mir,
    paysystem_unionpay,
    paysystem_napas,
    paysystem_verve,
    paysystem_elcart,
    paysystem_uzcard,
    paysystem_humo,
    paysystem_troy,
    paysystem_discover,
    paysystem_kortimilli,
    paysystem_amex,
    paysystem_arca,
    paysend,
    mastercard,
};
