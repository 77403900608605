import React from 'react';
import styled from 'styled-components';
import { ButtonCircle, ButtonRow } from '@consumer/type-button';
import { PopupComponent, TextCommon } from '@consumer/ui';
import { SplitText } from '@core/utils';
import { ActionActionTypeEnum, ECalculatorErrors, TCalculatorError, TSetFunc, TWAction } from '@core/types';
import { InputEnum, TErrorsResult, THandleUpdateValue } from '../utils/widgets';

const ErrorWrapper = styled.span`
    display: inline-flex;
    align-items: center;
    min-height: 28px;
    padding: 8px 6px 8px 8px;
    margin-top: 4px;
`;
type TCalculatorErrorProps = {
    setPopup?: TSetFunc;
    handleUpdateValue: THandleUpdateValue;
    fetchScenario: (action: TWAction) => void;
    actions?: TWAction[];
    shortText?: string;
    longText?: string;
    name: keyof TErrorsResult;
    newInputValue?: string;
    type?: ECalculatorErrors;
} & Pick<TCalculatorError, 'changeDeliveryOptions' | 'gotIt' | 'changeAmount' | 'changeTransferOptions'>;

const CalculatorError = ({
    setPopup,
    shortText,
    longText,
    newInputValue,
    name,
    changeDeliveryOptions,
    gotIt,
    changeAmount,
    changeTransferOptions,
    handleUpdateValue,
    fetchScenario,
    actions,
    type,
}: TCalculatorErrorProps) => {
    const isShowButtonCircle = type !== ECalculatorErrors.MIN_AMOUNT;
    const onChangeAmountClick = () => {
        if (newInputValue) {
            const isFrom = name === InputEnum.FROM_INPUT;
            handleUpdateValue(newInputValue, name, isFrom);
        }
        setPopup?.(null);
    };
    const handleClick = (actionType?: ActionActionTypeEnum, actions?: TWAction[]) => {
        setPopup?.(null);
        if (actionType && actions) {
            const action = actions?.find((a) => a?.actionType === actionType);
            if (action) {
                fetchScenario(action);
            }
        }
    };
    const getErrorButtons = () => {
        if (gotIt) {
            return <ButtonRow as="button" isWidget={false} label="Got it" iconName="ic_check" handleClick={() => handleClick()} />;
        }
        let first, second;
        if (changeAmount) {
            first = <ButtonRow as="button" isWidget={false} label="Change Amount" iconName="ic_arrow_retry" handleClick={onChangeAmountClick} />;
        }
        if (changeDeliveryOptions) {
            second = (
                <ButtonRow
                    as="button"
                    isWidget={false}
                    label="Change delivery option"
                    iconName="ic_arrow_back"
                    handleClick={() => handleClick(ActionActionTypeEnum.onChangeDeliveryOptions, actions)}
                />
            );
        } else if (changeTransferOptions) {
            second = (
                <ButtonRow
                    as="button"
                    isWidget={false}
                    label="Change transfer option"
                    iconName="ic_arrow_back"
                    handleClick={() => handleClick(ActionActionTypeEnum.onChangeTransferOptions, actions)}
                />
            );
        }
        return (
            <>
                {first}
                {second}
            </>
        );
    };
    const handleErrorClick = () => {
        setPopup?.(<PopupComponent header={<SplitText text={longText ?? ''} />} externalContent={getErrorButtons()} />);
    };
    return (
        <ErrorWrapper>
            <TextCommon as="span" content={shortText} options={{ variant: 'secondary', textColor: 'pink_100' }} />
            {isShowButtonCircle && (
                <ButtonCircle isWidget={false} size="small" iconName="ic_info_circle_small" iconColor="pink_100" handleClick={handleErrorClick} />
            )}
        </ErrorWrapper>
    );
};
export default React.memo(CalculatorError);
