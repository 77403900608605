import styled, { keyframes } from 'styled-components';
import { media } from '@core/theme';
import { WithPrefix } from '@core/types';

type TPopupStylesProps = {
    animated?: boolean;
};

type StyledComponentProps = WithPrefix<TPopupStylesProps>;

export const getOutAnimation = keyframes`
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(90vh);
    }
`;

export const Overlay = styled.div`
    align-items: center;
    background: ${(props) => props.theme.black_48_tr_base};
    bottom: 0;
    display: block;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 10000;
`;

export const PopupOuter = styled.div`
    align-items: flex-end;
    display: flex;
    justify-content: center;
    height: 100%;
    padding: 0 16px;

    ${media.tablet708} {
        align-items: center;
    }
`;

export const PopupBox = styled.div<StyledComponentProps>`
    animation-name: ${(props) => (props.$animated ? getOutAnimation : '')};
    animation-duration: 0.6s;
    animation-delay: 0.1s;
    animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    animation-iteration-count: 1;
    overflow: hidden;
    bottom: 24px;
    max-width: 568px;
    position: relative;
    width: 100%;
    max-height: calc(100vh - 48px);
    display: flex;
    flex-direction: column;

    ${media.tablet708} {
        bottom: auto;
        border-radius: 16px;
        max-height: calc(100vh - 96px);
    }
`;

export const PopupBoxContentWrapper = styled.div`
    background: ${(props) => props.theme.white};
    border-radius: 12px;
    max-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    ${media.tablet708} {
        border-radius: 16px;
    }
`;
export const PopupBoxContent = styled.div<StyledComponentProps>`
    max-height: 100%;
    overflow-y: auto;
`;
export const PopupBoxButtons = styled.div`
    background: ${(props) => props.theme.white};
    border-radius: 12px;
    padding: 8px 0;
    margin-top: 8px;

    ${media.tablet708} {
        border-radius: 16px;
    }
`;
