import { TextCommon, marginHelperWithPrefix } from '@consumer/ui';
import React from 'react';
import styled from 'styled-components';
import { WithPrefix } from '@core/types';

type TPricingTabProps = {
    text?: string;
    fee?: string;
    marginBottom?: number;
    marginTop?: number;
    marginLeft?: number;
    marginRight?: number;
};

type StyledComponentProps = WithPrefix<TPricingTabProps>;

const PricingTabBlock = styled.div<StyledComponentProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 16px;
    padding: 8px 16px;
    border-radius: 8px;
    background: ${({ theme }) => theme.grey_8_lc};
    ${marginHelperWithPrefix};
`;

const PricingTab = (props: TPricingTabProps) => {
    return (
        <PricingTabBlock $marginBottom={props.marginBottom} $marginTop={props.marginTop} $marginLeft={props.marginLeft} $marginRight={props.marginRight}>
            <TextCommon as={'span'} content={props.text} options={{ variant: 'subheader', textColor: 'black' }} />
            <TextCommon as={'span'} content={props.fee} options={{ variant: 'subheader', textColor: 'purple_100' }} />
        </PricingTabBlock>
    );
};
export default PricingTab;
