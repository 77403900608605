import { TItem, TWidget } from '@core/types';

const getValueFromItem = (item: Record<string, any>, key: string) => {
    return item[key.slice(1)];
};

export const fillTemplate = (item: TItem, templates: Record<string, Array<TWidget<any>>>) => {
    const cloneTemplate = structuredClone(templates[item.templateId ?? 'default']);
    return fillTemplateInner(item, cloneTemplate);
};
export const parseTemplates = (template: Array<TWidget<any>>): Record<string, Array<TWidget<any>>> => {
    const isArray = Array.isArray(template);
    const result: Record<string, Array<TWidget<any>>> = {
        default: isArray ? template : [template],
    };
    if (isArray) {
        template.filter((t) => t.templateId).forEach((t) => (result[t.templateId ?? 'default'] = [t]));
    }
    return result;
};
const fillTemplateInner = (item: Record<string, any>, template?: any) => {
    if (Array.isArray(template)) {
        template.filter((t) => !!t).forEach((t) => fillTemplateInner(item, t));
    } else if (template) {
        Object.keys(template).map((key) => {
            const value = template[key];
            if (typeof value === 'string') {
                const newValue = getValueFromItem(item, value);
                template[key] = newValue ?? value;
            } else if (template[key]) {
                fillTemplateInner(item, template[key]);
            }
        });
    }
    return template;
};
