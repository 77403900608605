import { THandleChangeProps, TInputData, TPasscodeOption, TWidgetProps } from '@core/types';
import { HintContainer } from '@consumer/ui/src/Wrapper/styles';
import { ConditionalRender } from '@core/utils';
import React, { useRef } from 'react';
import PasscodeDot from './PasscodeDot';
import styled from 'styled-components';

type TEnterPasscode = {
    handleChangeProps?: THandleChangeProps;
} & TWidgetProps<TInputData, TPasscodeOption>;

const DotsContainer = styled.div`
    display: flex;
    justify-content: center;
    column-gap: 24px;
    margin-bottom: 24px;
`;
const PassCodeWrapper = styled.div`
    height: 0;
    overflow: hidden;
`;

const InputPasscode = ({ widget, handleChangeProps, hooks }: TEnterPasscode) => {
    const passcodeRef = useRef<HTMLInputElement | null>(null);
    const { options, uid } = widget;
    const { value, isError, errorMessage, handleChange, handleFocus } = hooks.useInput(widget);

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        passcodeRef.current?.focus();
    };

    return (
        <>
            <DotsContainer>
                {[...Array(options.maxLength)].map((_value: undefined, index: number) => (
                    <PasscodeDot key={index} currentLength={value?.length} dotIndex={index + 1} />
                ))}
            </DotsContainer>
            <PassCodeWrapper>
                <input
                    ref={passcodeRef}
                    value={value || ''}
                    autoComplete={'off'}
                    autoFocus={true}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={(e) => handleChange?.(e, handleChangeProps)}
                    data-test={uid}
                    type="tel"
                    inputMode="numeric"
                    maxLength={options?.maxLength}
                />
            </PassCodeWrapper>
            <ConditionalRender condition={isError && !!errorMessage}>
                <HintContainer $marginRight={options?.margin?.right} $marginLeft={options?.margin?.left} $isError={isError} $alignCenter>
                    {errorMessage}
                </HintContainer>
            </ConditionalRender>
        </>
    );
};

export { InputPasscode };
