import React from 'react';
import { FeePanelButton } from '../styles';
import { TextCommon } from '@consumer/ui';

type TCalculatorFeeButtonProps = {
    content: string;
};

const FeeButton = ({ content }: TCalculatorFeeButtonProps) => {
    return (
        <FeePanelButton>
            <TextCommon content={content} options={{ variant: 'secondary', textColor: 'purple_100' }} />
        </FeePanelButton>
    );
};
export { FeeButton };
