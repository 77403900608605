import { ListEnum, RowEnum, TGroupWidgetProps, TListData, TListOption } from '@core/types';
import React from 'react';
import { ListContacts } from './src/ListContacts';
import ListItems from './src/ListItems';
import ListTemplate from './src/ListTemplate';
import ListTransactions from './src/ListTransactions';
import ListSelect from './src/select/ListSelect';

type List = {
    Package: React.ElementType;
} & TGroupWidgetProps<TListData, TListOption>;

const TypeList: React.FC<List> = ({ widgetId, wid, hooks, Package }) => {
    const { widget } = hooks.useGetWidget(wid);
    switch (widgetId) {
        case ListEnum.SELECT:
            return <ListSelect widget={widget} hooks={hooks} rowId={RowEnum.ROW_REGULAR} />;
        case ListEnum.TRANSACTIONS:
            return <ListTransactions widget={widget} hooks={hooks} />;
        case ListEnum.CURRENCY:
            return <ListSelect widget={widget} hooks={hooks} rowId={RowEnum.SELECTOR} />;
        case ListEnum.CONTACTS:
            return <ListContacts widget={widget} hooks={hooks} />;
        case ListEnum.PROFILE_MENU:
        case ListEnum.TEMPLATE:
            return <ListTemplate widget={widget} hooks={hooks} Package={Package} />;
        default:
            return false;
    }
};

export { ListSelect, ListItems };

export default TypeList;
