import React from 'react';
import styled from 'styled-components';
import { TVisualData, TWidget, THooks, TVisualOption, ActionActionTypeEnum, TMutableWidgetsData, TMutableWidgetsOption } from '@core/types';
import { Modal, PopupComponent } from '@consumer/ui';
import { Visual } from '../Visual';
import { SelectPhotos } from './SelectPhotos';
import { TakePhoto } from './TakePhoto';
import { WebcamCapture } from './WebcamCapture';

type TProfileImageProps<TD, TO> = {
    widget: TWidget<TD, TO>;
    hooks: THooks<TD, TO>;
};

const ProfileImageContainer = styled.div`
    display: inline-flex;
    cursor: pointer;
`;

const ProfileImage = ({ widget, hooks }: TProfileImageProps<TVisualData, TVisualOption>) => {
    const { setPopup, setModal } = hooks.useApp();
    const { fetchDataWidget } = hooks.useFetchData(widget);
    const { updateWidget } = hooks.useUpdateWidget(widget.wid);
    const { options, wid } = widget;

    const handleChange = async (value: string, wid: string) => {
        const action = widget?.actions?.find((a) => a?.actionType === ActionActionTypeEnum.onUpdateProfilePhoto);
        try {
            if (action) {
                const result = await fetchDataWidget(action, value);
                if (result) {
                    updateWidget(result as TWidget<TMutableWidgetsData, TMutableWidgetsOption>, wid);
                }
            }
        } catch (e) {
            console.error(`Widget '${widget.id}':${widget.uid} catch error of fetch data:`, e);
        }
    };

    const handlePhotoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target?.files?.[0];
        if (file && file?.size > 5242880) {
            console.info('Max file size : 5Mb');
            return;
        }
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async () => {
                await handleChange(reader.result as string, wid);
                setPopup?.(null);
            };
            reader.onerror = function () {
                console.error('Error reader', reader.error);
            };
        }
    };

    const handlePhotoTake = () => {
        setPopup?.(null);
        setModal?.(
            <Modal isCanCloseModal={true} onHandleClick={() => setModal(null)}>
                <WebcamCapture
                    handleClick={async (value: string) => {
                        await handleChange(value, wid);
                        setModal?.(null);
                    }}
                    setModal={setModal}
                />
            </Modal>,
        );
    };

    const handleClick = () => {
        setPopup?.(
            <PopupComponent
                withoutSeparator
                internalContent={
                    <>
                        <SelectPhotos label={options.selectPhotoButtonText} iconName={options.selectPhotoButtonIcon} handleChange={handlePhotoChange} />
                        <TakePhoto label={options.takePhotoButtonText} iconName={options.takePhotoButtonIcon} handleClick={handlePhotoTake} />
                    </>
                }
            />,
        );
    };
    return (
        <ProfileImageContainer>
            <Visual isWidget={true} widget={widget} hooks={hooks} handleClick={handleClick} />
        </ProfileImageContainer>
    );
};
export { ProfileImage };
