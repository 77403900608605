import React from 'react';
export const createRequiredContext = <T>() => {
    const context = React.createContext<T | Record<string, never>>({});

    const useContext = () => {
        return React.useContext(context);
    };

    return [useContext, context.Provider] as const;
};
