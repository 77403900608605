import { useEffect, useRef, useState } from 'react';

const addZero = (number: number) => (number < 10 ? '0' + number : number);

export const useInterval = (delay: number, time?: number) => {
    const RESET_INTERVAL = 59;
    const seconds = time || RESET_INTERVAL;
    const intervalRef = useRef<ReturnType<typeof setInterval> | undefined>(undefined);
    const timerRef = useRef<number>(seconds);
    const [timerSeconds, setTimerSeconds] = useState(seconds);
    const [isShowButton, setShowButton] = useState(false);

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            if (timerRef.current <= 0 && intervalRef.current) {
                clearInterval(intervalRef.current);
                setShowButton(true);
                return;
            }
            setTimerSeconds((prevState) => {
                const newState = prevState - 1;
                timerRef.current = newState;
                return newState;
            });
        }, delay);
        return () => {
            if (intervalRef) {
                clearInterval(intervalRef.current);
            }
        };
    }, [delay]);
    const minutes = Math.floor(timerSeconds / 60);
    const restSeconds = timerSeconds - minutes * 60;
    return { time: `${addZero(minutes)}:${addZero(restSeconds)}`, isShowButton };
};
