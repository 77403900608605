import React from 'react';
import styled from 'styled-components';
import { TCommonData, TLayout, TLayoutOption, TWidgetProps, WithPrefix } from '@core/types';
import { IVerticalLayoutProps } from '../interface/interface';

type StyledComponentProps = WithPrefix<IVerticalLayoutProps>;

const getAlignment = (props: StyledComponentProps) => {
    switch (props.$alignment) {
        case 'start':
            return 'start';
        case 'center':
            return 'center';
        case 'end':
            return 'end';
    }
};

const getJustify = (props: StyledComponentProps) => {
    switch (props.$arrangement) {
        case 'start':
            return 'flex-start';
        case 'center':
            return 'center';
        case 'end':
            return 'flex-end';
        case 'space-between':
            return 'space-between';
        case 'space-around':
            return 'space-around';
    }
};

const VerticalBlock = styled.div<StyledComponentProps>`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
`;
const VerticalBlockInner = styled.div<StyledComponentProps>`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: ${(props: StyledComponentProps) => getAlignment(props)};
    justify-content: ${(props: StyledComponentProps) => getJustify(props)};
    margin-top: ${(props) => (props.$marginTop ? `${props.$marginTop}px` : 0)};
    margin-left: ${(props) => (props.$marginLeft ? `${props.$marginLeft}px` : 0)};
    margin-right: ${(props) => (props.$marginRight ? `${props.$marginRight}px` : 0)};
    margin-bottom: ${(props) => (props.$marginBottom ? `${props.$marginBottom}px` : 0)};
`;

export const VerticalLayout: React.FC<TWidgetProps<TCommonData, TLayoutOption> & TLayout> = ({ widget, children, margin }) => {
    return (
        <VerticalBlock data-test={widget?.uid} className={'vertical-layout'}>
            <VerticalBlockInner
                $alignment={widget?.options?.alignment}
                $arrangement={widget?.options?.arrangement}
                $marginBottom={margin?.bottom}
                $marginTop={margin?.top}
                $marginLeft={margin?.left}
                $marginRight={margin?.right}
            >
                {children}
            </VerticalBlockInner>
        </VerticalBlock>
    );
};
