import { InputWrapper, Loading } from '@consumer/ui';
import { TextInputEnum, TGroupWidgetProps, THooks, TInputData, TInputOption, TInputPhoneData, TWidget } from '@core/types';
import React, { lazy, Suspense } from 'react';
import { InputPasscode } from './src/InputPasscode';
import { InputPhone } from './src/InputPhone';
import { InputText } from './src/InputText';
import { autoSubmitInputValidation } from './src/utils/validation';

const InputDate = lazy(() => import(/* webpackChunkName: "InputDateModule" */ './src/InputDate'));
const TypeInput = ({ widgetId, wid, hooks }: TGroupWidgetProps<TInputData, TInputOption>) => {
    const { widget } = hooks.useGetWidget(wid);
    const render = () => {
        switch (widgetId) {
            case TextInputEnum.STROKED:
                return <InputText widget={widget} hooks={hooks} />;
            case TextInputEnum.PHONE_TEXT_INPUT:
                return (
                    <InputPhone widget={widget as TWidget<TInputPhoneData, TInputOption>} hooks={hooks as unknown as THooks<TInputPhoneData, TInputOption>} />
                );
            case TextInputEnum.DATE_MASK_TEXT_INPUT:
                return <InputDate widget={widget} hooks={hooks} />;
            case TextInputEnum.ENTERPASSCODE:
                return <InputPasscode handleChangeProps={autoSubmitInputValidation} widget={widget} hooks={hooks} />;
            case TextInputEnum.AUTO_SUBMIT_TEXT_INPUT:
                return <InputText handleChangeProps={autoSubmitInputValidation} autoComplete="one-time-code" widget={widget} hooks={hooks} />;
            default:
                return false;
        }
    };
    return <Suspense fallback={<Loading />}>{render()}</Suspense>;
};

export { TypeInput, InputWrapper };
export default TypeInput;
