import { ElementType, MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface ITemplateListItemProps {
    enabled?: boolean;
    type?: 'reactLink';
    to?: string;
    onClick?: MouseEventHandler;
    as?: ElementType;
    alignRight?: boolean;
}
export const TemplateListItem = styled(Link)<ITemplateListItemProps>`
    border: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    background: none;
    display: flex;
    width: 100%;
`;
