import {
    LayoutEnum,
    TButtonData,
    TButtonOption,
    TInitWidgetState,
    TMutableWidgetsData,
    TMutableWidgetsOption,
    TPage,
    TWidget,
    TWInternalId,
} from '@core/types';
import { nanoid } from '@reduxjs/toolkit';

const getWidgets = (
    widgets: TWidget<TMutableWidgetsData & TButtonData, TMutableWidgetsOption & TButtonOption>[],
    result: TInitWidgetState = {
        mutableWidgetsMap: {},
        bottomSheetMap: {},
    },
) => {
    for (const widget of widgets) {
        const wid = nanoid();
        widget.wid = wid;
        const widgetCopy = { ...widget };
        widgetCopy.widgets = [];
        result.mutableWidgetsMap[wid] = widgetCopy;
        if (widget?.data?.default === true && !result.submitWidget) {
            result.submitWidget = widget;
        } else if (widget.internalId && (widget?.alternativeId === LayoutEnum.BOTTOM_SHEET || widget?.id === LayoutEnum.BOTTOM_SHEET)) {
            result.bottomSheetMap[widget.internalId] = widget;
        }
        if (Array.isArray(widget?.widgets) && widget.widgets.length > 0) {
            getWidgets(widget.widgets, result);
        }
    }
    return result;
};
export const getWidgetsForTest = (
    widgets: TWidget<TMutableWidgetsData & TButtonData, TMutableWidgetsOption & TButtonOption>[],
    result: TInitWidgetState = {
        mutableWidgetsMap: {},
        bottomSheetMap: {},
    },
) => getWidgets(widgets, result);

export const getWidgetByActionWidgetId = (
    widgets: TWidget<TMutableWidgetsData & TButtonData, TMutableWidgetsOption & TButtonOption>[],
    internalId: TWInternalId,
): TWidget<TMutableWidgetsData & TButtonData, TMutableWidgetsOption & TButtonOption> => {
    let result = {} as TWidget<TMutableWidgetsData & TButtonData, TMutableWidgetsOption & TButtonOption>;
    for (const widget of widgets) {
        if (result.uid) {
            break;
        } else if (widget?.internalId === internalId) {
            result = widget;
            break;
        } else if (Array.isArray(widget?.widgets) && widget.widgets.length > 0) {
            result = getWidgetByActionWidgetId(widget.widgets, internalId);
        }
    }
    return result;
};

export const initWidgetState = (pages: TPage[]) => {
    try {
        if (pages && Array.isArray(pages) && pages.length > 0) {
            let widgets = {};
            for (const page of pages) {
                widgets = getWidgets(page.widgets) || {};
            }
            return widgets;
        }
        return {};
    } catch (e) {
        console.error(e);
        return {};
    }
};
