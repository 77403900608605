import { TWidget, TCommonData } from '@core/types';
import React, { useCallback, useState } from 'react';
import { Progress } from './Progress';
import { Container, Overlay, StoriesContent } from './styles';

export type TStories = {
    isInverted?: boolean;
    duration?: number;
};

type TStoriesProps = TStories & {
    autoplay?: boolean;
    infinityLoop?: boolean;
    steps: React.ReactNode[];
    widget: TWidget<TCommonData>;
};

const Stories = ({ widget, steps, infinityLoop, duration, isInverted, autoplay = true }: TStoriesProps) => {
    const [currentStepNumber, setCurrentStepNumber] = useState<number>(0);
    const [isPaused, setIsPaused] = useState(false);
    const [pressedDate, setPressedDate] = useState<number | null>(null);

    const overlayPressed = () => {
        setPressedDate(Date.now());
        setIsPaused(true);
    };

    const overlayReleased = (action: () => void) => {
        if (Date.now() - pressedDate! < 200) {
            action();
        }

        setPressedDate(null);
        setIsPaused(false);
    };

    const previousStep = useCallback(() => {
        setCurrentStepNumber((step) => {
            const isFirstStepIndex = step === 0;
            return isFirstStepIndex ? (infinityLoop ? steps.length - 1 : step) : step - 1;
        });
    }, [steps.length, infinityLoop]);

    const nextStep = useCallback(() => {
        setCurrentStepNumber((step) => {
            const isLastStepIndex = step === steps.length - 1;
            return isLastStepIndex ? (infinityLoop ? 0 : step) : step + 1;
        });
    }, [steps.length, infinityLoop]);

    const nextReleased = () => overlayReleased(nextStep);
    const previousReleased = () => overlayReleased(previousStep);

    return (
        <Container>
            <Progress
                count={steps.length}
                isPaused={isPaused}
                currentStep={currentStepNumber}
                onStepComplete={nextStep}
                duration={duration}
                isInverted={isInverted}
            />
            <StoriesContent>{steps[currentStepNumber]}</StoriesContent>
            <Overlay>
                <div
                    onTouchStart={overlayPressed}
                    onTouchEnd={previousReleased}
                    onMouseDown={overlayPressed}
                    onMouseUp={previousReleased}
                    onContextMenu={(e) => e.preventDefault()}
                />
                <div
                    onTouchStart={overlayPressed}
                    onTouchEnd={nextReleased}
                    onMouseDown={overlayPressed}
                    onMouseUp={nextReleased}
                    onContextMenu={(e) => e.preventDefault()}
                />
            </Overlay>
        </Container>
    );
};
export { Stories };
