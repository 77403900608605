import { configureStore } from '@reduxjs/toolkit';
import scenarioSlice from '../features/scenario.slice';

export const store = configureStore({
    reducer: {
        [scenarioSlice.name]: scenarioSlice.reducer,
    },
    // Additional middleware can be passed to this array
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    devTools: process.env.NODE_ENV !== 'production',
    // Optional Redux store enhancers
    enhancers: [],
});
