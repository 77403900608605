import { Visual } from '@consumer/type-image';
import { TextCommon } from '@consumer/ui';

import { THandleClick, TListData, TRowComponentProps, TRowOption, TRowRegularData, TWidget, TWidgetProps } from '@core/types';
import React, { ElementType } from 'react';
import { CommonSelector, RowContent, SelectedBadge, VisualWrapper } from './styles/Selector';
import { ic_check as CheckIcon } from '@consumer/assets';
import { useTheme } from 'styled-components';

type TSelectorWidget = {
    isWidget: true;
} & TWidgetProps<TRowRegularData, TRowOption>;

type TSelectorComponent = {
    isWidget: false;
    handleClick?: THandleClick;
} & TRowComponentProps;

type TSelector = (TSelectorWidget | TSelectorComponent) & {
    as: ElementType;
};

export function Selector(props: TSelector) {
    const { isWidget, as } = props;
    const theme = useTheme();
    const hook = isWidget ? props.hooks.useList(props.widget as TWidget<TListData, TRowOption>) : undefined;

    const title = isWidget ? props.widget?.data?.title : props?.title;
    const subtitle = isWidget ? props.widget?.data?.subtitle : props?.subtitle;
    const metaText = isWidget ? props.widget?.data?.metaText : props?.metaText;

    const isEnabled = (isWidget ? props.widget?.options?.enabled : props.enabled) ?? true;
    const iconName = isWidget ? props.widget?.options?.iconName : props.iconName;
    const flagIcon = isWidget ? props.widget?.options?.flagIcon : props.flagIcon;
    const avatar = isWidget ? props.widget?.options?.avatar : props.avatar;
    const isSelected = isWidget ? props.widget?.options?.isSelected : props.isSelected;
    const getTextColor = !isEnabled ? 'grey_40_lc' : 'grey_72_lc';
    const showVisual = iconName || flagIcon || avatar;

    return (
        <CommonSelector as={as} $disabled={!isEnabled} data-test={isWidget ? props.widget?.uid : ''} onClick={isWidget ? hook?.handleClick : props.handleClick}>
            {showVisual && (
                <VisualWrapper>
                    {iconName && (
                        <Visual
                            isWidget={false}
                            iconName={iconName}
                            rightMargin
                            iconHierarchy={isWidget ? props.widget?.options?.iconHierarchy : props.iconHierarchy}
                            iconVariant={isWidget ? props.widget?.options?.iconVariant : props.iconVariant}
                            size={'small'}
                            isEnabled={!!isEnabled}
                        />
                    )}
                    {flagIcon && <Visual isWidget={false} rightMargin flagIcon={flagIcon} size={'small'} isEnabled={!!isEnabled} />}
                    {avatar && <Visual isWidget={false} avatar={avatar} rightMargin size={'small'} isEnabled={!!isEnabled} />}
                    {isSelected && (
                        <SelectedBadge>
                            <CheckIcon fill={theme.purple_100} />
                        </SelectedBadge>
                    )}
                </VisualWrapper>
            )}
            <RowContent>
                {title && <TextCommon content={title} options={{ textColor: !isEnabled ? 'grey_40_lc' : 'black' }} />}
                {subtitle && <TextCommon content={subtitle} options={{ variant: 'secondary', textColor: getTextColor, margin: { top: 4 } }} />}
            </RowContent>
            {metaText && <TextCommon content={metaText} options={{ textColor: !isEnabled ? 'grey_40_lc' : 'grey_72_lc' }} />}
            {!iconName && !flagIcon && !avatar && isSelected && <CheckIcon fill={isEnabled ? theme.purple_100 : theme.purple_40_tr} />}
        </CommonSelector>
    );
}
