import { ThemeEnum } from '@core/theme';
import { useEffect, useState } from 'react';

const localStorageKey = 'consumer-theme';
const getCurrentTheme = <T>() => {
    let theme = ThemeEnum.light;
    if (window?.matchMedia) {
        const isMatchesDark = window.matchMedia('(prefers-color-scheme: dark)')?.matches;
        theme = isMatchesDark ? ThemeEnum.dark : ThemeEnum.light;
    }
    return theme as T;
};
export const getCurrentThemeForText = <T>() => getCurrentTheme<T>();
export const useThemeDetector = <T extends string>(): T => {
    const [theme, setTheme] = useState(getCurrentTheme<T>());
    useEffect(() => {
        if (window?.matchMedia) {
            const mediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');
            const setQuery = () => {
                const currentTheme = getCurrentTheme<T>();
                setTheme(currentTheme);
                localStorage.setItem(localStorageKey, currentTheme);
            };
            setQuery();
            mediaQueryList.addEventListener('change', setQuery);

            return () => {
                mediaQueryList.removeEventListener('change', setQuery);
            };
        }
    }, []);
    return theme;
};
