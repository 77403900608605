import { ActionTypeEnum, ATAction, IBody, IJson, IMutableWidgetsMap, IStateSession, Routes, TActionData, TWAction, TWidget, TWInternalId } from '@core/types';
import { getCurrentBrowserFingerPrint } from '@rajesh896/broprint.js';
import { EntityId } from '@reduxjs/toolkit';
import { updateScenario } from '../features/utils/sharedActions';
import { sharedAbortController } from '../SharedAbortController';
import { getWidgetByActionWidgetId } from './widgets';

const MAX_ATTEMPTS = Number(process.env.NX_FETCH_SCENARIO_MAX_ATTEMPTS ?? 3);
const getActionData = <T>(widgets: T): TActionData => {
    const actionData: TActionData = {};
    if (Array.isArray(widgets)) {
        widgets.forEach((w) => {
            if (w.data?.value) {
                actionData[w.uid] = w.data?.value;
            }
        });
    }
    return actionData;
};
const getSessionObject = async () => {
    const fingerprint = await getCurrentBrowserFingerPrint().catch((e) => {
        console.log('Cannot get browser finger print:', e);
        return 'unknown';
    });
    const session: IStateSession = {
        environment: {
            realm: 'Web',
            locale: navigator?.language,
            data: {
                userAgent: navigator?.userAgent,
                id: fingerprint,
            },
        },
    };
    if (localStorage?.auth) {
        session.auth = JSON.parse(localStorage.auth);
    }
    return session;
};
const getBody = async <TData, TOptions>(action: TWAction | undefined, currentPageId: string, mutableWidgetsMap: IMutableWidgetsMap<TData, TOptions>) => {
    const body: IBody = { session: await getSessionObject() };
    if (action) {
        const pageId: EntityId = currentPageId;
        const data: ReturnType<typeof getActionData> = getActionData(Object.values(mutableWidgetsMap));
        body.action = { ...action, data, page: pageId };
    }
    return body;
};
const fetchData = async (url: string, body: IBody, abortController?: AbortController): Promise<IJson> | never => {
    const options: RequestInit = {
        method: 'POST',
        body: JSON.stringify(body),
        redirect: 'manual',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    };
    if (abortController) {
        options.signal = abortController.signal;
    }
    const response = await fetch(url, options).catch((e) => {
        console.error('Cannot get response from orchestrator.');
        throw e;
    });
    if (response.status !== 200) {
        console.error('Response status code invalid, code: ', response.status, response);
        throw Error(response.statusText);
    }
    const json: IJson = await response.json();
    return json;
};
const checkAndAbortPreviousRequests = (action: TWAction | undefined): AbortController | undefined => {
    const isWidgetAction = action?.type === ActionTypeEnum.Widget;
    if (isWidgetAction) {
        const widgetId = action?.widgetId ?? 'undefinedWidgetId';
        try {
            sharedAbortController.getWidgetAbortController(widgetId);
        } catch (e) {
            console.warn('Cannot abort previous request: ', e);
        } finally {
            sharedAbortController.setNewWidgetAbortController(widgetId);
        }
        return sharedAbortController.getWidgetAbortController(widgetId);
    } else {
        try {
            sharedAbortController.getScenarioAbortController().abort();
        } catch (e) {
            console.warn('Cannot abort previous request: ', e);
        } finally {
            sharedAbortController.setNewScenarioAbortController();
        }
        return sharedAbortController.getScenarioAbortController();
    }
};

const isCanDoRetry = (action: TWAction | undefined, attempt: number) => {
    return (!action || action.type === ActionTypeEnum.Scenario) && attempt < MAX_ATTEMPTS;
};

const hasScenario = (json: IJson) => {
    return Array.isArray(json?.scenario?.pages?.[0]?.widgets) && json?.scenario?.pages[0].widgets.length > 0;
};

export const fetchJson = async <TData, TOptions>(
    action: TWAction | undefined,
    currentPageId: string,
    mutableWidgetsMap: IMutableWidgetsMap<TData, TOptions>,
    attempt = 1,
): Promise<IJson> | never => {
    try {
        const body: IBody = await getBody(action, currentPageId, mutableWidgetsMap);
        const url = `/${action ? Routes.POST_PROCESS_ACTION : Routes.POST_SCENARIO_PAGE}`;
        const abortController = checkAndAbortPreviousRequests(action);
        const json = await fetchData(url, body, abortController);
        if (!hasScenario(json) && isCanDoRetry(action, attempt)) {
            return fetchJson(action, currentPageId, mutableWidgetsMap, ++attempt);
        }
        if (hasScenario(json)) {
            if (json?.session?.auth) {
                localStorage.setItem('auth', JSON.stringify(json.session.auth));
            }
            return json;
        }
        throw new Error('Has no scenario.');
    } catch (e) {
        if (isCanDoRetry(action, attempt)) {
            return fetchJson(action, currentPageId, mutableWidgetsMap, ++attempt);
        } else {
            throw e;
        }
    }
};

export const getWidgetDataOrUpdatePage = async <TData, TOptions>(
    action: TWAction,
    internalId: TWInternalId,
    currentScenarioId: string,
    currentPageId: string,
    mutableWidgetsMap: IMutableWidgetsMap<TData, TOptions>,
    dispatch: (arg0: ATAction) => void,
): Promise<TWidget<any> | undefined> | never => {
    const json = await fetchJson(action, currentPageId, mutableWidgetsMap).catch((e) => {
        throw e;
    });
    const jsonScenario = json?.scenario;
    if (jsonScenario?.scenarioId === currentScenarioId && jsonScenario?.pages?.[0]?.id === currentPageId) {
        const widgets = jsonScenario.pages[0].widgets;
        if (Array.isArray(widgets)) {
            return getWidgetByActionWidgetId(widgets, internalId);
        }
    } else {
        dispatch(updateScenario(json));
    }
};
