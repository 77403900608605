export type TEventPayload = {
    value?: string | number | boolean | null;
    internalId?: string;
};
export enum EventTypeEnum {
    IS_DEFAULT_BUTTON_ENABLED = 'isDefaultButtonEnabled',
    IS_VALID = 'isValid',
    SIZE = 'size',
    VALUE = 'value',
    SCROLLED = 'scrolled',
}
export type TEvent = {
    type: EventTypeEnum;
    payload: TEventPayload;
};
export type TEventCallback = (data?: TEventPayload['value']) => void;
