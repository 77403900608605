import { RowEnum, THandleClick, TRowComponentProps } from '@core/types';
import React from 'react';
import { RowRegular } from './src/RowRegular';
import { RowTransaction } from './src/RowTransaction';
import { Selector } from './src/Selector';

type TRowComponent = {
    handleClick?: THandleClick;
} & TRowComponentProps;
const RowComponent: React.FC<TRowComponent> = ({ handleClick, ...props }) => {
    switch (props.id) {
        case RowEnum.ROW_REGULAR:
            return <RowRegular isWidget={false} as={'button'} handleClick={handleClick} {...props} />;
        case RowEnum.SELECTOR:
            return <Selector isWidget={false} as={'button'} handleClick={handleClick} {...props} />;
        default:
            return <RowRegular isWidget={false} as={'button'} handleClick={handleClick} {...props} />;
    }
};

export { RowRegular, RowTransaction, Selector };
export default RowComponent;
