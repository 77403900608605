import { nanoid } from '@reduxjs/toolkit';
import { TListData, TListOption, TWidgetProps } from '@core/types';
import React, { useMemo } from 'react';
import { TemplateListItem } from './styles/TemplateListItem';
import { fillTemplate, parseTemplates } from './utils/template';

type ListTemplate = {
    Package: React.ElementType;
} & TWidgetProps<TListData, TListOption>;

const ListTemplate: React.FC<ListTemplate> = ({ widget, Package, hooks }) => {
    const {
        data: { items, templates },
        uid,
        wid,
    } = widget;
    const { handleClick } = hooks.useList(widget);
    const parsedTemplates = useMemo(() => parseTemplates(templates ?? []), [templates]);

    if (parsedTemplates && Array.isArray(items) && items.length > 0) {
        return (
            <div data-test={uid}>
                {items.map((item) => {
                    if (item.clickable === false) {
                        return <Package key={item.id ?? nanoid()} widgets={fillTemplate(item, parsedTemplates)} />;
                    } else {
                        return (
                            <TemplateListItem key={item.id ?? nanoid()} onClick={(_e) => handleClick(wid, item)} as={'button'}>
                                <Package widgets={fillTemplate(item, parsedTemplates)} />
                            </TemplateListItem>
                        );
                    }
                })}
            </div>
        );
    }
    return null;
};

export default ListTemplate;
