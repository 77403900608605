import { TCommonData, TGroupWidgetProps } from '@core/types';

const TypeAction = ({ wid, hooks }: TGroupWidgetProps<TCommonData>) => {
    const { widget } = hooks.useGetWidget(wid);
    hooks.useDelayAction(widget);

    return <></>;
};

export default TypeAction;
