import React, { useRef, useState, useCallback } from 'react';
import Webcam from 'react-webcam';
import styled, { css } from 'styled-components';
import { ConditionalRender } from '@core/utils';
import { ButtonPill } from '@consumer/type-button';
import { TSetFunc } from '@core/types';

const adaptiveStyles = css`
    object-fit: cover;
    inline-size: 100%;
    block-size: auto;
    object-position: bottom center;
    height: 500px;
`;

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    padding: 10px;
`;
const ButtonContainer = styled.div`
    text-align: center;
    padding: 10px;
`;

const Image = styled.img`
    ${adaptiveStyles}
`;

const WebcamContainer = styled.div`
    & video {
        ${adaptiveStyles}
    }
`;

type TWebcamCaptureProps = {
    handleClick: (value: string) => void;
    setModal: TSetFunc;
};

const WebcamCapture = ({ handleClick, setModal }: TWebcamCaptureProps) => {
    const webcamRef = useRef<Webcam>(null);
    const [imgSrc, setImgSrc] = useState<string>('');
    const [isShowCamera, setShowCamera] = useState(true);

    const onCaptureClick = useCallback(async () => {
        const value = webcamRef?.current?.getScreenshot();
        if (value) {
            setImgSrc(value);
            setShowCamera(false);
        }
    }, [webcamRef, setImgSrc]);

    return (
        <>
            <ConditionalRender condition={isShowCamera}>
                <WebcamContainer>
                    <Webcam onUserMediaError={() => setModal(null)} audio={false} ref={webcamRef} screenshotFormat="image/jpeg" width={400} height={500} />
                </WebcamContainer>
                <ButtonContainer>
                    <ButtonPill
                        isWidget={false}
                        label="Capture photo"
                        hierarchy="secondary"
                        as="button"
                        handleClick={onCaptureClick}
                        isEnabled
                        position="inline"
                    />
                </ButtonContainer>
            </ConditionalRender>
            <ConditionalRender condition={!isShowCamera && Boolean(imgSrc)}>
                <Image src={imgSrc} width="400" height="500" alt="Capture Image" />
                <ButtonsContainer>
                    <ButtonPill
                        isWidget={false}
                        label="Take a photo"
                        hierarchy="secondary"
                        as="button"
                        handleClick={() => setShowCamera(true)}
                        isEnabled
                        position="inline"
                    />
                    <ButtonPill
                        isWidget={false}
                        label="Upload photo"
                        hierarchy="secondary"
                        as="button"
                        handleClick={() => handleClick(imgSrc)}
                        isEnabled
                        position="inline"
                    />
                </ButtonsContainer>
            </ConditionalRender>
        </>
    );
};
export { WebcamCapture };
