import { ic_x_circle_thin as CrossRoundedIcon } from '@consumer/assets';
import { CommonScreen, SpinnerCircle } from '@consumer/ui';
import { pallets, ThemeEnum, zIndex } from '@core/theme';
import { EStatus, EventTypeEnum } from '@core/types';
import { ConditionalRender, eventBus } from '@core/utils';
import React, { useLayoutEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import PortalWrapper from './components/common/PortalWrapper';
import WidgetsList from './components/Widgets/WidgetsList';
import { selectError, selectPage, selectStatus } from './features/scenario.slice';
import { useThemeDetector } from './hooks/theme';
import { useAppDispatch, useAppSelector } from './store/hooks';

const Loader = styled.div`
    opacity: 0.4;
    z-index: ${zIndex.loaderLayout};
`;

export function App() {
    const page = useAppSelector((state) => selectPage(state, 0));
    const errors = useAppSelector(selectError);
    const status = useAppSelector(selectStatus);
    const theme = useThemeDetector<ThemeEnum>();
    const dispatch = useAppDispatch();

    useLayoutEffect(() => {
        let documentScrolled = false;
        const handleScroll = () => {
            const scrolled = window?.scrollY !== 0;
            if (documentScrolled !== scrolled) {
                documentScrolled = scrolled;
                eventBus.emitGeneral(EventTypeEnum.SCROLLED, scrolled);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [dispatch]);

    return (
        <ThemeProvider theme={pallets[theme]}>
            <ConditionalRender condition={status === EStatus.Failed}>
                <CommonScreen Icon={CrossRoundedIcon} mainText={errors?.errorMessage} />
            </ConditionalRender>
            <ConditionalRender condition={status === EStatus.Loading}>
                <Loader>
                    <CommonScreen Icon={SpinnerCircle} />
                </Loader>
            </ConditionalRender>
            <ConditionalRender condition={status === EStatus.Loading || status === EStatus.Succeeded}>
                <PortalWrapper>
                    <WidgetsList widgets={page?.widgets} />
                </PortalWrapper>
            </ConditionalRender>
        </ThemeProvider>
    );
}
