import { ActionActionTypeEnum, ActionTypeEnum, EventTypeEnum, TButtonData, TButtonOption, TUseButtonHook, TWidget } from '@core/types';
import { actionsCall, eventBus } from '@core/utils';
import React, { useCallback, useEffect, useRef } from 'react';
import { usePortal } from '../components/common/PortalWrapper';
import { selectSubmitWidget } from '../features/scenario.slice';
import { fetchScenario } from '../features/utils/sharedActions';
import { useAppDispatch, useAppSelector } from '../store/hooks';

export const useButton = (widget?: TWidget<TButtonData, TButtonOption>): TUseButtonHook => {
    const { setBottomSheet } = usePortal();
    const dispatch = useAppDispatch();

    const handleClick = useCallback(() => {
        actionsCall(
            ActionActionTypeEnum.onClick,
            async (action) => {
                if (action.type === ActionTypeEnum.Player && action.widgetId) {
                    setBottomSheet(action.widgetId);
                } else {
                    dispatch(fetchScenario({ action }));
                }
            },
            widget?.actions,
        );
    }, [setBottomSheet, dispatch, widget?.actions]);
    return {
        handleClick,
    };
};

export const useSubmitByEnter = () => {
    const submitWidget = useAppSelector(selectSubmitWidget);
    const { handleClick } = useButton(submitWidget);
    const isEnabled = useRef(false);
    useEffect(() => {
        eventBus.subscribe(EventTypeEnum.IS_DEFAULT_BUTTON_ENABLED, submitWidget?.uid ?? '', (value) => {
            isEnabled.current = !!value;
        });
        return () => {
            eventBus.unsubscribe(EventTypeEnum.IS_DEFAULT_BUTTON_ENABLED, submitWidget?.uid ?? '');
        };
    }, [submitWidget?.uid]);
    return (e: React.KeyboardEvent<HTMLDivElement>) => {
        const target = e.target as HTMLDivElement;
        if (target.tagName === 'INPUT' && isEnabled.current && e.key === 'Enter') {
            handleClick();
        }
    };
};
