import React, { useCallback, useEffect, useState } from 'react';
import { flushSync } from 'react-dom';
import { usePopup, AnimationEvent } from './hooks';
import { getOutAnimation, Overlay, PopupBox, PopupOuter } from './styles';
import { Portal } from '../common/Portal';
import { usePortal } from '../common/PortalWrapper';

type TPopupProps = {
    content?: React.ReactNode;
    modal: React.ReactNode | null;
};
const Popup = ({ content, modal }: TPopupProps) => {
    const { setPopup } = usePortal();
    const [animated, setAnimated] = useState(false);
    const [data, setData] = useState<typeof content>(null);
    const { handlers, overlayRef, overlayClick } = usePopup(data, setAnimated, modal);

    useEffect(() => {
        if (content) {
            setData(content);
        } else if (data) {
            setAnimated(true);
        }
    }, [content, data]);

    const closeAnimationPopup = useCallback(
        (e: AnimationEvent<HTMLDivElement>) => {
            const name = (getOutAnimation as unknown as { name: string }).name;
            if (e?.animationName === name) {
                flushSync(() => {
                    setData(null);
                    setAnimated(false);
                    setPopup(null);
                });
            }
        },
        [setPopup],
    );

    if (data) {
        return (
            <Portal>
                <div id="popup" {...handlers}>
                    <Overlay onClick={overlayClick}>
                        <PopupOuter>
                            <PopupBox $animated={animated} onAnimationEnd={closeAnimationPopup} ref={overlayRef}>
                                {data}
                            </PopupBox>
                        </PopupOuter>
                    </Overlay>
                </div>
            </Portal>
        );
    }
    return null;
};
export default Popup;
