import { TWAction, TWidget } from './widget';
import React from 'react';
import { RowEnum } from './enums';

export type TItem = {
    id: string;
    value: string | TCommonDataObj | TContactValue;
    title: string;
    text?: string;
    subtitle?: string;
    amount?: string;
    currency?: string;
    direction?: 'credit' | 'debit';
    icon?: string;
    iconName?: string;
    avatar?: string;
    selected?: boolean;
    alias?: Array<string>;
    enabled?: boolean;
    flagName?: string;
    iconVariant?: 'simple' | 'circle';
    iconHierarchy?: 'emphasised' | 'primary' | 'secondary' | 'secondaryAlt' | 'destructive';
    background?: string;
    imgSrc?: string;
    flagIconSmall?: string;
    status?: string;
    statusText?: string;
    metaText?: string;
    metaVariant?: 'text' | 'tag';
    templateId?: string;
    clickable?: boolean;
    from?: boolean;
    to?: boolean;
};
export type TContacts = {
    address: string[];
    email: string[];
    icon: (typeof Blob)[];
    name: string[];
    tel: string[];
};
export type TContactValue = {
    email?: string;
    avatar?: string;
    fullName?: string;
    number?: string;
};
type TCard = {
    pan?: string;
};

type TBalance = {
    balance?: string;
    prefix?: string;
    postfix?: string;
    currencyIso?: string;
    text?: string;
    current?: boolean;
    id?: string;
    cards?: Array<TCard>;
};

export type TSection = {
    header: string;
    itemsIds: Array<string>;
};

export type TCommonDataObj = {
    [key: string]: string | boolean;
};

export type TCommonData = {
    value?: string | TCommonDataObj;
};

export type TInputData = {
    label?: string;
    isError?: boolean;
    readOnly?: boolean;
    regex?: string;
    errorMessage?: string;
    mandatory?: false;
    mask?: string;
    submitLength?: number;
} & TCommonData;

export type TTextInputData = {
    helperText?: string;
} & TInputData;

export type TInputPhoneData = {
    headerForSelectPhonePrefix?: string;
} & TTextInputData &
    TListData &
    TButtonData;

export type TCarouselData = {
    items: Array<TItem>;
};

export type TTextData = {
    text: string;
    links?: Record<string, string>;
};

export type TDropdownData = {
    items?: Array<TItem>;
} & TTextInputData;

export type TButtonData = {
    label?: string;
    iconName?: string;
    default?: boolean;
};

export type TTimerButtonData = {
    timerTitle?: string;
    time?: number;
} & TButtonData;

export type TListData = {
    allItemsHeader?: string;
    emptyPlaceholder?: string;
    emptySearchResultLabel?: string;
    items: Array<TItem>;
    sections?: TSection[];
    templates: Array<TWidget<unknown>>;
    cellWidgetId?: RowEnum.SELECTOR | RowEnum.ROW_REGULAR;
} & TCommonData &
    TButtonData &
    TRowRegularData;

export type TRowRegularData = {
    title?: string | TrustedHTML;
    subtitle?: string;
    metaText?: string;
    iconName?: string;
    flagIcon?: string;
    avatar?: string;
} & TButtonData;

export type TBalanceData = {
    balances: Array<TBalance>;
};

export type TSeparatorData = {
    text?: string;
    metaText?: string;
} & TCommonData;

export type TVisualData = {
    iconName?: React.ReactNode;
    flagIcon?: string;
    flagIconSmall?: string;
    avatar?: string;
    base64?: string;
} & TCommonData;

type TRate = {
    to: string;
    from: string;
};
export type TFee = {
    sum: string;
    currency: string;
    iconName: string;
    longText: string;
};
export enum ECalculatorErrors {
    EXCEED_LIMIT_TRANSACTION = 'EXCEED_LIMIT_TRANSACTION',
    MIN_AMOUNT = 'MIN_AMOUNT',
}
export type TCalculatorError = {
    type: ECalculatorErrors;
    changeAmount?: boolean;
    changeDeliveryOptions?: boolean;
    changeTransferOptions?: boolean;
    gotIt?: boolean;
    from: boolean;
    longText?: string;
    shortText?: string;
    newInputValue?: string;
    actions?: TWAction[];
};
export type TCalculatorData = {
    rate: TRate;
    fee: TFee;
    errors: TCalculatorError[];
    listCurrency: TItem[];
} & TCommonData;
