import React from 'react';

import styled, { keyframes } from 'styled-components';

const preloaderAnimation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const defaultSize = '80px';

const SpinnerBox = styled.div<Omit<TSpinnerCircle, 'isWhite'>>`
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-name: ${preloaderAnimation};
    animation-timing-function: linear;
    width: ${(props) => (props.size ? props.size : defaultSize)};
    height: ${(props) => (props.size ? props.size : defaultSize)};

    svg {
        width: ${(props) => (props.size ? props.size : defaultSize)};
        height: ${(props) => (props.size ? props.size : defaultSize)};
    }
`;
type TSpinnerCircle = {
    size?: '16px' | '32px' | '80px';
    isWhite?: boolean;
    ref?: React.Ref<HTMLDivElement>;
};
export const SpinnerCircle = (props: TSpinnerCircle) => {
    return (
        <SpinnerBox size={props.size} ref={props.ref}>
            {!props.isWhite && (
                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M40 4.25C20.2558 4.25 4.25 20.2558 4.25 40C4.25 59.7442 20.2558 75.75 40 75.75C40.4142 75.75 40.75 76.0858 40.75 76.5C40.75 76.9142 40.4142 77.25 40 77.25C19.4274 77.25 2.75 60.5726 2.75 40C2.75 19.4274 19.4274 2.75 40 2.75C60.06 2.75 76.4162 18.6063 77.2191 38.4697C77.2359 38.8836 76.914 39.2326 76.5001 39.2494C76.0861 39.2661 75.7371 38.9441 75.7204 38.5304C74.9498 19.4679 59.2519 4.25 40 4.25Z"
                        stroke="url(#paint0_linear_1220_119607)"
                        strokeWidth={props.size === '32px' ? '7' : '1.5'}
                    />
                    <defs>
                        <linearGradient id="paint0_linear_1220_119607" x1="40" y1="40" x2="40" y2="78" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#7633FF" />
                            <stop offset="1" stopColor="white" />
                        </linearGradient>
                    </defs>
                </svg>
            )}
            {props.isWhite && (
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0V1.45455C11.615 1.45455 14.5455 4.38505 14.5455 8C14.5455 11.615 11.615 14.5455 8 14.5455C4.38505 14.5455 1.45455 11.615 1.45455 8H0C0 12.4183 3.58172 16 8 16Z"
                        fill="white"
                    />
                </svg>
            )}
        </SpinnerBox>
    );
};
