import { AnyAction, AsyncThunkAction, Dispatch } from '@reduxjs/toolkit';
import { IJson } from './json';
import { TPage } from './pages';
import { TButtonData, TCalculatorData, TCommonData, TInputData } from './widget/data';
import { TButtonOption, TCalculatorOption, TInputOption } from './widget/options';
import { TWAction, TWidget } from './widget/widget';

export enum EStatus {
    Idle = 'idle',
    Loading = 'loading',
    Succeeded = 'succeeded',
    Failed = 'failed',
}

export interface ValidationErrors {
    errorMessage: string;
    field_errors?: Record<string, string>;
}

export interface IBaseState {
    status?: EStatus;
    errors?: ValidationErrors;
}

type TWid = TWidget<TCommonData>['wid'];
export type IMutableWidgetsMap<TD, TO> = Record<TWid, TWidget<TD, TO>>;
export type TBottomSheetsWidgetsMap = Record<string, TWidget<TCommonData>>;
export type TMutableWidgetsData = TInputData & TCalculatorData;
export type TMutableWidgetsOption = TInputOption & TCalculatorOption;
export type TInitWidgetState = {
    mutableWidgetsMap: IMutableWidgetsMap<TMutableWidgetsData, TMutableWidgetsOption>;
    bottomSheetMap: TBottomSheetsWidgetsMap;
    submitWidget?: TWidget<TButtonData, TButtonOption>;
};
export type TInitPagesState = {
    pages: TPage[];
};

export type ATAction = AsyncThunkAction<
    ({ scenarioId: string; currentPageId: string; pages: TPage[] } & TInitWidgetState) | undefined,
    IJson | undefined,
    {
        state: IRootState;
        rejectValue: ValidationErrors;
        dispatch?: Dispatch<AnyAction> | undefined;
        extra?: unknown;
        serializedErrorType?: unknown;
        pendingMeta?: unknown;
        fulfilledMeta?: unknown;
        rejectedMeta?: unknown;
    }
>;

export type TStateScenario = IBaseState &
    TInitWidgetState &
    TInitPagesState & {
        scenarioId: string;
        currentPageId: string;
    };

export interface IRootAppState {
    app: TAppState;
}
export type TAppState = {
    documentScrolled: boolean;
};
export interface IStateSession extends IBaseState {
    auth?: Record<string, never>;
    environment: Record<string, any>;
}

export interface IBody {
    session: IStateSession;
    action?: TWAction;
}

export interface IRootState {
    scenario: TStateScenario;
}
