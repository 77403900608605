import { ic_chevron_down_small as IconChevronDown, mastercard as Mastercard, paysend as Paysend } from '@consumer/assets';
import { TextCommon } from '@consumer/ui';
import { media } from '@core/theme';
import { TBalanceData, TTextOption, TWidgetProps, WithPrefix } from '@core/types';
import { ConditionalRender, currencyFormat, numberFormat } from '@core/utils';
import React, { useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components';

type TBalance = {
    marginTop?: number;
    marginRight?: number;
    marginBottom?: number;
    marginLeft?: number;
};

type StyledComponentProps = WithPrefix<TBalance>;

const BalanceContainer = styled.div<StyledComponentProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    margin-top: ${(props) => (props.$marginTop ? `${props.$marginTop}px` : 0)};
    padding-left: ${(props) => (props.$marginLeft ? `${props.$marginLeft}px` : 0)};
    padding-right: ${(props) => (props.$marginRight ? `${props.$marginRight}px` : 0)};
    padding-bottom: ${(props) => (props.$marginBottom ? `${props.$marginBottom}px` : 0)};
`;
const CardContainer = styled.div<StyledComponentProps>`
    padding: 12px 12px 10px 12px;
    background: ${({ theme }) => theme.purple_100};
    box-shadow: ${({ theme }) => theme.shadow_purple};
    width: calc(100% - 100px);
    min-width: 215px;
    max-width: 337px;
    aspect-ratio: 215 / 133;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    border-radius: 12px;
    margin-bottom: 16px;
    cursor: pointer;

    &:hover {
        background: ${({ theme }) => theme.purple_72_hc};
    }

    &:active {
        background: ${({ theme }) => theme.purple_72_lc};
    }

    ${media.mobile481} {
        width: 337px;
        height: 208px;
    }

    ${media.tablet708} {
        //W_card = ((W_screen – 24*7) / 6) * 2 + 32
        width: calc(((100% - 168px) / 6) * 2 + 32px);
        min-width: 204px;
        height: auto;
        aspect-ratio: 204 / 127;
    }

    ${media.tablet889} {
        width: 264px;
        height: 163px;
    }
`;
const BottomRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const SumContainer = styled.div`
    display: inline-flex;
    flex-direction: column;
    padding: 4px 8px 8px 8px;
    border-radius: 12px;
    cursor: pointer;

    &:hover {
        background: ${({ theme }) => theme.white};
    }

    &:active {
        background: ${({ theme }) => theme.white_40_tr_base};
    }
`;
const Sum = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
`;
const PseudoDropdown = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const Balance: React.FC<TWidgetProps<TBalanceData, TTextOption>> = ({ widget, hooks: { useUpdateWidgetInfo } }) => {
    const theme = useTheme();
    const [stateWidget, setWidget] = useState(widget);
    const currentBalance = useMemo(() => {
        if (Array.isArray(stateWidget?.data?.balances) && stateWidget?.data.balances.length > 0) {
            return {
                currentBalance: stateWidget?.data.balances.filter((balance) => balance?.current)?.[0],
                showArrow: stateWidget?.data.balances.length > 1,
            };
        }
        return null;
    }, [stateWidget?.data?.balances]);

    useUpdateWidgetInfo(stateWidget, setWidget);

    const cb = currentBalance?.currentBalance;
    const card = cb?.cards?.[0];

    if (cb) {
        return (
            <BalanceContainer
                $marginBottom={stateWidget.options?.margin?.bottom}
                $marginTop={stateWidget.options?.margin?.top}
                $marginLeft={stateWidget.options?.margin?.left}
                $marginRight={stateWidget.options?.margin?.right}
            >
                {card && (
                    <CardContainer>
                        <Paysend />
                        <BottomRow>
                            <TextCommon content={`•• ${card.pan?.slice(-4)}`} options={{ variant: 'secondary', textColor: 'white_base' }} />
                            <Mastercard />
                        </BottomRow>
                    </CardContainer>
                )}
                <SumContainer key={cb.id} data-test={stateWidget.uid}>
                    <Sum>
                        <ConditionalRender condition={!!cb.currencyIso}>
                            <TextCommon content={currencyFormat(cb.balance, cb.currencyIso)} options={{ variant: 'display1', textColor: 'black' }} />
                        </ConditionalRender>
                        <ConditionalRender condition={!cb.currencyIso}>
                            {cb.prefix && (
                                <TextCommon
                                    content={cb.prefix}
                                    options={{
                                        variant: 'display1',
                                        textColor: 'black',
                                        smallCaps: true,
                                        margin: { right: 4 },
                                    }}
                                />
                            )}
                            <TextCommon content={numberFormat(cb.balance)} options={{ variant: 'display1', textColor: 'black' }} />
                            {cb.postfix && (
                                <TextCommon
                                    content={cb.postfix.toLowerCase()}
                                    options={{ variant: 'display1', textColor: 'black', smallCaps: true, margin: { left: 8 } }}
                                />
                            )}
                        </ConditionalRender>
                    </Sum>
                    <PseudoDropdown>
                        <TextCommon content={cb.text} options={{ variant: 'secondary', textColor: 'grey_72_lc', margin: { right: 2 } }} />
                        {currentBalance?.showArrow && <IconChevronDown fill={theme.grey_72_lc} />}
                    </PseudoDropdown>
                </SumContainer>
            </BalanceContainer>
        );
    } else {
        return false;
    }
};

export default Balance;
