import React, { useEffect, useRef, useState } from 'react';
import { TItem, ActionActionTypeEnum, TWidgetProps, TListData, TListOption, TWAction } from '@core/types';
import ListItems, { TListItemProps } from './ListItems';
import { TextCommon, SpinnerCircle } from '@consumer/ui';
import { useTheme } from 'styled-components';
import { ic_dotted_list as IconEmpty } from '@consumer/assets';
import { RowTransaction } from '@consumer/type-row';
import { ConditionalRender } from '@core/utils';
import { EmptyContainer, SpinnerContainer } from './styles';

const ListTransactions: React.FC<TWidgetProps<TListData, TListOption>> = ({ widget, hooks }) => {
    const getActionOnEndOfList = (actions?: TWAction[]) => actions?.find((a) => a?.actionType === ActionActionTypeEnum.onEndOfList);

    const localTheme = useTheme();
    const ref = useRef(null);
    const fetchingData = useRef(false);
    const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const { actions, data } = widget;
    const [items, setItems] = useState<TItem[]>(data?.items ?? []);
    const { handleClick, isVisible } = hooks.useList(widget, ref);
    const { fetchData } = hooks.useFetchData(widget);
    const [action, setAction] = useState(getActionOnEndOfList(actions));
    const hasItems = items && Array.isArray(items) && items.length > 0;
    const [showSpinner, toggleSpinner] = useState(!!action && hasItems);

    useEffect(() => {
        let timeoutId = timeoutRef?.current;

        async function startFetching() {
            if (showSpinner && action && isVisible && !fetchingData.current) {
                fetchingData.current = true;
                const result = await fetchData(action);
                if (result) {
                    if (Array.isArray(result.data?.items) && result.data.items.length > 0) {
                        setItems([...items, ...result.data.items]);
                    } else {
                        toggleSpinner(false);
                    }
                    setAction(getActionOnEndOfList(result.actions));
                } else {
                    setAction(undefined);
                    toggleSpinner(false);
                }
            }
        }

        startFetching()
            .catch((e) => console.warn(e))
            .finally(() => {
                timeoutId = setTimeout(() => (fetchingData.current = false), 300);
            });
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [showSpinner, items, isVisible, action, fetchData]);

    const renderItem = ({ item, index }: TListItemProps) => {
        return <RowTransaction as={'button'} key={`${index}_${item.id}`} item={item} handleClick={() => handleClick(widget.wid, item)} />;
    };

    return (
        <div data-test={widget.uid}>
            <ConditionalRender condition={!hasItems}>
                <EmptyContainer>
                    <IconEmpty fill={localTheme.grey_72_lc} />
                    <TextCommon content={data.emptyPlaceholder} options={{ variant: 'secondary', textColor: 'grey_72_lc', margin: { top: 16 } }} />
                </EmptyContainer>
            </ConditionalRender>
            <ConditionalRender condition={hasItems}>
                <ListItems items={items} renderItem={renderItem} />
            </ConditionalRender>
            <ConditionalRender condition={showSpinner}>
                <SpinnerContainer ref={ref}>
                    <SpinnerCircle size={'32px'} />
                </SpinnerContainer>
            </ConditionalRender>
        </div>
    );
};

export default ListTransactions;
