class SharedAbortController {
    private scenarioAbortController = new AbortController();
    private widgetAbortController: Record<string, AbortController> = {};

    getScenarioAbortController() {
        return this.scenarioAbortController;
    }

    getWidgetAbortController(widgetId: string) {
        const abortController = this.widgetAbortController[widgetId];
        if (!abortController) {
            this.widgetAbortController[widgetId] = new AbortController();
        }
        return this.widgetAbortController[widgetId];
    }

    setNewWidgetAbortController(widgetId: string) {
        this.widgetAbortController[widgetId] = new AbortController();
    }

    setNewScenarioAbortController() {
        this.scenarioAbortController = new AbortController();
        this.clearAndAbortWidgetRequest();
    }

    private clearAndAbortWidgetRequest() {
        if (this.widgetAbortController) {
            const keys = Object.keys(this.widgetAbortController);
            if (keys.length > 0) {
                keys.forEach((uid) => {
                    try {
                        this.widgetAbortController[uid]?.abort();
                    } catch (e) {
                        console.warn('Cannot abort request for widget: ', uid);
                    }
                });
            }
        }
        this.widgetAbortController = {};
    }
}

const sharedAbortController = new SharedAbortController();

export { sharedAbortController };
