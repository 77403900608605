import styled, { ExecutionContext } from 'styled-components';
import { Link } from 'react-router-dom';
import { fonts, media } from '@core/theme';
import { WithPrefix } from '@core/types';
import { marginHelperWithPrefix } from '@consumer/ui';

interface IButtonProps {
    hierarchy?: 'primary' | 'secondary' | 'tertiary';
    disabled?: boolean;
    viewBackground?: 'plain' | 'gradient';
    position?: 'floating' | 'inline';
    size?: 'large' | 'medium' | 'small';
    fillContainer?: boolean;
    marginTop?: number;
    marginRight?: number;
    marginBottom?: number;
    marginLeft?: number;
}

type StyledComponentProps = ExecutionContext & WithPrefix<IButtonProps>;

const getColor = (props: StyledComponentProps): string => {
    switch (props.$hierarchy) {
        case 'secondary':
            return props.$disabled ? props.theme.purple_40_tr : props.theme.purple_100;
        case 'tertiary':
            if (props.$viewBackground === 'gradient') {
                return props.theme.white_base;
            } else {
                return props.$disabled ? props.theme.grey_40_lc : props.theme.black;
            }
        default:
            return props.theme.white_base;
    }
};

const getBackground = (props: StyledComponentProps): string => {
    switch (props.$hierarchy) {
        case 'secondary':
            return props.theme.white;
        case 'tertiary':
            if (props.$viewBackground === 'gradient') {
                return props.theme.white_0_tr_base;
            } else {
                return props.theme.white;
            }
        default:
            return props.$disabled ? props.theme.purple_16_lc : props.theme.purple_100;
    }
};

const getHoveredBackground = (props: StyledComponentProps): string => {
    switch (props.$hierarchy) {
        case 'secondary':
            return props.$disabled ? props.theme.white : props.theme.purple_16_lc;
        case 'tertiary':
            if (props.$viewBackground === 'gradient') {
                return props.theme.white_8_tr_base;
            } else {
                return props.$disabled ? props.theme.white : props.theme.grey_16_lc;
            }
        default:
            return props.$disabled ? props.theme.purple_16_lc : props.theme.purple_72_hc;
    }
};

const getFocusedBackground = (props: StyledComponentProps): string => {
    switch (props.$hierarchy) {
        case 'secondary':
            return props.$disabled ? props.theme.white : props.theme.purple_8_lc;
        case 'tertiary':
            if (props.$viewBackground === 'gradient') {
                return props.theme.white_16_tr_base;
            } else {
                return props.$disabled ? props.theme.white : props.theme.grey_8_lc;
            }
        default:
            return props.$disabled ? props.theme.purple_16_lc : props.theme.purple_72_tr;
    }
};

const getBoxShadow = (props: StyledComponentProps): string => {
    const raised = props.$position === 'floating' && !props.$disabled;
    if (props.$hierarchy === 'primary' || !props.$hierarchy) {
        return raised ? props.theme.shadow_purple : 'none';
    }
    return raised ? props.theme.shadow_grey : 'none';
};

export const CommonButton = styled(Link)<StyledComponentProps>`
    font-family: ${fonts.regular};
    border: none;
    width: ${(props) => (props.$fillContainer ? '100%' : 'auto')};
    box-sizing: border-box;
    display: inline-block;
    outline: none;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    vertical-align: top;
    cursor: ${(props) => (props.$disabled ? 'default' : 'pointer')};
    pointer-events: auto;
    font-size: 17px;
    line-height: 24px;
    border-radius: ${(props) => (props.$size === 'medium' ? '32px' : '56px')};
    padding: ${(props) => (props.$size === 'medium' ? '8px 16px' : '16px 32px')};
    color: ${(props) => getColor(props)};
    background: ${(props) => getBackground(props)};
    box-shadow: ${(props) => getBoxShadow(props)};
    ${marginHelperWithPrefix};

    ${media.tablet708} {
        max-width: ${(props) => (props.$fillContainer ? 'calc(((100% - 168px) / 6) * 2 + 32px)' : null)};
    }

    &:link,
    &:visited,
    &:focus {
        color: ${(props) => getColor(props)};
    }

    &:hover {
        background: ${(props) => getHoveredBackground(props)};
        color: ${(props) => getColor(props)};
    }

    &:active,
    &:focus {
        background: ${(props) => getFocusedBackground(props)};
        color: ${(props) => getColor(props)};
    }
`;
