import styled from 'styled-components';

import { media } from '@core/theme';
import { TAddProps } from './CommonScreen';

export const Wrapper = styled.div`
    bottom: 0;
    left: 0;
    min-width: 288px;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    background-color: ${(props) => props.theme.white};
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    width: 100%;
    box-sizing: border-box;
    flex: 1;
    padding: 72px 24px 92px 24px;
`;

export const CenterContainer = styled.div`
    width: 100%;
    max-width: 504px;
`;

export const HeaderText = styled.div`
    font-size: 24px;
    line-height: 36px;
    margin-top: 24px;

    span,
    .color-GT {
        color: ${(props) => props.theme.purple_100};
    }

    ${media.desktop1920} {
        font-size: 32px;
        line-height: 48px;
    }
`;
export const SecondaryText = styled.div`
    font-size: 17px;
    line-height: 24px;
    margin-top: 16px;
    color: ${(props) => props.theme.grey_72_lc};

    ${media.desktop1920} {
        font-size: 20px;
        line-height: 32px;
    }
`;

export const MainText = styled.span<TAddProps>`
    display: inline-block;
    font-size: 17px;
    line-height: 24px;
    margin-top: 24px;
    border-bottom: ${(props) => (props.hasBorder ? `1px solid ${props.theme.grey_16_lc}` : null)};
    padding-bottom: ${(props) => (props.hasBorder ? '32px' : null)};
    word-break: ${(props) => (props.breakWord ? 'break-word' : null)};

    ${media.desktop1920} {
        font-size: 20px;
        line-height: 32px;
        padding-bottom: ${(props) => (props.hasBorder ? '48px' : null)};
    }
`;
