import styled from 'styled-components';
import { media } from '@core/theme';

export const SectionsWrapper = styled.div`
    margin-bottom: 32px;
`;

export const NotFoundContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;

    h2 {
        color: ${({ theme }) => theme.grey_72_lc};
    }
`;

export const IconWrapper = styled.div`
    margin-right: 16px;
    align-self: center;

    ${media.desktop1920} {
        margin-right: 24px;
    }
`;

export const ClearIcon = styled.span`
    cursor: pointer;

    &:hover svg {
        fill: ${({ theme }) => theme.black};
    }
`;

export const FirstLetterRow = styled.div`
    padding: 16px 24px;
`;
export const FirstLetter = styled.div`
    border: 2px solid ${({ theme }) => theme.black};
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const EmptyContainer = styled.div`
    margin: 0 auto;
    padding: 48px;
    text-align: center;
    max-width: 264px;
`;
export const SpinnerContainer = styled.div`
    padding-top: 16px;
    padding-bottom: 96px;
    display: flex;
    justify-content: center;
`;
