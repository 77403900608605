import styled from 'styled-components';
import { media, fonts } from '@core/theme';
import { WithPrefix } from '@core/types';

interface ICalculatorProps {
    isError?: boolean;
    fontSize: string;
    isLoading: boolean;
}

type StyledComponentProps = WithPrefix<ICalculatorProps>;

export const CalculatorFrame = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    padding-bottom: 132px;
`;

export const CalculatorFrameTop = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
    padding: 24px;
`;

export const CalculatorAmountContainer = styled.div`
    display: flex;
    column-gap: 16px;
`;

export const CalculatorAmountBlock = styled.label`
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
`;

export const CalculatorInputWrapper = styled.div<StyledComponentProps>`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    position: relative;
    padding: 16px 0;

    input {
        font-family: ${fonts.regular};
        background-color: transparent;
        border: none;
        box-sizing: border-box;
        display: inline-block;
        padding: 0;
        width: 100%;
        font-size: ${(props) => (props.$fontSize ? props.$fontSize : '40px')};
        line-height: 48px;
        cursor: auto;
        text-align: center;
        color: ${(props) => (props.$isError ? props.theme.pink_100 : null)};
        opacity: ${(props) => (props.$isLoading ? '0.7' : '1.0')};

        &:hover {
            color: ${(props) => (props.$isError ? props.theme.pink_100 : props.theme.purple_100)};
        }

        &:focus {
            color: ${(props) => (props.$isError ? props.theme.pink_100 : props.theme.purple_100)};
        }

        ${media.desktop1920} {
            font-size: 48px;
            line-height: 64px;
        }
    }
`;

export const CurrencyDropdownBlock = styled.div`
    display: inline-flex;
    align-items: center;
    padding: 8px 6px 8px 8px;
    border-radius: 6px;
    border: 1px solid ${(props) => props.theme.grey_16_tr};
    margin-top: 4px;
    cursor: pointer;
`;

export const CalculatorFrameBottom = styled.div`
    margin-top: 24px;
    padding: 24px;
    display: flex;
    border-top: 1px solid ${(props) => props.theme.grey_16_tr};
    border-bottom: 1px solid ${(props) => props.theme.grey_16_tr};
    position: relative;
`;
export const FeePanelBlock = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;
export const FeePanelBlockBottom = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;
export const FeePanelButton = styled.button`
    cursor: pointer;
    position: absolute;
    bottom: -16px;
    left: 50%;
    transform: translateX(-50%);
    padding: 4px 16px;
    border-radius: 16px;
    border: 1px solid ${(props) => props.theme.grey_16_lc};
    background-color: ${(props) => props.theme.white};

    &:hover {
        background-color: ${(props) => props.theme.purple_16_lc};
    }

    &:active {
        background-color: ${(props) => props.theme.purple_8_lc};
    }
`;

export const CalculatorButtonStyled = styled.div`
    display: flex;
    justify-content: center;
    padding: 38px 24px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
`;
