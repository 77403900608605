import { EventTypeEnum, TEvent, TEventCallback, TWInternalId } from '@core/types';
import { Subject, Subscription } from 'rxjs';

class EventBus {
    private consoleColor = 'background: #222; color: #bada55';
    private consoleColorUnsubscribe = 'background: #222; color: #d25050';
    private consoleColorEmit = 'background: #222; color: #3fb64d';
    private eventBus: Subject<TEvent> = new Subject();
    private subscriptions: Record<string, Subscription> = {};
    private getSubscriptionKey = (eventName: EventTypeEnum, internalId: TWInternalId) => `${eventName}_${internalId}`;

    subscribeOnSpecificWidget(eventName: EventTypeEnum, internalId: string, callback: TEventCallback): void {
        this.logSubscribe(`subscribe on SPECIFIC widget event: '${eventName}' internalId: '${internalId}'`);
        this.subscriptions[this.getSubscriptionKey(eventName, internalId)] = this.eventBus.subscribe((event: TEvent) => {
            if (event.type === eventName && event.payload.internalId === internalId) {
                callback(event.payload.value);
            }
        });
    }

    subscribe(eventName: EventTypeEnum, widgetUid: string, callback: TEventCallback): void {
        this.logSubscribe(`subscribe on GENERAL event: '${eventName}'`);
        this.subscriptions[this.getSubscriptionKey(eventName, widgetUid)] = this.eventBus.subscribe((event: TEvent) => {
            if (event?.type === eventName) {
                callback(event.payload.value);
            }
        });
    }

    unsubscribeSpecificWidget(eventName: EventTypeEnum, internalId: TWInternalId): void {
        this.logUnsubscribe(`unsubscribeSpecificWidget: ${this.getSubscriptionKey(eventName, internalId)}`);
        this.subscriptions?.[this.getSubscriptionKey(eventName, internalId)]?.unsubscribe();
    }

    unsubscribe(eventName: EventTypeEnum, widgetUid: string): void {
        this.logUnsubscribe(`unsubscribe: ${this.getSubscriptionKey(eventName, widgetUid)}`);
        this.subscriptions?.[this.getSubscriptionKey(eventName, widgetUid)]?.unsubscribe();
    }

    emit(eventName: EventTypeEnum, internalId: TWInternalId, data?: string | number | boolean): void {
        const event: TEvent = {
            type: eventName,
            payload: {
                value: data,
                internalId: internalId ?? '',
            },
        };
        this.logEmit(`Emitted event '${this.getSubscriptionKey(eventName, internalId)}' value: '${data}'`);
        this.eventBus.next(event);
    }

    emitGeneral(eventName: EventTypeEnum, data?: string | number | boolean): void {
        const event: TEvent = {
            type: eventName,
            payload: {
                value: data,
            },
        };
        this.logEmit(`Emitted general event '${eventName}' value: '${data}'`);
        this.eventBus.next(event);
    }

    private logEmit = (message: string) => {
        if (process.env.NX_LOG_EVENT_EMIT) {
            console.log(`%c ${message}`, this.consoleColorEmit);
        }
    };
    private logUnsubscribe = (message: string) => {
        if (process.env.NX_LOG_EVENT_UNSUBSCRIBE) {
            console.log(`%c ${message}`, this.consoleColorUnsubscribe);
        }
    };
    private logSubscribe = (message: string) => {
        if (process.env.NX_LOG_EVENT_SUBSCRIBE) {
            console.log(`%c ${message}`, this.consoleColor);
        }
    };
}

const eventBus = new EventBus();

export { eventBus };
