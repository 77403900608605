import { InputWrapper } from '@consumer/ui';
import { THandleChangeProps, TInputOption, TTextInputData, TWidgetProps } from '@core/types';
import React from 'react';

const getInputMode = (options: TInputOption): 'text' | 'numeric' => {
    return options.inputType === 'number' ? 'numeric' : 'text';
};

type TTextInput = {
    handleChangeProps?: THandleChangeProps;
    inputRef?: React.MutableRefObject<HTMLInputElement | null>;
    autoComplete?: string;
} & TWidgetProps<TTextInputData, TInputOption>;

const InputText = ({ widget, handleChangeProps, hooks, inputRef, autoComplete = 'off' }: TTextInput) => {
    const { data, options, uid } = widget;
    const { value, isFocused, isFilled, isError, errorMessage, handleChange, handleBlur, handleFocus } = hooks.useInput(widget);
    const useFunction = hooks.useFunction(widget);

    return (
        <InputWrapper
            useFunction={useFunction}
            labelText={data?.label}
            isFocused={isFocused}
            isError={isError}
            isDisabled={options?.isDisabled}
            errorMessage={errorMessage}
            helperText={data?.helperText}
            isFilled={isFilled}
            marginTop={options?.margin?.top}
            marginRight={options?.margin?.right}
            marginBottom={options?.margin?.bottom}
            marginLeft={options?.margin?.left}
        >
            <input
                ref={inputRef}
                value={value || ''}
                autoComplete={autoComplete}
                autoFocus={options?.onFocus || false}
                onFocus={handleFocus}
                onBlur={handleBlur}
                readOnly={!!options?.readOnly}
                onChange={(e) => handleChange?.(e, handleChangeProps)}
                data-test={uid}
                type="text"
                inputMode={getInputMode(options)}
                maxLength={data?.submitLength || options?.maxLength}
            />
        </InputWrapper>
    );
};

export { InputText };
