import React, { ReactNode, ElementType } from 'react';
import { TextCommonStyled } from './style';
import { TTextOption } from '@core/types';

type TTextCommonProps = {
    options?: TTextOption;
    content?: ReactNode | TrustedHTML;
    as?: ElementType;
    uid?: string;
};
const TextCommon = (props: TTextCommonProps) => {
    const { options, content, as, uid } = props;
    return (
        <TextCommonStyled
            as={as || 'p'}
            $variant={options?.variant}
            $textColor={options?.textColor}
            $fontWeight={options?.fontWeight}
            $textAlign={options?.textAlign}
            $smallCaps={options?.smallCaps}
            $ellipsis={options?.ellipsis}
            $lineThrough={options?.lineThrough}
            $marginTop={options?.margin?.top}
            $marginRight={options?.margin?.right}
            $marginBottom={options?.margin?.bottom}
            $marginLeft={options?.margin?.left}
            data-test={uid}
        >
            {content}
        </TextCommonStyled>
    );
};
export { TextCommon };
