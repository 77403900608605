import React, { ChangeEventHandler } from 'react';
import { ButtonRow } from '@consumer/type-button';

type TSelectPhotosProps = {
    label?: string;
    iconName?: string;
    handleChange: ChangeEventHandler;
};
const SelectPhotos = ({ label, iconName, handleChange }: TSelectPhotosProps) => {
    return (
        <label htmlFor="file">
            <ButtonRow as="div" isWidget={false} label={label} iconName={iconName} />
            <input id="file" type="file" onChange={handleChange} style={{ display: 'none' }} />
        </label>
    );
};
export { SelectPhotos };
