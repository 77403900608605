import React, { MouseEventHandler } from 'react';
import { ButtonRow } from '@consumer/type-button';

type TTakePhotoProps = {
    label?: string;
    iconName?: string;
    handleClick: MouseEventHandler;
};
const TakePhoto = ({ label, iconName, handleClick }: TTakePhotoProps) => {
    return <ButtonRow as="button" isWidget={false} label={label} iconName={iconName} handleClick={handleClick} />;
};
export { TakePhoto };
