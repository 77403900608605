import styled from 'styled-components';
import { ElementType, MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import { fonts } from '@core/theme';

interface IRowTransactionProps {
    type?: 'reactLink';
    to?: string;
    onClick?: MouseEventHandler;
    as?: ElementType;
    $enabled?: boolean;
    $alignRight?: boolean;
}

export const TransactionRow = styled.div<IRowTransactionProps>`
    display: flex;
    justify-content: ${(props) => (props.$alignRight ? 'flex-end' : 'flex-start')};
    flex-grow: 1;
    overflow: hidden;
`;
export const TransactionRight = styled.div`
    margin-left: 8px;
    white-space: nowrap;
`;

export const CommonRowTransaction = styled(Link)<IRowTransactionProps>`
    font-family: ${fonts.regular};
    border: none;
    width: 100%;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    outline: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    vertical-align: top;
    cursor: ${(props) => (props.$enabled ? 'pointer' : 'default')};
    pointer-events: auto;
    font-size: 17px;
    line-height: 24px;
    border-radius: 0;
    padding: 16px 24px;
    min-height: 72px;
    text-align: left;
    color: ${(props) => props.theme.black};
    background: ${(props) => props.theme.white};

    &:hover {
        background: ${(props) => props.theme.grey_8_lc};
    }

    ${TransactionRow} {
        margin-bottom: 4px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;
export const TransactionContent = styled.div`
    width: 100%;
    overflow: hidden;
`;
