import styled from 'styled-components';
import { WithPrefix } from '@core/types';
import { marginHelperWithPrefix } from '@consumer/ui';

interface IWidgetContainerProps {
    hide?: boolean;
    marginTop?: number;
    marginRight?: number;
    marginBottom?: number;
    marginLeft?: number;
}

type StyledComponentProps = WithPrefix<IWidgetContainerProps>;

export const WidgetContainer = styled.div<StyledComponentProps>`
    display: ${(props) => (props.$hide ? 'none' : null)};
    ${marginHelperWithPrefix}
`;
