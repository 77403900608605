import { useSubmitByEnter } from '../../hooks/button';
import React from 'react';
import styled from 'styled-components';
import { media } from '@core/theme';

const PageWrapperStyled = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    .vertical-layout {
        ${media.mobile481} {
            max-width: 480px;
        }
        ${media.tablet708} {
            //W_layout = ((W_screen – 24*7) / 6) * 4 + 24 * 5
            max-width: calc(((100% - 168px) / 6) * 4 + 120px);

            & .vertical-layout {
                max-width: 100%;
            }
        }
        ${media.tablet889} {
            max-width: 600px;
        }
    }
`;
const PageWrapper = ({ children }: { children: React.ReactNode }) => {
    const handleKeyDown = useSubmitByEnter();
    return <PageWrapperStyled onKeyDown={handleKeyDown}>{children}</PageWrapperStyled>;
};
export { PageWrapper };
