import { TextCommon } from '@consumer/ui';
import { TButtonOption, TTimerButtonData, TWidgetProps } from '@core/types';
import React from 'react';
import { useInterval } from '../hooks';
import { ButtonRow } from './ButtonRow';

export const TimerButton = ({ widget, hooks }: TWidgetProps<TTimerButtonData, TButtonOption>) => {
    const { data, options, uid } = widget;
    const { time, isShowButton } = useInterval(1000, data?.time);

    const getTextAlign = () => {
        if (options?.alignment === 'center') {
            return 'center';
        }
        return 'start';
    };

    let content = <TextCommon uid={uid} options={{ textColor: 'grey_72_lc', textAlign: getTextAlign(), ...options }} content={`${data?.timerTitle} ${time}`} />;

    if (!data?.time || isShowButton) {
        content = <ButtonRow isWidget={true} widget={widget} as="button" hooks={hooks} />;
    }

    return content;
};
