import {
    ActionActionTypeEnum,
    TCommonData,
    TCommonDataObj,
    TItem,
    TListData,
    TListOption,
    TMutableWidgetsData,
    TMutableWidgetsOption,
    TUseGetWidget,
    TUseListHook,
    TWAction,
    TWidget,
} from '@core/types';
import { actionsCall } from '@core/utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { selectMutableWidget, selectScenario, updateWidget, updateWidgetValue } from '../features/scenario.slice';
import { fetchScenario } from '../features/utils/sharedActions';
import { getWidgetDataOrUpdatePage } from '../services/apiService';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { useApp } from './app';

const DEFAULT_DELAY = 1000;

export const useGetWidget = <TD, TO>(wid: TWidget<TD, TO>['wid']): TUseGetWidget<TD, TO> => {
    // @ts-ignore
    const widget: TWidget<TD, TO> = useAppSelector((state) => selectMutableWidget(state, wid));
    return {
        widget,
    };
};

export const useUpdateWidgetValue = () => {
    const dispatch = useAppDispatch();
    return {
        updateWidgetValue: useCallback(
            (value: TCommonDataObj | string, wid: string) =>
                dispatch(
                    updateWidgetValue({
                        value,
                        wid,
                    }),
                ),
            [dispatch],
        ),
    };
};
export const useUpdateWidget = (wid: string) => {
    const dispatch = useAppDispatch();
    const mutableWidget = useAppSelector((state) => selectMutableWidget(state, wid));
    return {
        mutableWidget,
        updateWidget: useCallback(
            (widget: TWidget<TMutableWidgetsData, TMutableWidgetsOption>, wid: string) =>
                dispatch(
                    updateWidget({
                        widget,
                        wid,
                    }),
                ),
            [dispatch],
        ),
    };
};

export const useDelayAction = (widget: TWidget<TCommonData>) => {
    const dispatch = useAppDispatch();
    useEffectOnTimer(widget, async (action) => {
        dispatch(fetchScenario({ action }));
    });
    return {};
};
const useEffectOnTimer = (widget: TWidget<TCommonData>, func: (a: TWAction) => void) => {
    const actions = widget?.actions;
    useEffect(() => {
        const idArr: NodeJS.Timeout[] = [];
        if (Array.isArray(actions) && actions.length > 0) {
            const arrActions = actions.filter((a) => a?.actionType === ActionActionTypeEnum.onTimer);
            if (arrActions.length > 0) {
                arrActions.forEach((a) => {
                    idArr.push(setTimeout(async () => func(a), a.delay ?? DEFAULT_DELAY));
                });
            }
        }
        return () => {
            if (idArr.length > 0) {
                idArr.forEach((id) => clearTimeout(id));
            }
        };
    }, [actions, func]);
};

export const useUpdateWidgetInfo = (
    widget: TWidget<TCommonData>,
    setWidget: (value: ((prevState: TWidget<TCommonData>) => TWidget<TCommonData>) | TWidget<TCommonData>) => void,
): object | never => {
    const dispatch = useAppDispatch();
    const scenario = useAppSelector(selectScenario);

    useEffectOnTimer(widget, async (a) => {
        const newWidget = await getWidgetDataOrUpdatePage(
            a,
            widget.internalId,
            scenario.scenarioId,
            scenario.currentPageId,
            scenario.mutableWidgetsMap,
            dispatch,
        ).catch((e) => {
            console.error(`Widget '${widget.id}':${widget.uid} catch error of fetch data:`, e);
        });
        if (newWidget) {
            setWidget(newWidget);
        }
    });
    return {};
};
export const useList = (widget: TWidget<TListData, TListOption>, ref?: React.MutableRefObject<HTMLInputElement | null>): TUseListHook => {
    const dispatch = useAppDispatch();
    const { setModal, setBottomSheet } = useApp();
    const [isVisible, setVisible] = useState(false);
    const observer = useMemo(() => {
        return new IntersectionObserver(([entry]) => setVisible(entry.isIntersecting));
    }, []);

    useEffect(() => {
        if (ref?.current) {
            observer.observe(ref?.current);
        }
        return () => {
            observer.disconnect();
        };
    }, [ref, observer]);

    return {
        handleClick: (wid: string, item: TItem) => {
            return actionsCall(
                ActionActionTypeEnum.onItemSelected,
                async (action) => {
                    dispatch(updateWidgetValue({ value: item.value, wid }));
                    await dispatch(fetchScenario({ action }));
                    setModal(null);
                    setBottomSheet(undefined);
                },
                widget?.actions,
            );
        },
        isVisible,
    };
};
