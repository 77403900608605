import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ProgressRow, Step, StepBg } from './styles';
import { TStories } from './Stories';

type TProgress = {
    count: number;
    currentStep: number;
    isPaused: boolean;
    onStepComplete: () => void;
} & TStories;

const defaultDuration = 2000;
// progressChangeInterval = каждые сколько миллисекунд увеличивать ширину прогресса
const progressChangeInterval = 50;

const Progress = (props: TProgress) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [currentStepProgress, setCurrentStepProgress] = useState(0);
    const { onStepComplete } = props;

    const steps = useMemo(() => [...Array(props.count)], [props.count]);

    const duration = props.duration ?? defaultDuration;
    // changesCount = количество шагов таймера для того, чтобы достичь 100%
    const changesCount = duration / progressChangeInterval;
    // increment = на сколько увеличить ширину за каждый шаг таймера
    const increment = 100 / changesCount;

    const updateProgress = useCallback(() => {
        if (!props.isPaused) {
            setCurrentStepProgress((value) => Math.min(value + increment, 100));
        }
    }, [increment, props.isPaused]);

    useEffect(() => {
        if (currentStepProgress === 100) {
            onStepComplete();
        }
    }, [onStepComplete, currentStepProgress]);

    useEffect(() => {
        setCurrentStep(props.currentStep);
        setCurrentStepProgress(0);
    }, [props.currentStep]);

    useEffect(() => {
        const intervalId = setInterval(updateProgress, progressChangeInterval);
        return () => clearInterval(intervalId);
    }, [updateProgress]);

    return (
        <ProgressRow>
            {steps.map((value, index) => {
                const progress = index < currentStep ? 100 : index > currentStep ? 0 : currentStepProgress;
                return (
                    <Step key={index}>
                        <StepBg style={{ width: progress + '%' }} $isInverted={props.isInverted} />
                    </Step>
                );
            })}
        </ProgressRow>
    );
};
export { Progress };
