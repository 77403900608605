import { Link } from 'react-router-dom';
import styled, { ExecutionContext } from 'styled-components';
import { marginHelperWithPrefix } from '@consumer/ui';
import { WithPrefix } from '@core/types';

type TSize = 'medium' | 'small';
type THierarchy = 'tertiary';

type TButtonCircle = {
    hierarchy: THierarchy;
    disabled: boolean;
    position?: 'floating' | 'inline';
    size?: TSize;
    marginTop?: number;
    marginRight?: number;
    marginBottom?: number;
    marginLeft?: number;
};

type StyledComponentProps = ExecutionContext & WithPrefix<TButtonCircle>;

const getBoxShadow = (props: StyledComponentProps) => {
    const raised = props.$position === 'floating' && !props.$disabled;
    return raised ? `${props.theme.shadow_grey}` : 'none';
};

const getSize = (size?: TSize) => {
    switch (size) {
        case 'small':
            return '28px';
        case 'medium':
            return '40px';
        default:
            return '40px';
    }
};

export const StyledButton = styled(Link)<StyledComponentProps>`
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.white};
    border: none;
    border-radius: 50%;
    width: ${(props) => getSize(props.$size)};
    height: ${(props) => getSize(props.$size)};
    padding: 0;
    box-shadow: ${(props) => getBoxShadow(props)};
    pointer-events: auto;
    cursor: ${(props) => (props.$disabled ? 'default' : 'pointer')};
    ${marginHelperWithPrefix}

    &:hover {
        background-color: ${(props) => (props.$disabled ? props.theme.white : props.theme.grey_16_lc)};
    }

    @media (hover: hover) {
        &:hover,
        &:active {
            background-color: ${(props) => (props.$disabled ? props.theme.white : props.theme.grey_16_lc)};
        }
    }

    &:focus {
        background-color: ${(props) => props.theme.grey_8_lc};
    }
`;
