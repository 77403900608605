import { ButtonPill } from '@consumer/type-button';
import React from 'react';
import { CalculatorButtonStyled } from './styles';

type TCalculatorButtonProps = {
    isEnabled: boolean;
    label: string;
    handleClick: () => void;
};
const CalculatorButton = React.memo(({ isEnabled, label, handleClick }: TCalculatorButtonProps) => {
    return (
        <CalculatorButtonStyled>
            <ButtonPill
                as="button"
                hierarchy="primary"
                position="floating"
                isWidget={false}
                label={label ?? ''}
                handleClick={handleClick}
                isEnabled={isEnabled}
            />
        </CalculatorButtonStyled>
    );
});
export { CalculatorButton };
