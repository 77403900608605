import { THooks } from '@core/types';
import { useApp, useDetectDocumentScroll } from './app';
import { useInput, useInputPhone, useCheckBox } from './input';
import { useButton } from './button';
import { useFunction } from './function';
import { useUpdateWidgetValue, useUpdateWidget, useUpdateWidgetInfo, useDelayAction, useList, useGetWidget } from './widgets';
import { useFetchData } from './api';

export const hooks: THooks<any> = {
    useGetWidget,
    useInput,
    useInputPhone,
    useButton,
    useDelayAction,
    useCheckBox,
    useList,
    useUpdateWidgetInfo,
    useFetchData,
    useUpdateWidget,
    useUpdateWidgetValue,
    useApp,
    useDetectDocumentScroll,
    useFunction,
};
