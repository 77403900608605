import { Separator } from '@consumer/type-separator';
import { TItem, TListData } from '@core/types';
import React from 'react';
import ListItems, { TListItemProps } from '../ListItems';
import { SectionsWrapper } from '../styles';

type TListSectionItemsProps = {
    renderItem: React.FC<TListItemProps>;
    data: TListData;
};

const ListSectionItems = ({ data, renderItem }: TListSectionItemsProps) => {
    const sections = data?.sections;
    if (sections && Array.isArray(sections) && sections.length > 0) {
        return (
            <SectionsWrapper>
                {sections.map((section) => {
                    const filteredItems = section.itemsIds.map((id) => {
                        if (data.items) {
                            return data.items?.find((item) => item.id === id);
                        }
                        return [];
                    }) as TItem[];
                    return (
                        <React.Fragment key={section.header}>
                            <Separator isWidget={false} separatorVariant={'subheader'} text={section.header.toLowerCase()} />
                            <ListItems items={filteredItems} renderItem={renderItem} />
                        </React.Fragment>
                    );
                })}
            </SectionsWrapper>
        );
    }
    return null;
};
export default ListSectionItems;
