import React from 'react';
import styled, { ExecutionContext } from 'styled-components';
import { fonts } from '@core/theme';
import { WithPrefix } from '@core/types';

interface IInputWrapperPropsStyled {
    hide?: boolean;
    isError?: boolean;
    isFilled?: boolean;
    isFocused?: boolean;
    isDisabled?: boolean;
    isDropdownInput?: boolean;
    alignCenter?: boolean;
    marginRight?: number;
    marginLeft?: number;
    marginTop?: number;
    marginBottom?: number;
    rightInputComponent?: React.ReactNode;
}
type StyledComponentProps = ExecutionContext & WithPrefix<IInputWrapperPropsStyled>;

const getLabelColor = (props: StyledComponentProps): string => {
    if (props.$isDisabled) {
        return props.theme.grey_40_lc;
    } else if (!props.$isFocused && !props.$isError) {
        return props.theme.grey_72_lc;
    } else if (props.$isFocused && !props.$isError) {
        return props.theme.purple_100;
    } else if (props.$isError) {
        return props.theme.pink_100;
    } else {
        return props.theme.grey_72_lc;
    }
};

const getBorderColor = (props: StyledComponentProps): string => {
    if (!props.$isFocused && !props.$isError) {
        return props.theme.grey_16_lc;
    } else if (props.$isFocused && !props.$isError) {
        return props.theme.purple_100;
    } else if (props.$isError) {
        return props.theme.pink_100;
    } else {
        return props.theme.grey_16_lc;
    }
};

const getFloatingLabelPosition = (props: StyledComponentProps): string | undefined => {
    return props.$isFilled || props.$isFocused ? '-12px' : '16px';
};

export const InputContainer = styled.div<StyledComponentProps>`
    font-family: ${fonts.regular};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: ${(props) => (props.$hide ? 'none' : 'flex')};
    flex-direction: row;
    align-items: flex-end;
    position: relative;
    background: ${(props) => props.theme.white};
    border: ${(props) => (props.$isDisabled ? `1px dashed ${props.theme.grey_16_lc}` : `1px solid ${props.theme.grey_16_lc}`)};
    border-bottom: ${(props) => (props.$isDisabled ? null : `1px solid ${getBorderColor(props)}`)};
    border-radius: 6px;
    pointer-events: ${(props) => (props.$isDisabled ? 'none' : null)};
    margin-top: ${(props) => (props.$marginTop ? `${props.$marginTop}px` : 0)};
    margin-left: ${(props) => (props.$marginLeft ? `${props.$marginLeft}px` : 0)};
    margin-right: ${(props) => (props.$marginRight ? `${props.$marginRight}px` : 0)};
    margin-bottom: ${(props) => (props.$marginBottom ? `${props.$marginBottom}px` : 0)};

    &:after {
        content: '';
        position: absolute;
        width: calc(100% - 4px);
        height: 1px;
        background: ${(props) => ((!props.$isFocused && !props.$isError) || props.$isDisabled ? null : getBorderColor(props))};
        bottom: 0;
        left: 2px;
    }
`;

export const InputLabel = styled.label<StyledComponentProps>`
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    padding: ${(props) => (props.$rightInputComponent ? '16px 40px 16px 16px' : '16px')};
    z-index: 2;
    cursor: ${(props) => (props.$isDropdownInput ? 'pointer' : null)};

    input[type='search' i]::-webkit-search-cancel-button {
        -webkit-appearance: none;
    }
    input,
    input[type='search'],
    textarea {
        font-family: ${fonts.regular};
        background-color: transparent;
        border: none;
        box-sizing: border-box;
        display: inline-block;
        padding: 0;
        width: 100%;
        font-size: 17px;
        line-height: 24px;
        text-overflow: ellipsis;
        cursor: ${(props) => (props.$isDropdownInput ? 'pointer' : 'auto')};
        color: ${(props) => (props.$isDisabled ? props.theme.grey_40_lc : props.$isError ? props.theme.pink_100 : props.theme.black)};
        outline: none;

        &:hover {
            color: ${(props) => (props.$isDisabled ? props.theme.grey_40_lc : props.$isError ? props.theme.pink_100 : null)};
        }
    }
`;

export const FloatingLabel = styled.span<StyledComponentProps>`
    font-family: ${fonts.regular};
    position: absolute;
    top: ${(props) => getFloatingLabelPosition(props)};
    left: 8px;
    font-size: ${(props) => (props.$isFilled || props.$isFocused ? '14px' : '17px')};
    line-height: ${(props) => (props.$isFilled || props.$isFocused ? '20px' : '24px')};
    color: ${(props) => getLabelColor(props)};
    background: ${(props) => props.theme.white};
    transition: top 0.2s;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: calc(100% - 16px);
    box-sizing: border-box;
    padding: 0 8px;
`;

export const HintContainer = styled.div<StyledComponentProps>`
    font-family: ${fonts.regular};
    font-size: 14px;
    line-height: 20px;
    color: ${(props) => (props.$isDisabled ? props.theme.grey_40_lc : props.$isError ? props.theme.pink_100 : props.theme.grey_72_lc)};
    margin-top: 8px;
    margin-left: ${(props) => (props.$marginLeft ? `${props.$marginLeft}px` : '16px')};
    margin-right: ${(props) => (props.$marginRight ? `${props.$marginRight}px` : '16px')};
    justify-content: ${(props) => (props.$alignCenter ? 'center' : 'flex-start')};
    display: flex;
`;

export const InputRightElement = styled.div`
    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 3;
`;
