import { LayoutEnum, TCommonData, TGroupWidgetProps, TLayoutOption, TWidget } from '@core/types';
import React from 'react';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';
import { FloatingLayout } from './src/FloatingLayout';
import { HorizontalLayout } from './src/HorizontalLayout';
import { SeparateLayoutTransition } from './src/SeparateLayoutTransition';
import { VerticalLayout } from './src/VerticalLayout';
import { VerticalLayoutWide } from './src/VerticalLayoutWide';

type TTypeLayout = {
    Package: React.ElementType;
    widget: TWidget<TCommonData, TLayoutOption>;
} & TGroupWidgetProps<TCommonData, TLayoutOption>;

const TypeLayout = ({ widgetId, Package, widget, hooks }: TTypeLayout) => {
    const isKeyboardOpen = useDetectKeyboardOpen(400, false);
    const options = widget?.options || {};
    const margin = isKeyboardOpen && options?.keyboardMargin ? options?.keyboardMargin : options?.margin;
    switch (widgetId) {
        case LayoutEnum.VERTICAL_LAYOUT:
            return (
                <VerticalLayout widget={widget} margin={margin} hooks={hooks}>
                    <Package widgets={widget.widgets} />
                </VerticalLayout>
            );
        case LayoutEnum.VERTICAL_LAYOUT_WIDE:
            return (
                <VerticalLayoutWide widget={widget} margin={margin} hooks={hooks}>
                    <Package widgets={widget.widgets} />
                </VerticalLayoutWide>
            );
        case LayoutEnum.HORIZONTAL_LAYOUT:
            return (
                <HorizontalLayout widget={widget} margin={margin} hooks={hooks}>
                    <Package widgets={widget.widgets} />
                </HorizontalLayout>
            );
        case LayoutEnum.FLOATING_LAYOUT:
            return (
                <FloatingLayout widget={widget} margin={margin} hooks={hooks}>
                    <Package widgets={widget.widgets} />
                </FloatingLayout>
            );
        case LayoutEnum.SEPARATE_LAYOUT:
        case LayoutEnum.CALCULATOR_CONTENT_LAYOUT:
            return (
                <SeparateLayoutTransition
                    widget={widget}
                    topWidget={<Package widgets={widget?.widgets?.[0] ? [widget.widgets[0]] : []} />}
                    bottomWidget={<Package widgets={widget?.widgets?.[1] ? [widget.widgets[1]] : []} />}
                    hooks={hooks}
                />
            );
        default:
            return false;
    }
};

export default TypeLayout;
