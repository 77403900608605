import { ConditionalRender } from '@core/utils';
import React, { useCallback, useEffect, useState } from 'react';
import { flushSync } from 'react-dom';
import { selectBottomSheets } from '../../features/scenario.slice';
import { useAppSelector } from '../../store/hooks';
import { Portal } from '../common/Portal';
import { usePortal } from '../common/PortalWrapper';
import WidgetsList from '../Widgets/WidgetsList';
import { AnimationEvent, usePopup } from './hooks';
import { getOutAnimation, Overlay, PopupBox, PopupBoxButtons, PopupBoxContent, PopupBoxContentWrapper, PopupOuter } from './styles';

type TBottomSheetProps = {
    widgetId?: string;
    modal: React.ReactNode | null;
};
const BottomSheet = ({ widgetId, modal }: TBottomSheetProps) => {
    const bottomSheets = useAppSelector(selectBottomSheets);
    const { setBottomSheet } = usePortal();
    const [animated, setAnimated] = useState(false);
    const [id, setId] = useState<string>('');
    const currentBottomSheet = bottomSheets?.[id]?.widgets;
    const hasCurrentBottomSheet = currentBottomSheet && Object.keys(currentBottomSheet).length > 0;
    const { handlers, overlayRef, overlayClick } = usePopup(id && hasCurrentBottomSheet, setAnimated, modal);
    const isComplexBottomSheet = hasCurrentBottomSheet && currentBottomSheet.length > 1;
    const bottomSheetContent = isComplexBottomSheet ? [currentBottomSheet[0]] : currentBottomSheet;

    useEffect(() => {
        if (widgetId) {
            setId(widgetId as string);
        } else if (id) {
            setAnimated(true);
        }
    }, [widgetId, id]);

    const closeAnimationPopup = useCallback(
        (e: AnimationEvent<HTMLDivElement>) => {
            const name = (getOutAnimation as unknown as { name: string }).name;
            if (e?.animationName === name) {
                flushSync(() => {
                    setId('');
                    setAnimated(false);
                    setBottomSheet(undefined);
                });
            }
        },
        [setBottomSheet],
    );

    if (id && hasCurrentBottomSheet) {
        return (
            <Portal>
                <div id="bottom-sheet" {...handlers}>
                    <Overlay onClick={overlayClick}>
                        <PopupOuter>
                            <PopupBox $animated={animated} onAnimationEnd={closeAnimationPopup} ref={overlayRef}>
                                <PopupBoxContentWrapper>
                                    <PopupBoxContent>
                                        <WidgetsList widgets={bottomSheetContent} />
                                    </PopupBoxContent>
                                </PopupBoxContentWrapper>
                                <ConditionalRender condition={isComplexBottomSheet}>
                                    <PopupBoxButtons>
                                        <WidgetsList widgets={[currentBottomSheet[1]]} />
                                    </PopupBoxButtons>
                                </ConditionalRender>
                            </PopupBox>
                        </PopupOuter>
                    </Overlay>
                </div>
            </Portal>
        );
    }
    return null;
};
export default BottomSheet;
