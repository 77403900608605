import { SeparatorEnum, TGroupWidgetProps, TSeparatorData, TSeparatorOption } from '@core/types';
import React from 'react';
import { Separator } from './Separator';

const TypeSeparator: React.FC<TGroupWidgetProps<TSeparatorData, TSeparatorOption>> = ({ widgetId, wid, hooks }) => {
    const { widget } = hooks.useGetWidget(wid);
    switch (widgetId) {
        case SeparatorEnum.SEPARATOR:
            return <Separator isWidget={true} widget={widget} hooks={hooks} />;
        default:
            return false;
    }
};

export { Separator };
export default TypeSeparator;
