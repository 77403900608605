import styled from 'styled-components';
import { media, zIndex } from '@core/theme';
import { WithPrefix } from '@core/types';

interface IProgressProps {
    isInverted?: boolean;
}

type StyledComponentProps = WithPrefix<IProgressProps>;

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    user-select: none;
`;
export const ProgressRow = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0 24px;
    width: 100%;
    max-width: 552px;
    box-sizing: border-box;
    position: fixed;
    top: 12px;

    ${media.desktop1920} {
        top: 16px;
    }
`;

export const Step = styled.div`
    display: inline-flex;
    flex-grow: 1;
    height: 2px;
    border-radius: 4px;
    background: ${(props) => props.theme.grey_16_lc};
    margin-right: 4px;
    position: relative;

    &:last-child {
        margin-right: 0;
    }
`;
export const StepBg = styled.div<StyledComponentProps>`
    height: 2px;
    border-radius: 4px;
    background: ${(props) => (props.$isInverted ? props.theme.grey_16_lc : props.theme.purple_100)};
`;

export const Overlay = styled.div`
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    div {
        width: 50%;
    }
`;

export const StoriesContent = styled.div`
    button {
        cursor: pointer;
        position: relative;
        z-index: ${zIndex.storiesButtons};
    }
`;
