import React from 'react';
import { useTheme } from 'styled-components';
import { ic_chevron_down_small as ChevronIcon } from '@consumer/assets';
import { FlagIcon, PopupComponent, TextCommon } from '@consumer/ui';
import { ButtonRow } from '@consumer/type-button';
import { TItem, TSetFunc } from '@core/types';
import { CurrencyDropdownBlock } from '../styles';
import { TName, TServerCalculationFunc } from '../utils/widgets';
import { ListCurrency } from '../ListCurrency';

type TCurrencyDropdownProps = {
    value: string;
    flagIcon: string;
    name: TName;
    header: string;
    setPopup?: TSetFunc;
    buttonLabel: string;
    index: number;
    items: TItem[];
    isFrom: boolean;
    onServerCalculation: TServerCalculationFunc;
};

const CurrencyDropdown = React.memo(
    ({ value, flagIcon, setPopup, name, header, buttonLabel, index, items, isFrom, onServerCalculation }: TCurrencyDropdownProps) => {
        const localTheme = useTheme();
        const handleClick = (name: TName) => {
            setPopup?.(
                <PopupComponent
                    header={header}
                    internalContent={
                        <ListCurrency
                            index={index}
                            items={items}
                            handleClick={async (id) => {
                                setPopup(null);
                                await onServerCalculation(id, name, isFrom);
                            }}
                        />
                    }
                    externalContent={
                        <ButtonRow as="button" isWidget={false} label={buttonLabel} iconName={'ic_info_circle'} handleClick={() => setPopup(null)} />
                    }
                />,
            );
        };
        return (
            <CurrencyDropdownBlock onClick={() => handleClick(name)}>
                <FlagIcon name={flagIcon} />
                <TextCommon content={value} options={{ variant: 'main', textColor: 'black', margin: { left: 4 } }} />
                <ChevronIcon fill={localTheme.black} />
            </CurrencyDropdownBlock>
        );
    },
);

export { CurrencyDropdown };
