import * as Svg from '@consumer/assets';
import { TButtonData, TButtonOption, THooks, TWidget } from '@core/types';
import React, { ElementType, MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { DefaultTheme, useTheme } from 'styled-components';
import { StyledButton } from './styles/ButtonCircle';

type TButtonCircle<TD, TO> = (
    | {
          isWidget: false;
          iconName?: string;
          iconColor?: string;
          position?: 'floating' | 'inline';
          size?: 'small' | 'medium';
          handleClick?: (e: MouseEvent<HTMLElement>) => void;
          isEnabled?: boolean;
      }
    | {
          isWidget: true;
          widget: TWidget<TD, TO>;
      }
) & {
    as?: ElementType;
    hooks?: THooks<TD>;
};

export function ButtonCircle(props: TButtonCircle<TButtonData, TButtonOption>) {
    const { isWidget, hooks } = props;
    const localTheme = useTheme();
    const hook = isWidget ? hooks?.useButton(props.widget) : undefined;
    const useDetectDocumentScroll = hooks?.useDetectDocumentScroll(isWidget ? props.widget.uid : '');
    const iconName = isWidget ? props.widget?.options?.iconName : props.iconName;
    const label = isWidget ? props.widget?.data?.label : '';
    const IconComponent = Svg[iconName as keyof typeof Svg] ?? Svg['ic_x' as keyof typeof Svg];

    const isDisabled = () => (isWidget ? !(props.widget.options?.enabled ?? true) : props.isEnabled === false || false);

    const getPosition = () => {
        const dynamicShadow = isWidget ? props.widget?.options?.dynamicShadow || true : true;
        if (dynamicShadow && useDetectDocumentScroll?.documentScrolled && !isDisabled()) {
            return 'floating';
        } else if (isWidget) {
            return props.widget?.options?.position;
        }
    };
    const getFill = () => {
        if (isDisabled()) {
            return localTheme.grey_40_lc;
        } else if (!isWidget && props.iconColor) {
            return localTheme[props.iconColor as keyof DefaultTheme] ?? localTheme.black;
        }
        return localTheme.black;
    };

    return (
        <StyledButton
            as={props.as ?? Link}
            aria-label={label}
            $hierarchy={isWidget ? props.widget?.options?.hierarchy : ''}
            $size={isWidget ? props.widget?.options?.size : props.size}
            $disabled={isDisabled()}
            $position={getPosition()}
            to="/"
            onClick={isWidget ? hook?.handleClick : props.handleClick}
            id={isWidget ? props.widget?.id : ''}
            title={label}
            data-test={isWidget ? props.widget?.uid : ''}
            $marginBottom={isWidget ? props.widget?.options?.margin?.bottom : ''}
            $marginTop={isWidget ? props.widget?.options?.margin?.top : ''}
            $marginLeft={isWidget ? props.widget?.options?.margin?.left : ''}
            $marginRight={isWidget ? props.widget?.options?.margin?.right : ''}
        >
            {IconComponent && <IconComponent fill={getFill()} />}
        </StyledButton>
    );
}
