import { TextCommon } from '@consumer/ui';
import { FunctionActionTypeEnum, TCheckboxOption, TInputData, TWidgetProps, WithPrefix } from '@core/types';
import React, { useId } from 'react';
import styled from 'styled-components';
import { WidgetContainer } from './styles';

interface ISwitcherProps {
    isDisabled?: boolean;
}

type StyledComponentProps = WithPrefix<ISwitcherProps>;

const SwitchInputBlock = styled.div`
    display: flex;
    justify-content: space-between;
`;
const SwitchInput = styled.input`
    display: none;
`;
const SwitchControl = styled.label<StyledComponentProps>`
    display: inline-block;
    position: relative;
    width: 48px;
    height: 28px;
    cursor: ${(props) => (props.$isDisabled ? 'default' : 'pointer')};

    &::before {
        background-color: ${(props) => props.theme.grey_40_lc};
        border-radius: 14px;
        content: '';
        height: 100%;
        right: 0;
        position: absolute;
        top: 0;
        width: 100%;
        transition-duration: 0.15s;
        transition-property: background-color;
    }

    &::after {
        background-color: ${(props) => props.theme.white};
        border-radius: 50%;
        content: '';
        height: 20px;
        left: 4px;
        position: absolute;
        top: 4px;
        width: 20px;
    }
`;
const SwitchWrapper = styled.div<StyledComponentProps>`
    opacity: ${(props) => (props.$isDisabled ? '0.4' : null)};
    pointer-events: ${(props) => (props.$isDisabled ? 'none' : null)};

    ${SwitchInput}:checked + ${SwitchInputBlock} ${SwitchControl} {
        &::before {
            background-color: ${(props) => props.theme.purple_100};
        }

        &::after {
            left: auto;
            right: 4px;
        }
    }
`;

const Switcher = ({ widget, hooks }: TWidgetProps<TInputData, TCheckboxOption>) => {
    const id = useId();
    const { data, uid, options } = widget;
    const { handleChange, isChecked } = hooks.useCheckBox(widget);
    const { getFunctionResult, hasFunctionForAction } = hooks.useFunction(widget);
    const hideInput = hasFunctionForAction(FunctionActionTypeEnum.HIDE) ? getFunctionResult(FunctionActionTypeEnum.HIDE) : false;
    const isDisabled = hasFunctionForAction?.(FunctionActionTypeEnum.DISABLED)
        ? getFunctionResult?.(FunctionActionTypeEnum.DISABLED)
        : !(options.enabled ?? true);

    return (
        <WidgetContainer
            $hide={hideInput}
            $marginBottom={options?.margin?.bottom}
            $marginTop={options?.margin?.top}
            $marginLeft={options?.margin?.left}
            $marginRight={options?.margin?.right}
            data-test={uid}
        >
            <SwitchWrapper $isDisabled={isDisabled}>
                <SwitchInput id={id} name={data?.label} type="checkbox" checked={isChecked} onChange={handleChange} />
                <SwitchInputBlock>
                    {data?.label && <TextCommon content={data?.label} options={{ textColor: 'grey_72_lc', margin: { right: 24 } }} />}
                    <SwitchControl htmlFor={id} $isDisabled={isDisabled} />
                </SwitchInputBlock>
            </SwitchWrapper>
        </WidgetContainer>
    );
};
export { Switcher };
