import React from 'react';
import styled from 'styled-components';
import { TextCommon } from '@consumer/ui';

export type TTagProps = {
    metaText?: string;
    metaVariant?: 'text' | 'tag';
    disabled?: boolean;
};

const TagContainer = styled.span<TTagProps>`
    display: inline-block;
    border-radius: 24px;
    background: ${(props) => (props.metaVariant === 'tag' ? (props.disabled ? props.theme.purple_16_lc : props.theme.purple_100) : null)};
    padding: ${(props) => (props.metaVariant === 'tag' ? `5px 10px` : null)};
`;

const getTagVariant = (props: TTagProps) => {
    return props.metaVariant === 'tag' ? 'tag' : 'main';
};
const getTagColor = (props: TTagProps) => {
    if (props.metaVariant === 'tag') {
        return 'white_base';
    }
    return props.disabled ? 'grey_40_lc' : 'black';
};

export const Tag = (props: TTagProps) => {
    return (
        <TagContainer metaVariant={props.metaVariant} disabled={props.disabled}>
            <TextCommon content={props.metaText} options={{ variant: getTagVariant(props), textColor: getTagColor(props) }} />
        </TagContainer>
    );
};
