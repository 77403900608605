import React from 'react';
import styled from 'styled-components';
import { TCommonData, TLayout, TLayoutOption, TWidgetProps, WithPrefix } from '@core/types';
import { IHorizontalLayoutProps } from '../interface/interface';

type StyledComponentProps = WithPrefix<IHorizontalLayoutProps>;

const getAlignment = (props: StyledComponentProps) => {
    switch (props.$alignment) {
        case 'start':
            return 'start';
        case 'center':
            return 'center';
        case 'end':
            return 'end';
    }
};

const getJustify = (props: StyledComponentProps) => {
    switch (props.$arrangement) {
        case 'start':
            return 'flex-start';
        case 'center':
            return 'center';
        case 'end':
            return 'flex-end';
        case 'space-between':
            return 'space-between';
        case 'space-around':
            return 'space-around';
    }
};

const HorizontalBlock = styled.div<StyledComponentProps>`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    align-items: ${(props: StyledComponentProps) => getAlignment(props)};
    justify-content: ${(props: StyledComponentProps) => getJustify(props)};
    margin-top: ${(props) => (props.$marginTop ? `${props.$marginTop}px` : 0)};
    padding-left: ${(props) => (props.$marginLeft ? `${props.$marginLeft}px` : 0)};
    padding-right: ${(props) => (props.$marginRight ? `${props.$marginRight}px` : 0)};
    margin-bottom: ${(props) => (props.$marginBottom ? `${props.$marginBottom}px` : 0)};
`;

export const HorizontalLayout: React.FC<TWidgetProps<TCommonData, TLayoutOption> & TLayout> = ({ widget, children, margin }) => {
    const options = widget?.options || {};

    return (
        <HorizontalBlock
            data-test={widget?.uid}
            $alignment={options.alignment}
            $arrangement={options?.arrangement}
            $marginTop={margin?.top}
            $marginRight={margin?.right}
            $marginBottom={margin?.bottom}
            $marginLeft={margin?.left}
        >
            {children}
        </HorizontalBlock>
    );
};
