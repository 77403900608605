import React from 'react';

import { CenterContainer, Container, HeaderText, MainText, SecondaryText, Wrapper } from './styles';
import { useTheme } from 'styled-components';

export type TAddProps = {
    hasBorder?: boolean;
    breakWord?: boolean;
};

export type TCommonScreenProps = {
    Icon:
        | React.ComponentType<{
              fill: string;
          }>
        | React.FC;
    headerText?: string;
    secondaryText?: string;
    mainText?: string;
    uid?: string;
};

export const CommonScreen = (props: TCommonScreenProps & TAddProps) => {
    const localTheme = useTheme();
    const { Icon, headerText, secondaryText, mainText, hasBorder, breakWord } = props;
    return (
        <Wrapper data-test={props.uid}>
            <Container>
                <CenterContainer>
                    <div>
                        <Icon fill={localTheme.pink_100} />
                    </div>
                    {/* Для фиолетового выделения следующая конструкция: <><span>{purpleText}</span>{headerText}</span></>*/}
                    {headerText && <HeaderText>{headerText}</HeaderText>}
                    {secondaryText && <SecondaryText>{secondaryText}</SecondaryText>}
                    {/* текст небольшого размера */}
                    {mainText && (
                        <MainText hasBorder={hasBorder} breakWord={breakWord}>
                            {mainText}
                        </MainText>
                    )}
                </CenterContainer>
            </Container>
        </Wrapper>
    );
};
