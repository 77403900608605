import { TTextData } from '@core/types';

export const getMarkup = (data: TTextData) => {
    const { links = {}, text = '' } = data;
    let newText;
    if (links && Object.keys(links).length > 0) {
        for (const [key, value] of Object.entries(links)) {
            if (value) {
                newText = (newText || text).replace(key, `<a target='_blank' href=${value}>${key}</a>`);
            }
        }
    }
    return newText;
};
