import React from 'react';
import { TCalculatorError, TItem, TSetFunc, TWAction } from '@core/types';
import { CurrencyDropdownEnum, InputEnum, THandleUpdateValue, TServerCalculationFunc } from '../utils/widgets';
import { CurrencyDropdown } from './CurrencyDropdown';
import CalculatorError from './CalculatorError';

type TSubComponentProps = {
    dropdownName: CurrencyDropdownEnum.FROM_CURRENCY | CurrencyDropdownEnum.TO_CURRENCY;
    inputName: InputEnum.FROM_INPUT | InputEnum.TO_INPUT;
    value: string;
    index: number;
    flagIcon: string;
    setPopup?: TSetFunc;
    isFrom: boolean;
    items: TItem[];
    error?: TCalculatorError;
    dropDownHeader: string;
    dropDownButtonLabel: string;
    onServerCalculation: TServerCalculationFunc;
    handleUpdateValue: THandleUpdateValue;
    fetchScenario: (actions: TWAction) => void;
};
const SubComponent = ({
    dropdownName,
    inputName,
    value,
    index,
    flagIcon,
    setPopup,
    isFrom,
    onServerCalculation,
    handleUpdateValue,
    fetchScenario,
    items,
    error,
    dropDownHeader,
    dropDownButtonLabel,
}: TSubComponentProps) => {
    if (error?.shortText) {
        return (
            <CalculatorError
                setPopup={setPopup}
                shortText={error?.shortText}
                longText={error?.longText}
                name={inputName}
                newInputValue={error?.newInputValue}
                gotIt={error?.gotIt}
                changeDeliveryOptions={error?.changeDeliveryOptions}
                changeAmount={error?.changeAmount}
                changeTransferOptions={error?.changeTransferOptions}
                handleUpdateValue={handleUpdateValue}
                fetchScenario={fetchScenario}
                actions={error?.actions}
                type={error?.type}
            />
        );
    }
    return (
        <CurrencyDropdown
            isFrom={isFrom}
            items={items}
            index={index}
            header={dropDownHeader}
            buttonLabel={dropDownButtonLabel}
            name={dropdownName}
            value={value}
            flagIcon={flagIcon}
            setPopup={setPopup}
            onServerCalculation={onServerCalculation}
        />
    );
};
export { SubComponent };
