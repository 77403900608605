import React, { Dispatch, SetStateAction } from 'react';
import { Selector } from '@consumer/type-row';
import { RowEnum, TItem } from '@core/types';
import { convertItemToRowComponentProps } from '@core/utils';
import { ListItems } from '@consumer/type-list';

type TListCurrency = {
    index: number;
    items: TItem[];
    handleClick: (id: string) => void;
};
const ListCurrency = ({ items, index, handleClick }: TListCurrency) => {
    const renderItem = ({
        item,
        index,
        isSelected,
        setSelectedIndex,
    }: {
        item: TItem;
        index: number;
        isSelected?: boolean;
        setSelectedIndex?: Dispatch<SetStateAction<number | undefined>>;
    }) => {
        const props = convertItemToRowComponentProps(item, RowEnum.SELECTOR);
        const { isSelected: selected, ...rest } = props;
        return (
            <Selector
                key={item.id}
                isWidget={false}
                as={'button'}
                isSelected={isSelected}
                handleClick={() => {
                    setSelectedIndex?.(index);
                    handleClick(item.id);
                }}
                {...rest}
            />
        );
    };

    if (items && Array.isArray(items) && items.length > 0) {
        return <ListItems items={items} renderItem={renderItem} currentIndex={index} />;
    }
    return null;
};

export { ListCurrency };
