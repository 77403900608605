import { Loading } from '@consumer/ui';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ErrorBoundary from './app/components/ErrorBoundary';
import { fetchScenario } from './app/features/utils/sharedActions';
import router from './app/router/router';
import { store } from './app/store/store';
import './main.css';

store.dispatch(fetchScenario({ action: undefined }));

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <Provider store={store}>
        <StrictMode>
            <ErrorBoundary>
                <RouterProvider router={createBrowserRouter(router)} fallbackElement={<Loading />} />
            </ErrorBoundary>
        </StrictMode>
    </Provider>,
);
