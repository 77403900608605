import { Tag, TextCommon } from '@consumer/ui';
import { Visual } from '@consumer/type-image';

import { THandleClick, TListData, TRowComponentProps, TRowOption, TRowRegularData, TWidget, TWidgetProps } from '@core/types';
import React, { ElementType } from 'react';
import { CommonRowRegular, HighlightedTitle, RowContent } from './styles/RowRegular';

type TRowRegularWidget = {
    isWidget: true;
} & TWidgetProps<TRowRegularData, TRowOption>;

type TRowRegularComponent = {
    isWidget: false;
    handleClick?: THandleClick;
} & TRowComponentProps;

type TRowRegular = (TRowRegularWidget | TRowRegularComponent) & {
    as: ElementType;
};

export function RowRegular(props: TRowRegular) {
    const { isWidget, as } = props;
    const isEnabled = (isWidget ? props.widget?.options?.enabled : props.enabled) ?? true;
    const title = isWidget ? props.widget?.data?.title : props.title;
    const subtitle = isWidget ? props.widget?.data?.subtitle : props.subtitle;
    const metaText = isWidget ? props.widget?.data?.metaText : props.metaText;
    const metaVariant = isWidget ? props.widget?.options?.metaVariant : props.metaVariant;
    const hook = isWidget ? props.hooks.useList(props.widget as TWidget<TListData, TRowOption>) : undefined;

    const iconName = isWidget ? props.widget?.options?.iconName : props.iconName;
    const flagIcon = isWidget ? props.widget?.options?.flagIcon : props.flagIcon;
    const avatar = isWidget ? props.widget?.options?.avatar : props.avatar;
    const getTextColor = !isEnabled ? 'grey_40_lc' : 'grey_72_lc';

    return (
        <CommonRowRegular
            as={as}
            disabled={!isEnabled}
            data-test={isWidget ? props.widget?.uid : ''}
            onClick={isWidget ? hook?.handleClick : props.handleClick}
        >
            {iconName && (
                <Visual
                    isWidget={false}
                    iconName={iconName}
                    rightMargin
                    iconHierarchy={isWidget ? props.widget?.options?.iconHierarchy : props.iconHierarchy}
                    iconVariant={isWidget ? props.widget?.options?.iconVariant : props.iconVariant}
                    size={'small'}
                    isEnabled={!!isEnabled}
                />
            )}
            {avatar && <Visual isWidget={false} rightMargin avatar={avatar} size={'small'} isEnabled={!!isEnabled} />}
            {flagIcon && <Visual isWidget={false} rightMargin flagIcon={flagIcon} size={'small'} isEnabled={!!isEnabled} />}
            <RowContent>
                {title ? <HighlightedTitle dangerouslySetInnerHTML={{ __html: title }} disabled={!isEnabled} /> : ''}
                {subtitle && <TextCommon content={subtitle} options={{ variant: 'secondary', textColor: getTextColor, margin: { top: 4 } }} />}
            </RowContent>
            {metaText && <Tag metaText={metaText} metaVariant={metaVariant} disabled={!isEnabled} />}
        </CommonRowRegular>
    );
}
