import React from 'react';
import { StoriesEnum, TGroupWidgetProps, TCommonData } from '@core/types';
import { Stories } from './src/Stories';

const slides = [
    {
        title: '1',
        subTitle: ' SLIDE',
    },
    {
        title: '2',
        subTitle: ' SLIDE',
    },
    {
        title: '3',
        subTitle: ' SLIDE',
    },
];
const getSteps = () => {
    return slides.map((slide) => {
        return (
            <div key={slide.title} style={{ padding: '50px 20px' }}>
                {slide.title}
                {slide.subTitle}
            </div>
        );
    });
};

const TypeStories: React.FC<TGroupWidgetProps<TCommonData>> = ({ widgetId, wid, hooks }) => {
    const { widget } = hooks.useGetWidget(wid);
    switch (widgetId) {
        case StoriesEnum.STORIES:
            return <Stories widget={widget} steps={getSteps()} />;
        default:
            return false;
    }
};

export default TypeStories;
