import { IJson, IJsonScenario, IRootState, TInitWidgetState, TPage, TWAction, ValidationErrors } from '@core/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchJson } from '../../services/apiService';
import { initWidgetState } from '../../services/widgets';

type IPayloadScenario = {
    scenarioId: string;
    currentPageId: string;
    pages: TPage[];
} & TInitWidgetState;

const getPayload = (scenario: IJsonScenario): IPayloadScenario => {
    const { mutableWidgetsMap, bottomSheetMap, submitWidget } = initWidgetState(scenario.pages) as TInitWidgetState;
    return {
        pages: scenario?.pages,
        scenarioId: scenario?.scenarioId,
        currentPageId: scenario?.pages?.[0]?.id,
        bottomSheetMap,
        mutableWidgetsMap,
        submitWidget,
    };
};

export const fetchScenario = createAsyncThunk<
    IPayloadScenario | undefined,
    {
        action: TWAction | undefined;
    },
    {
        state: IRootState;
        rejectValue: ValidationErrors;
    }
>('fetchScenario', async ({ action }, thunkAPI) => {
    try {
        const { scenario } = thunkAPI.getState();
        const json = await fetchJson(action, scenario.currentPageId, scenario.mutableWidgetsMap);
        if (json?.scenario) {
            return getPayload(json.scenario);
        }
        return thunkAPI.rejectWithValue({
            errorMessage: 'Response do not have any scenario!',
        });
    } catch (e) {
        console.error(e);
        return thunkAPI.rejectWithValue({
            errorMessage: 'Response do not have any scenario!',
        });
    }
});

export const updateScenario = createAsyncThunk<
    IPayloadScenario | undefined,
    IJson | undefined,
    {
        state: IRootState;
        rejectValue: ValidationErrors;
    }
>('updateScenario', async (json, thunkAPI) => {
    if (json?.scenario) {
        return getPayload(json.scenario);
    }
    return thunkAPI.rejectWithValue({
        errorMessage: 'Response do not have any scenario!',
    });
});
