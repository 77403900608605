import { ButtonCircle, ButtonRow } from '@consumer/type-button';
import { numberFormat, SplitText } from '@core/utils';
import React from 'react';
import { FeeBlock } from './FeeBlock';
import { PopupComponent } from '@consumer/ui';
import { TSetFunc } from '@core/types';
import PricingTab from '../pricing-tab/PricingTab';

type TFeePanelProps = {
    setPopup?: TSetFunc;
    sum?: string;
    currency: string;
    iconName?: string;
    label: string;
    text: string;
    bottomSheetText: string;
    bottomSheetButton: string;
};

const Fee = React.memo(({ sum, currency, iconName, label, text, setPopup, bottomSheetText, bottomSheetButton }: TFeePanelProps) => {
    const handleClick = () => {
        setPopup?.(
            <PopupComponent
                header={
                    <>
                        <p>
                            <SplitText text={bottomSheetText} />
                        </p>
                        <PricingTab text={'Fast & Easy'} fee={'No fee'} marginTop={32} marginBottom={16} />
                        <PricingTab text={'Conventional'} fee={'No fee'} marginBottom={16} />
                        <PricingTab text={'All Demands'} fee={'1.50 EUR Extra'} marginBottom={32} />
                    </>
                }
                externalContent={<ButtonRow as="button" isWidget={false} label={bottomSheetButton} iconName={'ic_check'} handleClick={() => setPopup!(null)} />}
            />,
        );
    };
    return (
        <FeeBlock
            icon={iconName ? <ButtonCircle isWidget={false} size={'small'} iconName={iconName} handleClick={handleClick} /> : null}
            title={label ?? ''}
            text={'' + text}
            rate={`${numberFormat(sum)} ${currency}`}
        />
    );
});
export { Fee };
