import React, { useCallback, useMemo, useRef, useState } from 'react';
import { debounce, numberFormat } from '@core/utils';
import { CalculatorButton } from './CalculatorButton';
import { Fee } from './fee/Fee';
import { FeeButton } from './fee/FeeButton';
import { CalculatorInput } from './input/CalculatorInput';
import { SubComponent } from './input/SubComponent';
import { FeeBlock } from './fee/FeeBlock';
import { ActionActionTypeEnum, TButtonData, TCalculatorData, TCalculatorOption, TWidgetProps, TWidget, TCommonDataObj } from '@core/types';
import { CalculatorAmountContainer, CalculatorFrame, CalculatorFrameBottom, CalculatorFrameTop } from './styles';
import { toFixedSum } from './utils/format/format';
import { getDataValueFromWidget, getListsCurrency, getCurrency, getErrors, InputEnum, CurrencyDropdownEnum, TErrorsResult } from './utils/widgets';
import { useFontSize } from './utils/fontSizeService';

const Calculator = ({ widget, hooks }: TWidgetProps<TCalculatorData, TCalculatorOption>) => {
    const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const { fetchDataWidget, fetchScenario } = hooks.useFetchData(widget);
    const { updateWidgetValue } = hooks.useUpdateWidgetValue();
    const { setPopup } = hooks.useApp();
    const { updateWidget, mutableWidget } = hooks.useUpdateWidget(widget.wid);
    const { handleClick } = hooks.useButton(widget as TWidget<TButtonData>);
    const wid = mutableWidget?.wid;
    const options = mutableWidget?.options ?? {};
    const fee = mutableWidget?.data?.fee ?? {};
    const rate = mutableWidget?.data?.rate ?? {};
    const { fromInputValue, toInputValue, fromCurrencyValue, toCurrencyValue } = getDataValueFromWidget(mutableWidget?.data?.value as TCommonDataObj);
    const { fromCurrencyList, toCurrencyList } = useMemo(() => getListsCurrency(mutableWidget?.data.listCurrency), [mutableWidget?.data.listCurrency]);
    const errors = useMemo(() => getErrors(mutableWidget?.data?.errors ?? []), [mutableWidget?.data?.errors]);
    const fromCurrency = useMemo(() => getCurrency(fromCurrencyValue, mutableWidget?.data.listCurrency), [fromCurrencyValue, mutableWidget?.data.listCurrency]);
    const toCurrency = useMemo(() => getCurrency(toCurrencyValue, mutableWidget?.data.listCurrency), [toCurrencyValue, mutableWidget?.data.listCurrency]);
    const { fontSize, updateFontSize } = useFontSize(fromInputValue, toInputValue);
    const waitFor = useMemo(
        () => mutableWidget?.actions?.find((a) => a?.actionType === ActionActionTypeEnum[a?.actionType])?.delay || 500,
        [mutableWidget?.actions],
    );
    const debounceCalcCommission = debounce(timeoutRef, onServerCalculation, waitFor);
    const [isFrom, setIsFrom] = useState(false);
    const [currentErrors, setCurrentErrors] = useState<TErrorsResult>(errors);
    const [isLoading, setLoading] = useState(false);
    const isEnabled = Boolean(
        +fromInputValue &&
            +toInputValue &&
            !fromInputValue.endsWith('.') &&
            !toInputValue.endsWith('.') &&
            !currentErrors.fromInput?.shortText &&
            !currentErrors.toInput?.shortText,
    );

    async function onServerCalculation(value: string, name: string, isFrom: boolean) {
        const action = widget?.actions?.find((a) => a?.actionType === ActionActionTypeEnum[a?.actionType]);
        const isValueEndsDot = value.endsWith('.');
        if (action && value !== '0' && !isValueEndsDot) {
            setLoading(true);
            const result = await fetchDataWidget(action, value, name, isFrom);
            setLoading(false);
            if (result?.data) {
                result.wid = wid;
                updateWidget(result, wid);
                if (result.data.value && typeof result.data.value !== 'string') {
                    updateFontSize(result.data.value.fromInput as string, result.data.value.toInput as string);
                }
                setCurrentErrors(getErrors(result?.data?.errors ?? []));
            }
        }
    }

    const handleUpdateValue = useCallback(
        (clearValue: string, name: string, isFromFlag: boolean) => {
            setIsFrom(isFromFlag);
            if (mutableWidget?.data?.value && typeof mutableWidget.data.value !== 'string') {
                const value: TCommonDataObj = {
                    ...mutableWidget.data.value,
                    [name]: clearValue,
                };
                updateWidgetValue(value, wid);
            }
            if (errors.fromInput?.shortText || errors.toInput?.shortText) {
                setCurrentErrors((prevState) => {
                    return {
                        ...prevState,
                        [name]: {},
                    };
                });
            }
            updateFontSize(clearValue, isFromFlag ? fromInputValue : toInputValue);
            debounceCalcCommission(clearValue, name, isFromFlag);
        },
        [
            errors.fromInput?.shortText,
            errors.toInput?.shortText,
            debounceCalcCommission,
            fromInputValue,
            mutableWidget.data.value,
            toInputValue,
            updateFontSize,
            updateWidgetValue,
            wid,
        ],
    );

    const rateText = `${numberFormat(rate.from)} ${fromCurrencyValue ?? ''} = ${numberFormat(rate?.to, 4)} ${toCurrencyValue ?? ''}`;

    return (
        <CalculatorFrame data-test={widget.uid}>
            <CalculatorFrameTop>
                <CalculatorAmountContainer>
                    <CalculatorInput
                        isFrom={true}
                        name={InputEnum.FROM_INPUT}
                        value={fromInputValue}
                        label={options.fromInputLabel}
                        fontSize={fontSize}
                        isLoading={isLoading}
                        error={currentErrors.fromInput}
                        handleUpdateValue={handleUpdateValue}
                        subComponent={
                            <SubComponent
                                isFrom={isFrom}
                                inputName={InputEnum.FROM_INPUT}
                                dropdownName={CurrencyDropdownEnum.FROM_CURRENCY}
                                value={fromCurrencyValue}
                                flagIcon={fromCurrency.currency?.icon ?? ''}
                                index={fromCurrency.currencyIndex}
                                dropDownHeader={options.listFromCurrencyText}
                                dropDownButtonLabel={options.listFromCurrencyButton}
                                items={fromCurrencyList}
                                error={currentErrors.fromInput}
                                setPopup={setPopup}
                                fetchScenario={fetchScenario}
                                onServerCalculation={onServerCalculation}
                                handleUpdateValue={handleUpdateValue}
                            />
                        }
                    />
                    <CalculatorInput
                        isFrom={false}
                        name={InputEnum.TO_INPUT}
                        value={toInputValue}
                        label={options.toInputLabel}
                        fontSize={fontSize}
                        isLoading={isLoading}
                        error={currentErrors.toInput}
                        handleUpdateValue={handleUpdateValue}
                        subComponent={
                            <SubComponent
                                isFrom={isFrom}
                                inputName={InputEnum.TO_INPUT}
                                dropdownName={CurrencyDropdownEnum.TO_CURRENCY}
                                value={toCurrencyValue}
                                flagIcon={toCurrency.currency?.icon ?? ''}
                                index={toCurrency.currencyIndex}
                                dropDownHeader={options.listToCurrencyText}
                                dropDownButtonLabel={options.listToCurrencyButton}
                                items={toCurrencyList}
                                error={currentErrors.toInput}
                                setPopup={setPopup}
                                fetchScenario={fetchScenario}
                                onServerCalculation={onServerCalculation}
                                handleUpdateValue={handleUpdateValue}
                            />
                        }
                    />
                </CalculatorAmountContainer>
            </CalculatorFrameTop>
            <CalculatorFrameBottom>
                <Fee
                    setPopup={setPopup}
                    bottomSheetButton={options.feeBottomSheetButton}
                    bottomSheetText={fee.longText}
                    sum={toFixedSum(fee.sum)}
                    currency={fee.currency}
                    iconName={fee.iconName}
                    label={options.feeLabel}
                    text={options.feeText ?? ''}
                />
                <FeeBlock title={options.rateLabel} rate={rateText} />
                <FeeButton content={'Add a code'} />
            </CalculatorFrameBottom>
            <CalculatorButton handleClick={handleClick} label={options.buttonLabelMain} isEnabled={isEnabled} />
        </CalculatorFrame>
    );
};
export { Calculator };
