import React from 'react';

import { Container, Inner } from './styles';

export type TNavBarProps = {
    children: React.ReactNode;
    isSearchBar?: boolean;
    align?: 'left' | 'right' | 'space-between' | 'center';
    insideStories?: boolean;
};
export const NavBar = (props: TNavBarProps) => {
    return (
        <Container>
            <Inner $align={props.align} $isSearchBar={props.isSearchBar} $insideStories={props.insideStories}>
                {props.children}
            </Inner>
        </Container>
    );
};
