import { numberFormat } from '@core/utils';

const getPart = (value: string, type: string) => {
    const defaultParts = numberFormat(value, 2, true);
    if (typeof defaultParts !== 'string') {
        const part = defaultParts.find((part) => part.type === type);
        if (part) {
            return part.value;
        }
        return '';
    }
};
const DEFAULT_VALUE = '1236.36';
export const getFormattedValue = (amount: string): string => {
    try {
        const parts = numberFormat(amount, 2, true);
        if (typeof parts !== 'string') {
            if (parts[0].type === 'nan') return amount;
            const formattedValue = parts.reduce((string, part) => string + part.value, '');
            const decimal = getPart(amount, 'decimal');
            const dotIndexAmount = amount?.indexOf('.');
            const hasDot = dotIndexAmount && dotIndexAmount !== -1;
            const zeroIndexFormatted = amount?.search(/\.0+/);
            const defaultDecimal = getPart(DEFAULT_VALUE, 'decimal');
            if (hasDot && decimal) {
                return parts
                    .map(({ type, value }) => {
                        switch (type) {
                            case 'fraction':
                                return `${value.slice(0, 2)}`;
                            default:
                                return value;
                        }
                    })
                    .reduce((string, part) => string + part, '');
            } else if (hasDot && zeroIndexFormatted && zeroIndexFormatted !== -1) {
                const startIndex = zeroIndexFormatted + 1;
                return `${formattedValue}${defaultDecimal}${amount?.slice(startIndex, startIndex + 2)}`;
            } else if (hasDot) {
                return `${formattedValue}${defaultDecimal}`;
            }
            return formattedValue;
        }
        return amount;
    } catch (e) {
        if (amount) {
            return amount;
        }
        return '';
    }
};

export const getClearValue = (value: string) => {
    const initialValue = value;
    try {
        let clearValue;
        const defaultDecimal = getPart(DEFAULT_VALUE, 'decimal');
        const splitRegex = new RegExp(`[${defaultDecimal}.](?=[0-9]{0,2}.?$)`);
        const digitRegex = /\D/g;
        const valueArr = initialValue.split(splitRegex);
        const integerValue = valueArr?.[0].replace(digitRegex, '').replace(/^0/, '');
        if (valueArr?.length > 1) {
            const decimalValue = valueArr[1]?.replace(digitRegex, '') ?? '';
            clearValue = `${integerValue}.${decimalValue.slice(0, 2)}`;
        } else {
            clearValue = integerValue;
        }
        if (initialValue?.length === 0) {
            clearValue = '0';
        }
        if (clearValue) {
            return clearValue;
        }
        return initialValue;
    } catch (e) {
        return initialValue;
    }
};
export const toFixedSum = (sum?: string) => {
    if (sum) {
        return Number(sum).toFixed(2);
    }
    return '';
};
