import { ic_chevron_down as ChevronDownIcon } from '@consumer/assets';
import { ListSelect } from '@consumer/type-list';
import { FlagIcon, InputWrapper, Modal, TextCommon } from '@consumer/ui';
import { RowEnum, TButtonData, THooks, TInputOption, TInputPhoneData, TItem, TListData, TUseAppHook, TUseListHook, TWidget, TWidgetProps } from '@core/types';
import { ConditionalRender } from '@core/utils';
import React from 'react';
import PhoneInput from 'react-phone-input-2';
import styled, { useTheme } from 'styled-components';
import { PHONE_CUSTOM_MASK } from './utils/masks';

const FlagIconDropdown = styled.div`
    display: flex;
    padding-right: 8px;
    margin-right: 16px;
    border-right: 1px solid ${(props) => props.theme.grey_16_lc};
    cursor: pointer;
`;
const IconContainer = styled.div`
    margin-left: 4px;
`;

const InputPhone = ({ widget, hooks }: TWidgetProps<TInputPhoneData, TInputOption>) => {
    const { data, options, uid } = widget;
    const theme = useTheme();
    const { value, isError, errorMessage, handleFocus } = hooks.useInput(widget);
    const { iso2, handleChangeInputPhone } = hooks.useInputPhone(widget);
    const { setModal, setBottomSheet } = hooks.useApp();
    const useFunction = hooks.useFunction(widget);
    const hasItems = Array.isArray(widget.data.items) && widget.data.items.length > 0;
    const useApp = (): TUseAppHook => {
        return { setModal, setBottomSheet };
    };

    const useList = (widget: TWidget<TListData, TInputOption>): TUseListHook => {
        return {
            handleClick: (_wid: string, item: TItem) => {
                if (hasItems && item) {
                    setModal(null);
                    handleChangeInputPhone?.(item.metaText ?? '', { countryCode: item.icon ?? '' }, undefined, item.metaText);
                }
            },
            isVisible: true,
        };
    };

    const modalContent = (
        <Modal isCanCloseModal={true} iconName="ic_arrow_back" onHandleClick={() => setModal?.(null)} hooks={hooks as unknown as THooks<TButtonData>}>
            <ConditionalRender condition={!!widget.data.headerForSelectPhonePrefix}>
                <TextCommon
                    options={{ variant: 'header', margin: { top: 16, left: 24, right: 24, bottom: 32 } }}
                    content={widget.data.headerForSelectPhonePrefix}
                />
            </ConditionalRender>
            <ListSelect rowId={RowEnum.ROW_REGULAR} widget={widget} hooks={{ ...hooks, useApp, useList }} isOpenInModal={true} />
        </Modal>
    );

    return (
        <InputWrapper
            useFunction={useFunction}
            labelText={data?.label}
            isError={isError}
            errorMessage={errorMessage}
            isDisabled={options?.isDisabled}
            isFocused
            isFilled
            marginTop={options?.margin?.top}
            marginRight={options?.margin?.right}
            marginBottom={options?.margin?.bottom}
            marginLeft={options?.margin?.left}
            leftInputComponent={
                <FlagIconDropdown
                    onClick={() => {
                        if (hasItems) {
                            setModal(modalContent);
                        }
                    }}
                >
                    <FlagIcon name={iso2} />
                    <ConditionalRender condition={hasItems}>
                        <IconContainer>
                            <ChevronDownIcon fill={theme.black} width={24} height={24} />
                        </IconContainer>
                    </ConditionalRender>
                </FlagIconDropdown>
            }
        >
            <PhoneInput
                inputProps={{ autoFocus: options?.onFocus || false, autoComplete: 'tel', 'data-test': uid }}
                value={value}
                specialLabel={''}
                placeholder={''}
                disableDropdown={true}
                defaultMask={'... ... ... ... ..'}
                masks={PHONE_CUSTOM_MASK}
                onFocus={handleFocus}
                onChange={handleChangeInputPhone}
            />
        </InputWrapper>
    );
};
export { InputPhone };
