import { useSwipeable } from 'react-swipeable';
import React, { SyntheticEvent, useEffect, useRef } from 'react';

interface AnimationEvent<T = Element> extends SyntheticEvent<T> {
    animationName: string;
}
const usePopup = <TData>(data: TData, setAnimated: React.Dispatch<React.SetStateAction<boolean>>, modal: React.ReactNode | null) => {
    const overlayRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const keyPressHandler = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                e.preventDefault();
                setAnimated(true);
            }
        };
        if (data) {
            document.body.style.overflow = 'hidden';
            document.addEventListener('keydown', keyPressHandler);
        }

        return () => {
            if (data) {
                document.body.style.overflow = modal ? 'hidden' : 'visible';
                document.removeEventListener('keydown', keyPressHandler);
            }
        };
    }, [setAnimated, data, modal]);

    const handlers = useSwipeable({
        onSwipedDown: () => setAnimated(true),
        delta: 20,
        trackTouch: true,
    });

    const overlayClick = (e: React.MouseEvent) => {
        if (e.target instanceof HTMLElement && overlayRef.current && !overlayRef.current.contains(e.target)) {
            e.preventDefault();
            setAnimated(true);
        }
    };

    return {
        handlers,
        overlayClick,
        overlayRef,
    };
};
export { usePopup, AnimationEvent };
