import { ButtonEnum, TButtonData, TButtonOption, TGroupWidgetProps } from '@core/types';
import React from 'react';
import { ButtonCircle } from './src/ButtonCircle';
import { ButtonPill } from './src/ButtonPill';
import { ButtonRow } from './src/ButtonRow';
import { TimerButton } from './src/TimerButton';

const TypeButton = ({ widgetId, wid, hooks }: TGroupWidgetProps<TButtonData, TButtonOption>) => {
    const { widget } = hooks.useGetWidget(wid);
    switch (widgetId) {
        case ButtonEnum.BUTTON_PILL:
            return <ButtonPill as="button" isWidget={true} widget={widget} hooks={hooks} />;
        case ButtonEnum.BUTTON_CIRCLE:
            return <ButtonCircle as="button" isWidget={true} widget={widget} hooks={hooks} />;
        case ButtonEnum.BUTTON_ROW:
            return <ButtonRow as="button" isWidget={true} widget={widget} hooks={hooks} />;
        case ButtonEnum.TIMER_BUTTON:
            return <TimerButton widget={widget} hooks={hooks} />;
        default:
            return false;
    }
};

export { ButtonPill, ButtonCircle, ButtonRow, TimerButton };

export default TypeButton;
