import { DropdownEnum, TDropdownData, TDropdownOption, TGroupWidgetProps } from '@core/types';
import React from 'react';
import { InputDropdown } from './src/InputDropdown';

const TypeDropdown = ({ widgetId, wid, hooks }: TGroupWidgetProps<TDropdownData, TDropdownOption>) => {
    const { widget } = hooks.useGetWidget(wid);
    switch (widgetId) {
        case DropdownEnum.DROPDOWN:
            return <InputDropdown widget={widget} hooks={hooks} />;
        default:
            return false;
    }
};

export default TypeDropdown;
