import { EventTypeEnum, FunctionActionTypeEnum, TButtonData, TButtonOption, THooks, TWidget } from '@core/types';
import { eventBus } from '@core/utils';
import React, { ElementType, MouseEventHandler, useEffect } from 'react';

import { CommonButton } from './styles/ButtonPill';

type TButtonPillProps<TD, TO> = (
    | {
          isWidget: true;
          widget: TWidget<TD, TO>;
          hooks?: THooks<TD>;
      }
    | {
          isWidget: false;
          handleClick: MouseEventHandler;
          label: string;
          isEnabled: boolean;
          dynamicShadow?: boolean;
          position: 'floating' | 'inline';
          size?: 'large' | 'medium' | 'small';
          viewBackground?: 'plain' | 'gradient';
          fillContainer?: boolean;
      }
) & {
    as: ElementType;
    hierarchy?: 'primary' | 'secondary' | 'tertiary';
};

export const ButtonPill = (props: TButtonPillProps<TButtonData, TButtonOption>) => {
    const { isWidget, as } = props;
    const useButton = isWidget ? props.hooks?.useButton(props.widget) : undefined;
    const useFunction = isWidget ? props.hooks?.useFunction(props.widget) : undefined;
    const useDetectDocumentScroll = isWidget ? props.hooks?.useDetectDocumentScroll(isWidget ? props.widget.uid : '') : undefined;
    const hierarchy = isWidget ? props.widget?.options?.hierarchy : '';
    const isEnabled = useFunction?.getFunctionResult(FunctionActionTypeEnum.ENABLED);

    const isDisabled = () => {
        if (isWidget) {
            return useFunction?.hasFunctionForAction(FunctionActionTypeEnum.ENABLED) ? !isEnabled : !(props.widget?.options?.enabled ?? true);
        } else {
            return props.isEnabled === false || false;
        }
    };

    useEffect(() => {
        if (isWidget && props.widget.data.default) {
            eventBus.emitGeneral(EventTypeEnum.IS_DEFAULT_BUTTON_ENABLED, !isDisabled());
        }
    }, [isEnabled]);

    const getPosition = () => {
        const dynamicShadow = isWidget ? props.widget?.options?.dynamicShadow : props.dynamicShadow;
        const dynamicShadowDefault = dynamicShadow ?? true;
        if (dynamicShadowDefault && useDetectDocumentScroll?.documentScrolled && !isDisabled()) {
            return 'floating';
        } else {
            return isWidget ? props.widget?.options?.position : props.position;
        }
    };
    return (
        <CommonButton
            as={as}
            data-test={isWidget ? props.widget?.uid : ''}
            $disabled={isDisabled()}
            $hierarchy={props.hierarchy ?? hierarchy}
            $size={isWidget ? props.widget?.options?.size : props.size}
            $position={getPosition()}
            $viewBackground={isWidget ? props.widget?.options?.viewBackground : props.viewBackground}
            $fillContainer={isWidget ? props.widget?.options?.fillContainer : props.fillContainer}
            $marginBottom={isWidget ? props.widget?.options?.margin?.bottom : ''}
            $marginTop={isWidget ? props.widget?.options?.margin?.top : ''}
            $marginLeft={isWidget ? props.widget?.options?.margin?.left : ''}
            $marginRight={isWidget ? props.widget?.options?.margin?.right : ''}
            onClick={isWidget ? useButton?.handleClick : props.handleClick}
        >
            {isWidget ? props.widget?.data?.label : props.label}
        </CommonButton>
    );
};
