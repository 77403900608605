import React, { Dispatch, SetStateAction, useState, useMemo } from 'react';
import { Portal } from './Portal';
import BottomSheet from '../Popup/BottomSheet';
import Popup from '../Popup/Popup';
import { createRequiredContext } from '../../services/contextService';
import { PageWrapper } from './PageWrapper';

type TContext = Dispatch<SetStateAction<React.ReactNode>>;
export const [usePortal, PortalProvider] = createRequiredContext<{
    setModal: TContext;
    setPopup: TContext;
    setBottomSheet: Dispatch<SetStateAction<string | undefined>>;
}>();

const PortalWrapper = ({ children }: { children: React.ReactNode }) => {
    const [modal, setModal] = useState<React.ReactNode>(undefined);
    const [bottomSheet, setBottomSheet] = useState<string | undefined>();
    const [popup, setPopup] = useState<React.ReactNode>(undefined);

    const contextValue = useMemo(
        () => ({
            setModal,
            setPopup,
            setBottomSheet,
        }),
        [],
    );
    return (
        <PageWrapper>
            <PortalProvider value={contextValue}>
                {children}
                <BottomSheet widgetId={bottomSheet} modal={modal} />
                <Popup content={popup} modal={modal} />
            </PortalProvider>
            {modal && <Portal>{modal}</Portal>}
        </PageWrapper>
    );
};
export default PortalWrapper;
