import 'styled-components';

export type ThemeType = typeof light;

declare module 'styled-components' {
    interface DefaultTheme extends ThemeType {
        mode: ThemeEnum;
    }
}

const basePalette = {
    white_base: '#FFFFFF',
    white_0_tr_base: 'rgba(255, 255, 255, 0)',
    white_8_tr_base: 'rgba(255, 255, 255, 0.08)',
    white_16_tr_base: 'rgba(255, 255, 255, 0.16)',
    white_40_tr_base: 'rgba(255, 255, 255, 0.40)',
    white_72_tr_base: 'rgba(255, 255, 255, 0.72)',
    black_48_tr_base: 'rgba(0, 0, 0, 0.48)',
};
export enum ThemeEnum {
    light = 'light',
    dark = 'dark',
}
// THEME PALETTE Light
const light = {
    ...basePalette,
    mode: ThemeEnum.light,
    white: '#FFFFFF',
    black: '#141414',
    white_72_tr: 'rgba(255, 255, 255, 0.72)',
    grey_8_lc: '#F3F2F5',
    grey_16_lc: '#E4E4E5',
    grey_40_lc: '#9F9DA3',
    grey_72_lc: '#77767A',
    grey_16_tr: 'rgba(100, 98, 104, 0.16)',
    purple_8_lc: '#F4EFFF',
    purple_16_lc: '#E9DEFF',
    purple_24_lc: '#DECEFF',
    purple_40_lc: '#C8ADFF',
    purple_72_lc: '#9C6CFF',
    purple_100: '#7633FF',
    purple_72_hc: '#5E29CC',
    purple_8_tr: 'rgba(118, 51, 255, 0.08)',
    purple_16_tr: 'rgba(118, 51, 255, 0.16)',
    purple_24_tr: 'rgba(118, 51, 255, 0.24)',
    purple_40_tr: 'rgba(118, 51, 255, 0.40)',
    purple_72_tr: 'rgba(118, 51, 255, 0.72)',
    pink_8_lc: '#FFF0F4',
    pink_16_lc: '#FFE2E9',
    pink_40_lc: '#FFB5C9',
    pink_72_lc: '#FF7B9E',
    pink_100: '#FF4778',
    pink_8_tr: 'rgba(255, 71, 120, 0.08)',
    pink_16_tr: 'rgba(255, 71, 120, 0.16)',
    pink_40_tr: 'rgba(255, 71, 120, 0.40)',
    pink_72_tr: 'rgba(255, 71, 120, 0.72)',
    shadow_purple: '0px 8px 8px 0px rgba(118, 51, 255, 0.16)',
    shadow_grey: '0px 8px 8px 0px rgba(20, 20, 20, 0.04), 0px 0px 8px 0px rgba(20, 20, 20, 0.04), 0px 0px 1px 0px rgba(20, 20, 20, 0.12)',
};

// THEME PALETTE Dark
const dark = {
    ...basePalette,
    mode: ThemeEnum.dark,
    white: '#141414',
    black: '#FFFFFF',
    white_72_tr: 'rgba(0, 0, 0, 0.72)',
    grey_8_lc: '#1E1F1F',
    grey_16_lc: '#2A2C2C',
    grey_40_lc: '#373939',
    grey_72_lc: '#888E90',
    grey_16_tr: 'rgba(153, 168, 168, 0.16)',
    purple_8_lc: '#1C1826',
    purple_16_lc: '#251B37',
    purple_24_lc: '#2D1F49',
    purple_40_lc: '#3E266C',
    purple_72_lc: '#5F35B2',
    purple_100: '#7C42F0',
    purple_72_hc: '#A177F4',
    purple_8_tr: 'rgba(124, 66, 240, 0.08)',
    purple_16_tr: 'rgba(124, 66, 240, 0.16)',
    purple_24_tr: 'rgba(124, 66, 240, 0.24)',
    purple_40_tr: 'rgba(124, 66, 240, 0.40)',
    purple_72_tr: 'rgba(124, 66, 240, 0.72)',
    pink_8_lc: '#26191D',
    pink_16_lc: '#371E25',
    pink_40_lc: '#6C2E3F',
    pink_72_lc: '#B34361',
    pink_100: '#F1557F',
    pink_8_tr: 'rgba(241, 85, 127, 0.08)',
    pink_16_tr: 'rgba(241, 85, 127, 0.16)',
    pink_40_tr: 'rgba(241, 85, 127, 0.40)',
    pink_72_tr: 'rgba(241, 85, 127, 0.72)',
    shadow_purple: '0px 8px 8px 0px rgba(9, 7, 13, 0.32)',
    shadow_grey: '0px 8px 8px 0px rgba(0, 0, 0, 0.32), 0px 0px 8px 0px rgba(0, 0, 0, 0.32)',
};
export default {
    light,
    dark,
};
