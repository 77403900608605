import styled from 'styled-components';

import { media, zIndex } from '@core/theme';
import { WithPrefix } from '@core/types';

interface INavBarProps {
    isSearchBar?: boolean;
    align?: 'left' | 'right' | 'space-between' | 'center';
    insideStories?: boolean;
}

type StyledComponentProps = WithPrefix<INavBarProps>;

const getJustifyContent = (props: StyledComponentProps) => {
    if (props.$isSearchBar || props.$align === 'center') {
        return 'center';
    } else if (props.$align === 'space-between') {
        return 'space-between';
    } else {
        return props.$align === 'left' ? 'start' : 'flex-end';
    }
};
const getPadding = (props: StyledComponentProps) => {
    if (props.$isSearchBar) {
        return '0';
    } else if (props.$insideStories) {
        return '42px 16px 16px 16px';
    } else {
        return '32px 16px';
    }
};

export const Container = styled.div`
    background-color: ${(props) => props.theme.white};
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    height: 0;
    pointer-events: none;
    z-index: ${zIndex.navBar};
`;
export const Inner = styled.div<StyledComponentProps>`
    box-sizing: border-box;
    margin: 0 auto;
    padding: 16px;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    display: flex;
    justify-content: ${(props: StyledComponentProps) => getJustifyContent(props)};
    max-width: 528px;

    ${media.mobile481} {
        max-width: 504px;
    }
    ${media.tablet708} {
        padding: ${(props) => getPadding(props)};
        max-width: 100%;
    }
    ${media.tablet889} {
        max-width: 888px;
    }
`;
