import { AnyAction, AsyncThunk } from '@reduxjs/toolkit';

type GenericAsyncThunk = AsyncThunk<unknown, unknown, never>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
export function isPendingAction(action: AnyAction): action is PendingAction {
    return action.type.endsWith('/pending');
}
export function isRejectedAction(action: AnyAction): action is RejectedAction {
    return action.type.endsWith('/rejected');
}
