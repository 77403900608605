import { EventTypeEnum, FunctionActionTypeEnum, TCommonData, TEventPayload, TUseFunction, TWidget } from '@core/types';
import { eventBus } from '@core/utils';
import React, { useEffect, useRef, useState } from 'react';

type TFunctionParsed = {
    internalIds: string[];
    widgets: Record<string, TEventPayload['value']>;
    conditions: Array<TConditions>;
};
type TParameter = {
    internalId?: string;
    value: TEventPayload['value'];
};
export type TConditions = {
    firstParameter: TParameter;
    secondParameter: TParameter;
    conditionAction: ConditionActionEnum;
    joinConditionAction?: JoinConditionActionEnum;
};

export enum JoinConditionActionEnum {
    AND = '&&',
    OR = '||',
}

export enum ConditionActionEnum {
    EQUAL = '==',
    NOT_EQUAL = '!=',
    MORE_THAN = '>',
    MORE_THAN_OR_EQUAL = '>=',
    LESS_THAN = '<',
    LESS_THAN_OR_EQUAL = '<=',
}

/*
    @match0 - whole expression
    @match1 - validationId or number or boolean
    @match2 - string value
    @match3 - property name of widget with '.'
    @match4 - property name of widget
    @match5 - conditional operator (==, >, <, >=, <=)
    @match6 - validationId or number or boolean
    @match7 - string value
    @match8 - property name of widget with '.'
    @match9 - property name of widget
    @match10 - conditional operator between expressions (||, &&)
 */
const objectRegex = /(\w+|\d+|(.*?))(\.(\w+))?(={2}|<|\\>|>=|=<|!=){1,}(\w+|\d+|(.*?))(\.(\w+))?(\|{2}|&{2})?/g;
const parseToPrimitive = (str: string): boolean | number | string => {
    if (str === 'true' || str === 'false') {
        return str === 'true';
    } else if (!isNaN(+str)) {
        return Number(str);
    }
    return str;
};
export const parseToPrimitiveForTest = (str: string): boolean | number | string => parseToPrimitive(str);
const parseStringToFunctionObject = (constraint: string): TFunctionParsed => {
    const result: TFunctionParsed = { internalIds: [], widgets: {}, conditions: [] };
    try {
        const matches = constraint.matchAll(objectRegex);
        for (const match of matches) {
            const condition: TConditions = {
                firstParameter: { value: parseToPrimitive(match?.[2] ?? match?.[1]) },
                secondParameter: { value: parseToPrimitive(match?.[7] ?? match?.[6]) },
                conditionAction: match?.[5] as ConditionActionEnum,
                joinConditionAction: match?.[10] as JoinConditionActionEnum,
            };
            if (match?.[1] && match?.[4]) {
                result.internalIds.push(match[1]);
                result.widgets[match[1]] = match[4];
                condition.firstParameter.value = null;
                condition.firstParameter.internalId = match[1];
            }
            if (match?.[6] && match?.[9]) {
                result.internalIds.push(match[6]);
                result.widgets[match[6]] = match[9];
                condition.secondParameter.value = null;
                condition.secondParameter.internalId = match[6];
            }

            result.conditions.push(condition);
        }
    } catch (e) {
        console.error(e);
    }
    return result;
};
export const parseStringToFunctionObjectForTest = (constraint: string) => parseStringToFunctionObject(constraint);
const updateConditionValues = (conditions: Array<TConditions>, internalId: string, value: TEventPayload['value']) => {
    conditions.forEach((condition) => {
        if (condition.firstParameter.internalId === internalId) {
            condition.firstParameter.value = value;
        }
        if (condition.secondParameter.internalId === internalId) {
            condition.secondParameter.value = value;
        }
    });
};
export const updateConditionValuesForTest = (conditions: Array<TConditions>, internalId: string, value: TEventPayload['value']) =>
    updateConditionValues(conditions, internalId, value);
const executeCondition = (condition: TConditions) => {
    const a = condition.firstParameter.value;
    const b = condition.secondParameter.value;
    if (a && b && condition.conditionAction) {
        switch (condition.conditionAction) {
            case ConditionActionEnum.EQUAL:
                return a === b;
            case ConditionActionEnum.NOT_EQUAL:
                return a !== b;
            case ConditionActionEnum.LESS_THAN:
                return a < b;
            case ConditionActionEnum.LESS_THAN_OR_EQUAL:
                return a <= b;
            case ConditionActionEnum.MORE_THAN:
                return a > b;
            case ConditionActionEnum.MORE_THAN_OR_EQUAL:
                return a >= b;
        }
    }
    return false;
};
export const executeConditionForTest = (condition: TConditions) => executeCondition(condition);
const executeConditions = (conditions: Array<TConditions>) => {
    let result = false;
    if (Array.isArray(conditions) && conditions.length > 0) {
        let joinConditionAction = conditions[0].joinConditionAction;
        conditions.forEach((condition, index) => {
            const expressionResult = executeCondition(condition);
            if (index === 0) {
                result = expressionResult;
            } else {
                switch (joinConditionAction) {
                    case JoinConditionActionEnum.AND:
                        result = result && expressionResult;
                        return;
                    case JoinConditionActionEnum.OR:
                        result = result || expressionResult;
                        return;
                }
                joinConditionAction = condition.joinConditionAction;
            }
        });
    }
    return result;
};
export const executeConditionsForTest = (conditions: Array<TConditions>) => executeConditions(conditions);
export const useFunction = ({ functions }: TWidget<TCommonData>): TUseFunction => {
    const [functionResult, setFunctionResult] = useState<Record<string, boolean>>({});
    const refFunctions: React.MutableRefObject<Record<string, TFunctionParsed>> = useRef({});
    useEffect(() => {
        const functionsCopy = refFunctions.current;
        Object.keys(refFunctions.current).forEach((functionkey) => {
            const functionParsed = refFunctions.current[functionkey];
            Object.keys(functionParsed.widgets).forEach((internalId) => {
                const eventType = functionParsed.widgets[internalId] as EventTypeEnum;
                eventBus.subscribeOnSpecificWidget(eventType, internalId, (value) => {
                    updateConditionValues(functionParsed.conditions, internalId, value);
                    const res = executeConditions(functionParsed.conditions);
                    setFunctionResult((prevState) => {
                        return { ...prevState, [functionkey]: res };
                    });
                });
            });
        });
        return () => {
            Object.values(functionsCopy).forEach((functionParsed) => {
                Object.keys(functionParsed.widgets).forEach((internalId) => {
                    const eventType = functionParsed.widgets[internalId] as EventTypeEnum;
                    eventBus.unsubscribeSpecificWidget(eventType, internalId);
                });
            });
        };
    }, [functions]);

    const getFunctionResult = (actionType: FunctionActionTypeEnum) => {
        if (functions?.[actionType]) {
            if (!refFunctions.current[actionType]) {
                const fun = functions[actionType];
                refFunctions.current[actionType] = parseStringToFunctionObject(fun);
                const functionParsed = refFunctions.current[actionType];
                const res = executeConditions(functionParsed.conditions);
                setFunctionResult((prevState) => {
                    return { ...prevState, [actionType]: res };
                });
            }
        }
        return functionResult[actionType];
    };

    const hasFunctionForAction = (actionType: FunctionActionTypeEnum) => !!functions?.[actionType];

    return {
        getFunctionResult,
        hasFunctionForAction,
    };
};
