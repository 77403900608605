import { CheckboxEnum, TCheckboxOption, TGroupWidgetProps, TInputData } from '@core/types';
import React from 'react';
import { Checkbox } from './src/Checkbox';
import { Switcher } from './src/Switcher';

const TypeCheckbox = ({ widgetId, wid, hooks }: TGroupWidgetProps<TInputData, TCheckboxOption>) => {
    const { widget } = hooks.useGetWidget(wid);
    switch (widgetId) {
        case CheckboxEnum.SWITCHER:
            return <Switcher widget={widget} hooks={hooks} />;
        case CheckboxEnum.CHECKBOX:
            return <Checkbox widget={widget} hooks={hooks} />;
        default:
            return false;
    }
};

export default TypeCheckbox;
