import React from 'react';
import { PopupBoxContentWrapper, PopupBoxContent, PopupBoxHeader, PopupInternalContent, PopupExternalContent } from './styles';

type TPopupComponentProps = {
    header?: string | React.ReactNode;
    internalContent?: React.ReactNode;
    externalContent?: React.ReactNode;
    withoutSeparator?: boolean;
    textAlign?: 'start' | 'end' | 'center' | 'justify' | 'left' | 'right';
};

const PopupComponent = ({ header, withoutSeparator, internalContent, externalContent, textAlign }: TPopupComponentProps) => {
    return (
        <>
            <PopupBoxContentWrapper>
                <PopupBoxContent>
                    {header && <PopupBoxHeader $textAlign={textAlign}>{header}</PopupBoxHeader>}
                    {internalContent && <PopupInternalContent $withoutSeparator={withoutSeparator}>{internalContent}</PopupInternalContent>}
                </PopupBoxContent>
            </PopupBoxContentWrapper>
            {externalContent && <PopupExternalContent>{externalContent}</PopupExternalContent>}
        </>
    );
};

export { PopupComponent };
