import styled, { css } from 'styled-components';

export const marginHelperWithPrefix = css<{ $marginTop?: number; $marginLeft?: number; $marginRight?: number; $marginBottom?: number }>`
    margin-top: ${(props) => (props.$marginTop ? `${props.$marginTop}px` : 0)};
    margin-left: ${(props) => (props.$marginLeft ? `${props.$marginLeft}px` : 0)};
    margin-right: ${(props) => (props.$marginRight ? `${props.$marginRight}px` : 0)};
    margin-bottom: ${(props) => (props.$marginBottom ? `${props.$marginBottom}px` : 0)};
`;

type TIndent = '4px' | '8px' | '12px' | '16px' | '24px' | '32px';
type TSize = '16px' | '20px' | '24px' | '32px' | '48px' | '56px' | '64px' | '80px' | '96px' | '100%';

const defaultSize = '24px';
export const IconContainer = styled.span<{ rightIndent?: TIndent; leftIndent?: TIndent; size: TSize }>`
    display: inline-block;
    height: ${(props) => (props.size ? props.size : defaultSize)};
    margin-right: ${(props) => (props.rightIndent ? props.rightIndent : null)};
    margin-left: ${(props) => (props.leftIndent ? props.leftIndent : null)};
`;
