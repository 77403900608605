import { useCallback, useState } from 'react';
import { useMatchMedia } from '@core/utils';
import { breakpoints } from '@core/theme';
const FONT_SIZE_STEPS = {
    desktop: {
        4: '48px',
        5: '40px',
        6: '40px',
        7: '32px',
        8: '32px',
        9: '24px',
    },
    tablet: {
        4: '40px',
        5: '32px',
        6: '32px',
        7: '24px',
        8: '24px',
        9: '17px',
    },
};

function calcFontSize(from: string, to: string, isTablet: boolean) {
    const trunc = (value: string) => Math.trunc(+value ?? 0).toString();
    const valueFrom = trunc(from);
    const valueTo = trunc(to);
    const maxLength = Math.max(valueFrom.length, valueTo.length);
    const index = Math.max(4, Math.min(maxLength, 9));
    return isTablet
        ? FONT_SIZE_STEPS.tablet[index as keyof (typeof FONT_SIZE_STEPS)['tablet']]
        : FONT_SIZE_STEPS.desktop[index as keyof (typeof FONT_SIZE_STEPS)['desktop']];
}
export const useFontSize = (from: string, to: string) => {
    const isTablet = useMatchMedia(`(max-width: ${breakpoints.tablet708})`);
    const [fontSize, setFontSize] = useState(calcFontSize(from, to, isTablet));
    const updateFontSize = useCallback(
        (amountFrom: string, amountTo: string) => {
            setFontSize(calcFontSize(amountFrom, amountTo, isTablet));
        },
        [isTablet],
    );

    return { fontSize, updateFontSize };
};
