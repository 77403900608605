import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { FunctionActionTypeEnum, TCommonData, TLayout, TLayoutOption, TWidgetProps, WithPrefix } from '@core/types';
import { IFloatingLayoutProps } from '../interface/interface';
import { zIndex, media } from '@core/theme';

type StyledComponentProps = WithPrefix<IFloatingLayoutProps>;

const fadeOut = (isTop: boolean) => keyframes`
    0% {
        opacity: 1;
    }
    100% {
        transform: translateY(${isTop ? '-' : ''}100px);
        opacity: 0;
        display: none;
    }
`;
const fadeIn = (isTop: boolean) => keyframes`
    0% {
        opacity: 0;
        display: block;
        transform: translateY(${isTop ? '-' : ''}100px);
    }
    100% {
        bottom: 0;
        opacity: 1;
    }
`;

const FloatingBlock = styled.div<StyledComponentProps>`
    position: fixed;
    left: 0;
    top: ${(props) => (props.$alignment === 'top' ? '0' : null)};
    bottom: ${(props) => (props.$alignment === 'bottom' ? '0' : null)};
    width: 100%;
    box-sizing: border-box;
    margin-top: ${(props) => (props.$marginTop ? `${props.$marginTop}px` : 0)};
    margin-left: ${(props) => (props.$marginLeft ? `${props.$marginLeft}px` : 0)};
    margin-right: ${(props) => (props.$marginRight ? `${props.$marginRight}px` : 0)};
    margin-bottom: ${(props) => (props.$marginBottom ? `${props.$marginBottom}px` : 0)};
    z-index: ${(props) => (props.$alignment === 'top' ? zIndex.floatingLayoutTop : zIndex.floatingLayoutBottom)};
    animation-name: ${(props) => (props.$isFirstRender ? 'none' : props.$isHidden ? fadeOut(props.$alignment === 'top') : fadeIn(props.$alignment === 'top'))};
    animation-duration: 500ms;
    animation-fill-mode: forwards;
`;
const FloatingInner = styled.div`
    margin: 0 auto;

    ${media.mobile481} {
        max-width: 480px;
    }

    ${media.tablet708} {
        max-width: 100%;
    }

    ${media.tablet889} {
        max-width: 888px;
    }
`;

export const FloatingLayout: React.FC<TWidgetProps<TCommonData, TLayoutOption> & TLayout> = ({ widget, children, margin, hooks }) => {
    const firstRender = useRef(true);
    const options = widget?.options || {};
    const useFunction = hooks?.useFunction(widget);
    const isHidden = useFunction?.getFunctionResult(FunctionActionTypeEnum.HIDDEN);
    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
        }
    }, [isHidden]);
    return (
        <FloatingBlock
            $isHidden={isHidden}
            $isFirstRender={firstRender.current}
            data-test={widget?.uid}
            $alignment={options.alignment}
            $marginBottom={margin?.bottom}
            $marginTop={margin?.top}
            $marginLeft={margin?.left}
            $marginRight={margin?.right}
        >
            <FloatingInner>{children}</FloatingInner>
        </FloatingBlock>
    );
};
