import { CalculatorEnum, TCalculatorData, TCalculatorOption, TGroupWidgetProps } from '@core/types';
import React from 'react';
import { Calculator } from './src/Calculator';

const TypeCalculator = ({ widgetId, wid, hooks }: TGroupWidgetProps<TCalculatorData, TCalculatorOption>) => {
    const { widget } = hooks.useGetWidget(wid);
    switch (widgetId) {
        case CalculatorEnum.CALCULATOR:
            return <Calculator widget={widget} hooks={hooks} />;
        default:
            return false;
    }
};

export default TypeCalculator;
