import styled, { ExecutionContext } from 'styled-components';
import { Link } from 'react-router-dom';
import { fonts } from '@core/theme';
import { WithPrefix } from '@core/types';
import { marginHelperWithPrefix } from '@consumer/ui';

interface IButtonRowProps {
    hierarchy?: 'primary' | 'destructive';
    disabled?: boolean;
    size?: 'large' | 'small';
    alignment?: 'center' | 'left';
    hasIcon?: boolean;
    marginTop?: number;
    marginRight?: number;
    marginBottom?: number;
    marginLeft?: number;
}

type StyledComponentProps = ExecutionContext & WithPrefix<IButtonRowProps>;

const getColor = (props: StyledComponentProps): string => {
    switch (props.$hierarchy) {
        case 'destructive':
            return props.$disabled ? props.theme.pink_40_lc : props.theme.pink_100;
        default:
            return props.$disabled ? props.theme.purple_40_tr : props.theme.purple_100;
    }
};

const getPadding = (props: StyledComponentProps): string => {
    if (props.$hasIcon) {
        return props.$size === 'large' ? '16px 16px 16px 24px' : '16px 24px';
    } else {
        return props.$size === 'large' ? '24px 16px 24px 24px' : '16px 24px';
    }
};

export const CommonButtonRow = styled(Link)<StyledComponentProps>`
    font-family: ${fonts.regular};
    border: none;
    width: 100%;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    outline: none;
    justify-content: ${(props) => (props.$alignment === 'center' ? 'center' : 'start')};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    vertical-align: top;
    cursor: ${(props) => (props.$disabled ? 'default' : 'pointer')};
    pointer-events: auto;
    font-size: 17px;
    line-height: 24px;
    border-radius: 0;
    padding: ${(props) => getPadding(props)};
    background: ${(props) => props.theme.white_0_tr_base};
    color: ${(props) => getColor(props)};
    ${marginHelperWithPrefix};

    &:link,
    &:visited,
    &:focus {
        color: ${(props) => getColor(props)};
    }

    &:hover {
        background: ${(props) =>
            props.$disabled ? props.theme.white_0_tr_base : props.$hierarchy === 'destructive' ? props.theme.pink_16_tr : props.theme.purple_16_tr};
    }

    &:active,
    &:focus {
        background: ${(props) =>
            props.$disabled ? props.theme.white_0_tr_base : props.$hierarchy === 'destructive' ? props.theme.pink_8_tr : props.theme.purple_8_tr};
    }
`;
