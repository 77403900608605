const PHONE_CUSTOM_MASK = {
    ug: '(...) ...-...',
    kz: '(...) ...-..-......',
    gb: '.... ...-...',
    uz: '..-...-....',
    lv: '..-...-...',
    ua: '... ... ...',
    cz: '(...) ...-...',
    au: '.-....-....',
    it: '...-....-...',
    ng: '(...) ...-....',
    bd: '..-...-...-..',
    at: '(...) ...-....-...',
    cn: '..-.....-.....',
    gr: '..-...-.......',
    al: '.. ...-....',
    ad: '...-...-...',
    be: '(....) ...-...',
    ba: '..-...-....',
    bg: '(...) ...-...',
    by: '(..) ...-..-..',
    hr: '... ... ...',
    cy: '.... ....',
    dk: '..-..-..-..',
    ee: '....-....',
    fi: '(..) ...-..-...',
    fr: '(...) ...-...',
    de: '(...) ...-...-..',
    hu: '(...) ...-...',
    is: '...-....',
    ie: '(...) ...-...',
    kg: '(...) ...-...',
    li: '... ... ...',
    lt: '. ... ....',
    lu: '(...) ...-...',
    mt: '....-....',
    mc: '... ... ...',
    md: '....-....',
    me: '..-...-...',
    nl: '..-...-....',
    no: '... .. ...',
    pl: '.. ... .. ..',
    pt: '..-...-....',
    ro: '..-...-....',
    sm: '.. .. .... ..',
    rs: '..-...-....',
    sk: '... ... ...',
    si: '..-...-...',
    es: '(...) ...-...',
    se: '..-...-....',
    ch: '..-...-....',
    mk: '..-...-...',
    us: '(...) ...-....',
    ae: '..-...-....',
    af: '..-...-....',
    am: '..-...-...',
    ao: '(...) ...-...',
    ar: '. .. .... ....',
    aw: '...-....',
    az: '..-...-..-..',
    bf: '..-..-....',
    bh: '....-....',
    bi: '..-..-....',
    bj: '..-..-....',
    bn: '...-....',
    bo: '.-...-....',
    br: '..-....-.....',
    bt: '..-...-...',
    bw: '..-...-...',
    bz: '...-....',
    cd: '(...) ...-...',
    cf: '..-..-....',
    cg: '..-...-....',
    ci: '..-...-...',
    cl: '.-....-....',
    cm: '....-.....',
    co: '(...) ...-....',
    cr: '....-....',
    cu: '.-...-....',
    cv: '(...) ..-..',
    cw: '....-....',
    dj: '..-..-..-..',
    dz: '..-...-....',
    ec: '..-...-....',
    eg: '(...) ...-....',
    er: '.-...-...',
    et: '..-...-....',
    fj: '..-.....',
    fm: '...-....',
    gp: '(...) ...-...',
    ga: '.-..-..-...',
    ge: '(...) ...-...',
    gf: '.....-....',
    gh: '(...) ...-...',
    gm: '(...) ..-..',
    gn: '..-...-....',
    gq: '..-...-....',
    gt: '.-...-....',
    gw: '.-........',
    gy: '...-....',
    hk: '....-....',
    hn: '....-....',
    ht: '..-..-....',
    id: '(...) ...-..-...',
    il: '..-...-....',
    in: '(....) ...-...',
    io: '...-....',
    iq: '(...) ...-....',
    ir: '(...) ...-....',
    jo: '.-....-....',
    jp: '..-....-....',
    ke: '...-......',
    kh: '..-...-...',
    ki: '..-...',
    km: '..-.....',
    kp: '...-....-...',
    kr: '..-....-....',
    kw: '....-....',
    la: '(....) ...-...',
    lb: '..-...-...',
    lk: '..-...-....',
    lr: '..-...-.....',
    ls: '.-...-....',
    ly: '..-...-....',
    ma: '..-....-...',
    mg: '..-..-.....',
    mh: '...-....',
    ml: '..-..-....',
    mm: '..-...-...',
    mn: '..-..-....',
    mo: '....-....',
    mq: '(...) ..-..-..',
    mr: '..-..-....',
    mu: '...-....',
    mv: '...-....',
    mw: '.-....-....',
    mx: '(...) ..-..-....',
    my: '..-...-.....',
    mz: '..-...-....',
    na: '..-...-....',
    nc: '..-....',
    ne: '..-..-....',
    ni: '....-....',
    np: '..-...-.....',
    nr: '...-....',
    nz: '(...) ...-....',
    om: '..-...-...',
    pa: '...-....',
    pe: '(...) ...-...',
    pf: '..-..-..',
    pg: '(...) ..-...',
    ph: '(...) ...-....',
    pk: '(...) ...-....',
    ps: '..-...-....',
    pw: '...-....',
    py: '(...) ...-...',
    qa: '....-....',
    re: '.....-....',
    rw: '(...) ...-...',
    sa: '.-....-....',
    sb: '...-....',
    sc: '.-...-...',
    sd: '..-...-....',
    sg: '....-....',
    sl: '..-......',
    sn: '..-...-....',
    so: '..-...-...',
    sr: '...-....',
    ss: '..-...-....',
    st: '..-.....',
    sv: '..-..-....',
    sy: '..-....-...',
    sz: '..-..-....',
    td: '..-..-..-..',
    tg: '..-...-...',
    th: '..-...-....',
    tj: '..-...-....',
    tl: '...-.....',
    tm: '.-...-....',
    tn: '..-...-...',
    to: '.....',
    tr: '(...) ...-....',
    tv: '......',
    tw: '.-....-....',
    tz: '..-...-....',
    uy: '.-...-..-...',
    ve: '(...) ...-....',
    vn: '(...) ....-...',
    vu: '..-.....',
    ws: '..-....',
    ye: '...-...-...',
    za: '..-...-....',
    zm: '..-...-....',
    zw: '...-......',
};
export { PHONE_CUSTOM_MASK };
