import React from 'react';
import styled from 'styled-components';

const URL_IMAGES_FLAG = `${process.env.NX_CLOUD_PAYSEND_API}/images/web/flag-icons`;
type TSize = '16px' | '20px' | '24px' | '40px' | '80px';

function getSize(size?: TSize) {
    switch (size) {
        case '16px':
            return '16px';
        case '20px':
            return '20px';
        case '24px':
            return '24px';
        case '40px':
            return '40px';
        case '80px':
            return '80px';
        default:
            return '24px';
    }
}
type TFlagIconPropsStyled = Omit<IFlagIconProps, 'isDisabled' | 'rightMargin'> & {
    $isDisabled?: IFlagIconProps['isDisabled'];
    $rightMargin?: IFlagIconProps['rightMargin'];
};
const PngIcon = styled.span<TFlagIconPropsStyled>`
    display: inline-block;
    position: relative;
    width: ${(props) => getSize(props.size)};
    height: ${(props) => getSize(props.size)};
    overflow: hidden;
    border-radius: 50%;
    vertical-align: top;
    opacity: ${(props) => (props.$isDisabled ? '0.24' : null)};
    flex-shrink: 0;
    margin-right: ${(props) => (props.$rightMargin ? '16px' : null)};

    &:before {
        border-width: 1px;
        border-style: solid;
        border-color: ${(props) => props.theme.grey_16_tr};
        border-radius: 50%;
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    img {
        max-width: 100%;
    }
`;
interface IFlagIconProps {
    isDisabled?: boolean;
    name?: string;
    size?: TSize;
    rightMargin?: boolean;
}
const FlagIcon: React.FC<IFlagIconProps> = ({ name, isDisabled = false, size = '24px', rightMargin = false }) => {
    if (name) {
        return (
            <PngIcon size={size} $isDisabled={isDisabled} $rightMargin={rightMargin}>
                <img src={`${URL_IMAGES_FLAG}/${name}.png`} alt={name} loading="lazy" decoding="async" />
            </PngIcon>
        );
    }
    return null;
};
export { FlagIcon };
