import { useLayoutEffect, useState } from 'react';

export function useMatchMedia(media: string) {
    const [queryMatch, setQueryMatch] = useState(false);
    useLayoutEffect(() => {
        if (window && window.matchMedia) {
            const mediaQueryList = window.matchMedia(media);
            const setQuery = () => setQueryMatch(mediaQueryList.matches);
            setQuery();
            mediaQueryList.addEventListener('change', setQuery);

            return () => {
                mediaQueryList.removeEventListener('change', setQuery);
            };
        }
    }, [media]);

    return queryMatch;
}
