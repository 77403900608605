import styled from 'styled-components';
import { media } from '@core/theme';
import { WithPrefix } from '@core/types';

type TPopupStylesProps = {
    withoutSeparator?: boolean;
    textAlign?: 'start' | 'end' | 'center' | 'justify' | 'left' | 'right';
};

type StyledComponentProps = WithPrefix<TPopupStylesProps>;

export const PopupBoxContentWrapper = styled.div`
    background: ${(props) => props.theme.white};
    border-radius: 12px;
    max-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
`;
export const PopupBoxContent = styled.div`
    max-height: 100%;
    overflow-y: auto;
    padding-top: 28px;
`;
export const PopupBoxHeader = styled.div<StyledComponentProps>`
    padding: 32px 24px 48px;
    text-align: ${(props) => (props.$textAlign ? props.$textAlign : 'center')};

    ${media.tablet708} {
        padding: 48px 32px 64px;
    }

    p {
        margin-top: 0;
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

/* Попапы с кнопками и текстом имеют разделитель, попапы без текста - нет (withoutSeparator) */
export const PopupInternalContent = styled.div<StyledComponentProps>`
    padding: ${(props) => (props.$withoutSeparator ? '0 0 24px 0' : '24px 0')};
    border-top: ${(props) => (props.$withoutSeparator ? null : `1px solid ${props.theme.grey_16_lc}`)};

    ${media.tablet708} {
        padding: ${(props) => (props.$withoutSeparator ? '0 0 32px 0' : '32px 0')};
    }
`;

export const PopupExternalContent = styled.div`
    background: ${(props) => props.theme.white};
    border-radius: 12px;
    padding: 8px 0;
    margin-top: 8px;

    ${media.tablet708} {
        border-radius: 16px;
    }
`;
