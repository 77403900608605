import React, { useId } from 'react';
import { TextCommon } from '@consumer/ui';
import { TCalculatorError } from '@core/types';
import { CalculatorAmountBlock, CalculatorInputWrapper } from '../styles';
import { getClearValue, getFormattedValue } from '../utils/format/format';
import { THandleUpdateValue } from '../utils/widgets';

type TInputCalculatorProps = {
    label?: string;
    name: string;
    value: string;
    error?: TCalculatorError;
    isFrom: boolean;
    handleUpdateValue: THandleUpdateValue;
    fontSize: string;
    isLoading: boolean;
    subComponent: React.ReactNode;
};

const CalculatorInput = React.memo(({ label, error, value, handleUpdateValue, subComponent, name, isFrom, fontSize, isLoading }: TInputCalculatorProps) => {
    const inputId = useId();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isLoading) return;
        const clearValue = getClearValue(e?.target.value);
        handleUpdateValue(clearValue, e.target.name, isFrom);
    };

    return (
        <CalculatorAmountBlock>
            <TextCommon content={label} options={{ variant: 'secondary', textColor: 'grey_72_lc', margin: { bottom: 4 } }} />
            <CalculatorInputWrapper $isError={!!error?.shortText} $fontSize={fontSize} $isLoading={isLoading}>
                <input id={inputId} name={name} value={getFormattedValue(value)} autoComplete="off" onChange={handleChange} type="text" inputMode="numeric" />
            </CalculatorInputWrapper>
            {subComponent}
        </CalculatorAmountBlock>
    );
});
export { CalculatorInput };
