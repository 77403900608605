import TypeButton from '@consumer/type-button';
import TypeCalculator from '@consumer/type-calculator';
import TypeCarousel from '@consumer/type-carousel';
import TypeCheckbox from '@consumer/type-checkbox';
import TypeDropdown from '@consumer/type-dropdown';
import TypeImage from '@consumer/type-image';
import TypeInput from '@consumer/type-input';
import TypeLayout from '@consumer/type-layout';
import TypeList from '@consumer/type-list';
import TypeLottie from '@consumer/type-lottie';
import TypeSeparator from '@consumer/type-separator';
import TypeStories from '@consumer/type-stories';
import TypeText from '@consumer/type-text';
import TypeAction from '@core/type-action';
import {
    ActionWidgetEnum,
    AnimationIconEnum,
    ButtonEnum,
    CalculatorEnum,
    CarouselEnum,
    CheckboxEnum,
    DropdownEnum,
    ImageEnum,
    LayoutEnum,
    ListEnum,
    SeparatorEnum,
    StoriesEnum,
    TBalanceData,
    TButtonData,
    TCalculatorData,
    TCalculatorOption,
    TCarouselData,
    TCommonData,
    TextEnum,
    TextInputEnum,
    THooks,
    TInputData,
    TListData,
    TTextData,
    TWidget,
    WidgetEnum,
} from '@core/types';
import React from 'react';
import WidgetsList from './Widgets/WidgetsList';

export const getWidgetById = (widgetId: WidgetEnum, widget: TWidget<TCommonData>, hooks: THooks<TCommonData>) => {
    switch (widgetId) {
        case TextEnum.TEXT:
        case TextEnum.TEXT_LINKED:
        case TextEnum.BALANCE:
            return <TypeText widgetId={widgetId} wid={widget.wid} hooks={hooks as unknown as THooks<TTextData & TBalanceData>} />;
        case LayoutEnum.VERTICAL_LAYOUT:
        case LayoutEnum.VERTICAL_LAYOUT_WIDE:
        case LayoutEnum.HORIZONTAL_LAYOUT:
        case LayoutEnum.FLOATING_LAYOUT:
        case LayoutEnum.SEPARATE_LAYOUT:
        case LayoutEnum.CALCULATOR_CONTENT_LAYOUT:
            return <TypeLayout widgetId={widgetId} widget={widget} wid={widget.wid} Package={WidgetsList} hooks={hooks} />;
        case AnimationIconEnum.Success:
        case AnimationIconEnum.Error:
            return <TypeLottie widgetId={widgetId} wid={widget.wid} hooks={hooks} />;
        case CarouselEnum.CAROUSEL:
            return <TypeCarousel widgetId={widgetId} wid={widget.wid} hooks={hooks as unknown as THooks<TCarouselData>} />;
        case TextInputEnum.STROKED:
        case TextInputEnum.PHONE_TEXT_INPUT:
        case TextInputEnum.DATE_MASK_TEXT_INPUT:
        case TextInputEnum.ENTERPASSCODE:
        case TextInputEnum.AUTO_SUBMIT_TEXT_INPUT:
            return <TypeInput widgetId={widgetId} wid={widget.wid} hooks={hooks as THooks<TInputData>} />;
        case ButtonEnum.BUTTON_PILL:
        case ButtonEnum.BUTTON_CIRCLE:
        case ButtonEnum.BUTTON_ROW:
        case ButtonEnum.TIMER_BUTTON:
            return <TypeButton widgetId={widgetId} wid={widget.wid} hooks={hooks as unknown as THooks<TButtonData>} />;
        case CheckboxEnum.SWITCHER:
        case CheckboxEnum.CHECKBOX:
            return <TypeCheckbox widgetId={widgetId} wid={widget.wid} hooks={hooks} />;
        case DropdownEnum.DROPDOWN:
            return <TypeDropdown widgetId={widgetId} wid={widget.wid} hooks={hooks} />;
        case ActionWidgetEnum.ACTION_WIDGET:
            return <TypeAction widgetId={widgetId} wid={widget.wid} hooks={hooks} />;
        case ListEnum.SELECT:
        case ListEnum.TRANSACTIONS:
        case ListEnum.CURRENCY:
        case ListEnum.CONTACTS:
        case ListEnum.PROFILE_MENU:
        case ListEnum.TEMPLATE:
            return <TypeList widgetId={widgetId} wid={widget.wid} hooks={hooks as unknown as THooks<TListData>} Package={WidgetsList} />;
        case CalculatorEnum.CALCULATOR:
            return <TypeCalculator widgetId={widgetId} wid={widget.wid} hooks={hooks as unknown as THooks<TCalculatorData, TCalculatorOption>} />;
        case SeparatorEnum.SEPARATOR:
            return <TypeSeparator widgetId={widgetId} wid={widget.wid} hooks={hooks} />;
        case ImageEnum.VISUAL:
        case ImageEnum.PROFILE_IMAGE:
            return <TypeImage widgetId={widgetId} wid={widget.wid} hooks={hooks} />;
        case StoriesEnum.STORIES:
            return <TypeStories widgetId={widgetId} wid={widget.wid} hooks={hooks} />;
        default:
            return false;
    }
};
