import { ButtonCircle } from '@consumer/type-button';
import { TButtonData, THooks } from '@core/types';
import React, { useEffect } from 'react';
import { NavBar } from '../NavBar/NavBar';
import { ModalBody, ModalContainer, ModalViewport } from './styles';

type TModalProps = (
    | {
          isCanCloseModal: true;
          onHandleClick: () => void;
      }
    | {
          isCanCloseModal: false;
      }
) & {
    hooks?: THooks<TButtonData>;
    children: React.ReactNode;
    iconName?: string;
    isVerticalCentered?: boolean;
};
const Modal = ({ hooks, ...props }: TModalProps) => {
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'visible';
        };
    }, []);

    return (
        <ModalContainer id="portal-modal">
            <ModalViewport>
                <ModalBody $isVerticalCentered={props.isVerticalCentered}>{props.children}</ModalBody>
                {props.isCanCloseModal && (
                    <NavBar align="space-between">
                        <ButtonCircle iconName={props.iconName} isWidget={false} handleClick={props.onHandleClick} hooks={hooks} />
                    </NavBar>
                )}
            </ModalViewport>
        </ModalContainer>
    );
};
export { Modal };
