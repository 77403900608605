import React, { ElementType } from 'react';

const getHash = (str = '') => {
    let hash = 0,
        i,
        chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0;
    }
    return hash;
};
type TSplitTextProps = {
    text: string;
    as?: ElementType;
};

export const SplitText = ({ text, as: Component = 'p' }: TSplitTextProps): React.ReactNode => {
    const regex = /\r?\n/m;
    const textArray = text.split(regex);
    if (textArray.length > 1) {
        return (
            <>
                {textArray.map((m) => (
                    <Component key={getHash(m)}>{m}</Component>
                ))}
            </>
        );
    }
    return text;
};
