import { TWidget, TSeparatorVariant, TSeparatorHierarchy, TSeparatorData, THooks, TMargin, TSeparatorOption, WithPrefix } from '@core/types';
import React from 'react';
import { TextCommon, marginHelperWithPrefix } from '@consumer/ui';
import styled, { ExecutionContext } from 'styled-components';

type TSeparatorProps<TD, TO> =
    | {
          isWidget: false;
          text?: string;
          metaText?: string;
          separatorVariant?: TSeparatorVariant;
          separatorHierarchy?: TSeparatorHierarchy;
          margin?: TMargin;
      }
    | ({
          isWidget: true;
          widget: TWidget<TD, TO>;
      } & {
          hooks: THooks<TD>;
      });

interface ISeparatorProps {
    separatorVariant?: TSeparatorVariant;
    separatorHierarchy?: TSeparatorHierarchy;
    marginTop?: number;
    marginRight?: number;
    marginBottom?: number;
    marginLeft?: number;
}

type StyledComponentProps = ExecutionContext & WithPrefix<ISeparatorProps>;

const SeparatorBlock = styled.div<StyledComponentProps>`
    display: flex;
    justify-content: space-between;
    height: ${(props) => (props.$separatorVariant === 'line' ? '1px' : null)};
    background: ${(props) => (props.$separatorVariant === 'line' || props.$separatorHierarchy === 'secondary' ? props.theme.grey_16_lc : null)};
    padding: ${(props) => (props.$separatorVariant === 'subheader' ? '16px 24px' : null)};
    ${marginHelperWithPrefix};
`;

export function Separator(props: TSeparatorProps<TSeparatorData, TSeparatorOption>) {
    const { isWidget } = props;
    const variant = isWidget ? props.widget?.options?.separatorVariant : props.separatorVariant;
    const hasMeta = isWidget ? props.widget?.data?.metaText : props.metaText;

    return (
        <SeparatorBlock
            data-test={isWidget ? props.widget?.uid : ''}
            $separatorVariant={isWidget ? props.widget?.options?.separatorVariant : props.separatorVariant ? props.separatorVariant : 'line'}
            $separatorHierarchy={isWidget ? props.widget?.options?.separatorHierarchy : props.separatorHierarchy}
            $marginBottom={isWidget ? props.widget?.options?.margin?.bottom : props.margin?.bottom}
            $marginTop={isWidget ? props.widget?.options?.margin?.top : props.margin?.top}
            $marginLeft={isWidget ? props.widget?.options?.margin?.left : props.margin?.left}
            $marginRight={isWidget ? props.widget?.options?.margin?.right : props.margin?.right}
        >
            {variant === 'subheader' ? (
                <>
                    <TextCommon content={isWidget ? props.widget?.data?.text : props.text} options={{ variant: 'subheader', textColor: 'grey_72_lc' }} />
                    {hasMeta && (
                        <TextCommon
                            content={isWidget ? props.widget?.data?.metaText : props.metaText}
                            options={{ variant: 'subheader', textColor: 'grey_72_lc' }}
                        />
                    )}
                </>
            ) : null}
        </SeparatorBlock>
    );
}
