import breakpoints from './breakpoints';

const media = {
    mobile320: `@media screen and (min-width: ${breakpoints.mobile320})`,
    mobile481: `@media screen and (min-width: ${breakpoints.mobile481})`,
    tablet708: `@media screen and (min-width: ${breakpoints.tablet708})`,
    tablet889: `@media screen and (min-width: ${breakpoints.tablet889})`,
    desktop1440: `@media screen and (min-width: ${breakpoints.desktop1440})`,
    desktop1920: `@media screen and (min-width: ${breakpoints.desktop1920})`,
};

export default media;
