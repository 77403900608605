import { TextCommon } from '@consumer/ui';
import { TTextData, TTextOption } from '@core/types';
import React from 'react';
import styled from 'styled-components';
import { getMarkup } from './utils';

type TTextLinkedProps = {
    options: TTextOption;
    data: TTextData;
    uid?: string;
};
const TextLinkedStyled = styled.div`
    a:link,
    a:visited {
        text-decoration: none;
        color: ${(props) => props.theme.black};
    }
`;
const TextLinked = (props: TTextLinkedProps) => {
    const { options, data, uid } = props;

    return (
        <TextLinkedStyled>
            <TextCommon as="div" uid={uid} options={options} content={<div dangerouslySetInnerHTML={{ __html: `<div>${getMarkup(data)}</div>` }} />} />
        </TextLinkedStyled>
    );
};
export { TextLinked };
