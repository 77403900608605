import { TextCommon } from '@consumer/ui';
import { breakpoints, media } from '@core/theme';
import { TCarouselData, TCommonOption, TGroupWidgetProps, WithPrefix } from '@core/types';
import { useMatchMedia } from '@core/utils';
import { CarouselProvider, DotGroup, Slide, Slider } from 'pure-react-carousel';
import React from 'react';
import styled, { css } from 'styled-components';
import 'pure-react-carousel/dist/react-carousel.es.css';

export type TButtonStoryProps = {
    imgSrc?: string;
    background?: string;
};

type StyledComponentProps = WithPrefix<TButtonStoryProps>;

const borderStyles = css`
    border: 0;
    border-radius: 12px;
    outline-offset: 1px;
    outline: ${({ theme }) => `1px solid ${theme.purple_100}`};
`;

const ButtonStory = styled.div<StyledComponentProps>`
    cursor: grab;
    box-sizing: border-box;
    padding: 12px;
    background: ${(props) => (props.$imgSrc ? `url(${props.$imgSrc})` : props.$background)};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: ${({ theme }) => theme.white};
    height: 120px;
    margin: 2px 8px;
    ${borderStyles}
`;
const CarouselWrapper = styled.div`
    padding-left: 16px;
    margin-bottom: 16px;
`;
const CarouselDotGroup = styled.div`
    margin-top: 16px;
    display: none;

    ${media.mobile481} {
        display: block;
    }

    & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }

    button {
        background-color: ${({ theme }) => theme.grey_16_lc};
        padding: 0;
        cursor: pointer;
        border-radius: 50%;
        height: 8px;
        width: 8px;
    }

    button:disabled {
        background-color: ${({ theme }) => theme.purple_100};
        cursor: pointer;
    }
`;
const TypeCarousel: React.FC<TGroupWidgetProps<TCarouselData, TCommonOption>> = ({ wid, hooks }) => {
    const { widget } = hooks.useGetWidget(wid);
    const { data, uid } = widget;
    const isMobileBig = useMatchMedia(`(min-width: ${breakpoints.mobile481})`);
    const isTablet = useMatchMedia(`(min-width: ${breakpoints.tablet708})`);

    const dataItemsLength = data?.items?.length || 0;
    const visibleSlides = dataItemsLength > 2 ? (isTablet ? 3 : isMobileBig ? 2 : 2.2) : dataItemsLength;

    if (dataItemsLength > 0) {
        return (
            <CarouselWrapper data-test={uid}>
                <CarouselProvider
                    isIntrinsicHeight
                    naturalSlideWidth={100}
                    naturalSlideHeight={105}
                    totalSlides={dataItemsLength}
                    visibleSlides={visibleSlides}
                >
                    <Slider>
                        {data?.items?.map((button, index) => (
                            <Slide key={index} index={index}>
                                <ButtonStory $background={button.background} $imgSrc={button.imgSrc}>
                                    <TextCommon content={button.title} options={{ variant: 'tag', textColor: 'white_72_tr_base', margin: { bottom: 4 } }} />
                                    <TextCommon content={button.text} options={{ variant: 'secondary', textColor: 'white_base' }} />
                                </ButtonStory>
                            </Slide>
                        ))}
                    </Slider>
                    <CarouselDotGroup>
                        <DotGroup />
                    </CarouselDotGroup>
                </CarouselProvider>
            </CarouselWrapper>
        );
    }
    return null;
};

export default TypeCarousel;
