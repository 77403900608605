import styled from 'styled-components';
import { ElementType, MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import { fonts } from '@core/theme';

interface IRowRegularProps {
    disabled?: boolean;
    type?: 'reactLink';
    to?: string;
    onClick?: MouseEventHandler;
    as?: ElementType;
}

export const CommonRowRegular = styled(Link)<IRowRegularProps>`
    font-family: ${fonts.regular};
    border: none;
    width: 100%;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    outline: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    vertical-align: top;
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    pointer-events: auto;
    font-size: 17px;
    line-height: 24px;
    border-radius: 0;
    padding: 16px 24px;
    min-height: 72px;
    text-align: left;
    color: ${(props) => props.theme.black};
    background: ${(props) => props.theme.white};

    &:hover {
        background: ${(props) => (props.disabled ? 'none' : props.theme.grey_8_lc)};
    }
`;
export const RowContent = styled.div<IRowRegularProps>`
    display: flex;
    flex-direction: column;
    flex: 1;
`;
export const HighlightedTitle = styled.div<IRowRegularProps>`
    color: ${(props) => (props.disabled ? props.theme.grey_40_lc : props.theme.black)};

    & > mark {
        background: none;
        color: ${(props) => props.theme.purple_100};
    }
`;
