import styled, { DefaultTheme, ExecutionContext } from 'styled-components';
import { fonts } from '@core/theme';
import { TSize, WithPrefix } from '@core/types';
import { marginHelperWithPrefix } from '../styles/index';

interface ITextProps {
    variant?: 'display1' | 'display2' | 'header' | 'subheader' | 'main' | 'secondary' | 'tag';
    textAlign?: 'start' | 'center' | 'end' | 'justified';
    textColor?: string;
    smallCaps?: boolean;
    fontWeight?: 'regular' | 'medium' | 'bold';
    ellipsis?: boolean;
    lineThrough?: boolean;
    marginTop?: number;
    marginLeft?: number;
    marginRight?: number;
    marginBottom?: number;
}

type StyledComponentProps = ExecutionContext & WithPrefix<ITextProps>;

const getFontSize = (variant?: TSize): string | undefined => {
    switch (variant) {
        case 'display1':
            return '40px';
        case 'display2':
            return '32px';
        case 'header':
            return '24px';
        case 'subheader':
            return '14px';
        case 'main':
            return '17px';
        case 'secondary':
            return '14px';
        case 'tag':
            return '8px';
        default:
            return '17px';
    }
};
const getLineHeight = (variant?: TSize): string | undefined => {
    switch (variant) {
        case 'display1':
            return '48px';
        case 'display2':
            return '40px';
        case 'header':
            return '36px';
        case 'subheader':
            return '20px';
        case 'main':
            return '24px';
        case 'secondary':
            return '20px';
        case 'tag':
            return '10px';
        default:
            return '24px';
    }
};

const getColor = (props: StyledComponentProps): string => {
    return props.$textColor ? props.theme[props.$textColor as keyof DefaultTheme] : '';
};

const getFontWeight = (props: StyledComponentProps): string => {
    switch (props.$fontWeight) {
        case 'medium':
            return fonts.medium;
        case 'bold':
            return fonts.bold;
        default:
            return fonts.regular;
    }
};

export const TextCommonStyled = styled.p<StyledComponentProps>`
    font-family: ${(props) => (props.$variant === 'tag' ? fonts.bold : props.$variant === 'subheader' ? fonts.medium : getFontWeight(props))};
    font-size: ${(props) => getFontSize(props.$variant)};
    line-height: ${(props) => getLineHeight(props.$variant)};
    color: ${(props) => getColor(props)};
    font-variant: ${(props) => (props.$smallCaps ? 'small-caps' : null)};
    text-transform: ${(props) => (props.$variant === 'tag' || props.$variant === 'subheader' ? 'uppercase' : null)};
    letter-spacing: ${(props) => (props.$variant === 'tag' ? '0.64px' : props.$variant === 'subheader' ? '1.12px' : null)};
    text-align: ${(props) => props.$textAlign};
    overflow: ${(props) => (props.$ellipsis ? 'hidden' : null)};
    text-overflow: ${(props) => (props.$ellipsis ? 'ellipsis' : null)};
    text-decoration: ${(props) => (props.$lineThrough ? 'line-through' : null)};
    ${marginHelperWithPrefix};
`;
