import React, { useState, useRef, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { RowEnum, TContacts, TListData, TListOption, TWidgetProps, TContactValue, TRowComponentProps, TItem } from '@core/types';
import RowComponent from '@consumer/type-row';
import { convertContactsToRowComponentProps, ConditionalRender } from '@core/utils';
import { ic_dotted_list as IconEmpty } from '@consumer/assets';
import { TextCommon } from '@consumer/ui';
import ListItems from './ListItems';
import InputSearch from './InputSearch';
import { EmptyContainer } from './styles';

interface ContactsManager {
    getProperties(): Promise<string[]>;

    select(properties: string[], options?: { multiple: boolean }): Promise<TContacts[]>;
}

interface IContacts {
    readonly contacts: ContactsManager;
}

declare global {
    interface Navigator extends IContacts {}
}

const supported = 'contacts' in navigator && 'ContactsManager' in window && 'select' in navigator.contacts && 'getProperties' in navigator.contacts;
const ListContacts = ({ widget, hooks }: TWidgetProps<TListData, TListOption>) => {
    const [props, setProps] = useState<TRowComponentProps[] | []>([]);
    const [contacts, setContacts] = useState<TContacts[] | []>([]);
    const { handleClick } = hooks.useList(widget);
    const imageRef = useRef(null);
    const localTheme = useTheme();
    const { data, options } = widget;
    const rowId = data.cellWidgetId ?? RowEnum.ROW_REGULAR;

    useEffect(() => {
        const ref = imageRef.current;
        return () => {
            if (ref) {
                URL.revokeObjectURL(ref);
            }
        };
    }, []);

    const handleInputClick = () => {
        if (supported) {
            navigator.contacts
                .getProperties()
                .then((supportedProperties) => {
                    if (supportedProperties) {
                        return navigator.contacts.select(supportedProperties, { multiple: true });
                    }
                })
                .then((contacts) => {
                    if (contacts && contacts?.length > 0) {
                        setProps(convertContactsToRowComponentProps(contacts, rowId, imageRef));
                        setContacts(contacts);
                    }
                })
                .catch((e) => console.warn(e));
        }
    };
    const handleRowClick = (index: number) => {
        const contact: TContactValue = {
            email: contacts[index].email[0],
            fullName: contacts[index].name[0],
            number: contacts[index].tel[0],
        };
        const item: TItem = {
            id: contact.number ?? '',
            title: contact.fullName ?? '',
            value: contact,
        };
        const blob = contacts[index].icon[0];
        if (blob && blob instanceof Blob) {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = function () {
                contact.avatar = reader.result as string;
                handleClick(widget.wid, item);
            };
            reader.onerror = function () {
                console.error(reader.error);
            };
        } else {
            handleClick(widget.wid, item);
        }
    };
    const renderItem = ({ item, index }: { item: TRowComponentProps; index: number }) => {
        return <RowComponent {...item} key={index} handleClick={() => handleRowClick(index)} />;
    };
    if (supported) {
        return (
            <>
                <ConditionalRender condition={options.searchEnabled}>
                    <InputSearch
                        asButton={true}
                        widget={widget}
                        hooks={hooks}
                        handleClick={handleInputClick}
                        marginTop={16}
                        marginRight={24}
                        marginBottom={16}
                        marginLeft={24}
                    />
                </ConditionalRender>
                <ConditionalRender condition={props.length > 0}>
                    <ListItems items={props} renderItem={renderItem} />
                </ConditionalRender>
                <ConditionalRender condition={props.length === 0}>
                    <EmptyContainer>
                        <IconEmpty fill={localTheme.grey_72_lc} />
                        <TextCommon
                            content={options?.emptyContactsResultLabel}
                            options={{ variant: 'secondary', textColor: 'grey_72_lc', margin: { top: 16 } }}
                        />
                    </EmptyContainer>
                </ConditionalRender>
            </>
        );
    }
    return null;
};
export { ListContacts };
