import React from 'react';
import { ic_passcode_dot as Dot } from '@consumer/assets';
import { useTheme } from 'styled-components';

type TPasscodeDot = {
    currentLength: number;
    dotIndex: number;
};
const PasscodeDot = ({ dotIndex, currentLength }: TPasscodeDot) => {
    const theme = useTheme();
    return <Dot fill={currentLength >= dotIndex ? theme.purple_100 : theme.grey_40_lc} />;
};
export default PasscodeDot;
