import React, { useState, Dispatch, SetStateAction } from 'react';
import { TItem } from '@core/types';

export type TListItemProps<T = TItem> = {
    item: T;
    index: number;
    firstLetter?: string;
    addLetter?: boolean;
    isSelected?: boolean;
    setSelectedIndex?: Dispatch<SetStateAction<number | undefined>>;
};
type TListItemsProps<T> = {
    items: T[];
    renderItem: React.FC<TListItemProps<T>>;
    sortByAlphabet?: boolean;
    currentIndex?: number;
};

type TBaseItem = {
    title: string;
};
function getFirstLetter<T extends TBaseItem>(item: T) {
    return item.title[0];
}

function ListItems<TItem extends TBaseItem>({ items, renderItem, currentIndex, sortByAlphabet = false }: TListItemsProps<TItem>) {
    const [selectedIndex, setSelectedIndex] = useState<number | undefined>(currentIndex);

    if (items.length > 0) {
        if (sortByAlphabet) {
            const sortedItems = [...items].sort((a, b) => a.title.localeCompare(b.title));
            let currentLetter = '';

            return sortedItems.map((item, index) => {
                const firstLetter = getFirstLetter<TItem>(item);
                const addLetter = firstLetter !== currentLetter;
                currentLetter = firstLetter;
                const isSelected = index === selectedIndex;
                return renderItem({ item, index, firstLetter, addLetter, isSelected, setSelectedIndex });
            });
        } else {
            return items.map((item, index) => {
                const isSelected = index === selectedIndex;
                return renderItem({ item, index, isSelected, setSelectedIndex });
            });
        }
    }
    return null;
}
export default ListItems;
