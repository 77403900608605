import { TextCommon, Tag } from '@consumer/ui';
import { Visual } from '@consumer/type-image';
import { THandleClick, TItem } from '@core/types';
import { numberFormat } from '@core/utils';
import React, { ElementType } from 'react';
import { CommonRowTransaction, TransactionContent, TransactionRight, TransactionRow } from './styles/RowTransaction';
import { useTheme } from 'styled-components';
import * as Svg from '@consumer/assets';

type TRowTransaction = {
    as: ElementType;
    handleClick?: THandleClick;
    item: TItem;
};

enum TransactionStatus {
    CREATED = 'CREATED',
    INPUT = 'INPUT',
    INPUT_FINISHED = 'INPUT FINISHED',
    OUTPUT = 'OUTPUT',
    OUTPUT_FINISHED = 'OUTPUT FINISHED',
    REFUND = 'REFUND',
    REFUND_FINISHED = 'REFUND FINISHED',
    SUCCESS = 'SUCCESS',
    FAIL = 'FAIL',
    HALT = 'HALT',
}

export function RowTransaction({ item, as, handleClick }: TRowTransaction) {
    const theme = useTheme();
    const isEnabled = item.enabled ?? true;
    const title = item.title;
    const subtitle = item.subtitle;
    const amount = numberFormat(item.amount);
    const currency = item.currency;
    const direction = item.direction;
    const iconName = item.iconName;
    const avatar = item.avatar;
    const flagIconSmall = item.flagIconSmall;
    const statusFailed = item.status ? item.status === TransactionStatus.FAIL : false;

    const getStatusIcon = (item: TItem) => {
        if (!item.status) return;
        switch (item.status as TransactionStatus) {
            case TransactionStatus.SUCCESS:
                return 'ic_check_circle_small';
            case TransactionStatus.FAIL:
                return 'ic_x_circle_small';
            default:
                return 'ic_clock_filled_small';
        }
    };

    const getIconColor = (item: TItem) => {
        if (!item.status) return theme.black;
        switch (item.status as TransactionStatus) {
            case TransactionStatus.SUCCESS:
                return theme.black;
            case TransactionStatus.FAIL:
                return theme.grey_40_lc;
            default:
                return theme.grey_72_lc;
        }
    };

    const getTextColor = (item: TItem) => {
        if (!item.status) return 'black';
        switch (item.status as TransactionStatus) {
            case TransactionStatus.SUCCESS:
                return 'black';
            case TransactionStatus.FAIL:
                return 'grey_40_lc';
            default:
                return 'grey_72_lc';
        }
    };

    const IconComponent = Svg[getStatusIcon(item) as keyof typeof Svg] ?? Svg['ic_clock_filled_small' as keyof typeof Svg];

    return (
        <CommonRowTransaction as={as} $enabled={isEnabled} onClick={handleClick}>
            {iconName && (
                <Visual
                    isWidget={false}
                    iconName={iconName}
                    rightMargin
                    iconHierarchy={'secondary'}
                    iconVariant={'circle'}
                    flagIconSmall={flagIconSmall}
                    size={'small'}
                    isEnabled={!statusFailed}
                />
            )}
            {avatar && <Visual isWidget={false} avatar={avatar} rightMargin flagIconSmall={flagIconSmall} size={'small'} isEnabled={!statusFailed} />}
            <TransactionContent>
                <TransactionRow>
                    <TextCommon content={title} options={{ textColor: statusFailed ? 'grey_40_lc' : 'black', ellipsis: true }} />
                </TransactionRow>
                {subtitle && (
                    <TransactionRow>
                        <TextCommon
                            content={subtitle}
                            options={{ variant: 'secondary', textColor: statusFailed ? 'grey_40_lc' : 'grey_72_lc', ellipsis: true }}
                        />
                    </TransactionRow>
                )}
            </TransactionContent>
            <TransactionRight>
                <TransactionRow>
                    <TextCommon
                        content={<span>{direction === 'debit' ? '+' : '-'}&nbsp;</span>}
                        options={{ variant: 'secondary', textColor: getTextColor(item), lineThrough: statusFailed }}
                    />
                    <TextCommon content={amount} options={{ variant: 'secondary', textColor: getTextColor(item), lineThrough: statusFailed }} />
                    <TextCommon
                        content={<span>&nbsp;{currency}</span>}
                        options={{
                            variant: 'secondary',
                            textColor: getTextColor(item),
                            smallCaps: true,
                            lineThrough: statusFailed,
                        }}
                    />
                </TransactionRow>
                <TransactionRow $alignRight>
                    {item.status && <IconComponent fill={getIconColor(item)} />}
                    {item.statusText && <Tag metaText={item.statusText} metaVariant={'tag'} />}
                </TransactionRow>
            </TransactionRight>
        </CommonRowTransaction>
    );
}
