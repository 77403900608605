import React from 'react';
import styled, { ExecutionContext } from 'styled-components';
import { WithPrefix } from '@core/types';
import { marginHelperWithPrefix } from '@consumer/ui';

export type TVisualProps = {
    iconName?: React.ReactNode;
    iconVariant?: 'simple' | 'circle';
    iconHierarchy?: 'emphasised' | 'primary' | 'secondary' | 'secondaryAlt' | 'destructive';
    size?: 'small' | 'medium';
    flagIcon?: string;
    flagIconSmall?: string;
    avatar?: string;
    disabled?: boolean;
    rightMargin?: boolean;
    marginTop?: number;
    marginRight?: number;
    marginBottom?: number;
    marginLeft?: number;
};

type StyledComponentProps = ExecutionContext & WithPrefix<TVisualProps>;

const getIconColor = (props: StyledComponentProps) => {
    switch (props.$iconHierarchy) {
        case 'emphasised':
            return props.theme.white_base;
        case 'secondary':
        case 'secondaryAlt':
            return props.$disabled ? props.theme.grey_40_lc : props.theme.black;
        case 'destructive':
            return props.$disabled ? props.theme.pink_40_lc : props.theme.pink_100;
        default:
            return props.$disabled ? props.theme.purple_40_tr : props.theme.purple_100;
    }
};
const getBackgroundColor = (props: StyledComponentProps) => {
    if (props.$iconVariant === 'circle') {
        switch (props.$iconHierarchy) {
            case 'emphasised':
                return props.$disabled ? props.theme.purple_8_tr : props.theme.purple_100;
            case 'secondary':
                return props.$disabled ? 'transparent' : props.theme.grey_8_lc;
            case 'secondaryAlt':
                return props.$disabled ? 'transparent' : props.theme.white;
            case 'destructive':
                return props.$disabled ? 'transparent' : props.theme.pink_8_tr;
            default:
                return props.$disabled ? 'transparent' : props.theme.purple_8_tr;
        }
    }
    return null;
};
const getBorderColor = (props: StyledComponentProps) => {
    if (props.$iconVariant === 'circle') {
        switch (props.$iconHierarchy) {
            case 'emphasised':
                return props.$disabled ? 'transparent' : props.theme.purple_100;
            case 'secondary':
                return props.$disabled ? props.theme.grey_8_lc : 'transparent';
            case 'destructive':
                return props.$disabled ? props.theme.pink_8_lc : 'transparent';
            default:
                return props.$disabled ? props.theme.purple_16_tr : 'transparent';
        }
    }
    return 'transparent';
};

export const VisualContainer = styled.span<StyledComponentProps>`
    display: inline-flex;
    border-radius: 50%;
    position: relative;
    ${marginHelperWithPrefix};
    margin-right: ${(props) => (props.$rightMargin ? '16px' : null)};
`;
export const IconContainer = styled.span<StyledComponentProps>`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: ${(props) => getBackgroundColor(props)};
    border: 1px solid ${(props) => getBorderColor(props)};
    width: ${(props) => (props.$size === 'medium' ? '78px' : '38px')};
    height: ${(props) => (props.$size === 'medium' ? '78px' : '38px')};
    border-radius: 50%;

    svg {
        fill: ${(props) => getIconColor(props)};
    }
`;
export const BadgeContainer = styled.span<StyledComponentProps>`
    display: inline-flex;
    position: absolute;
    bottom: ${(props) => (props.$size === 'medium' ? '0' : '-4px')};
    right: ${(props) => (props.$size === 'medium' ? '0' : '-6px')};
`;
export const AvatarContainer = styled.span<StyledComponentProps>`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: ${({ theme }) => theme.grey_16_lc};
    border-radius: 50%;
    width: ${(props) => (props.$size === 'medium' ? '80px' : '40px')};
    height: ${(props) => (props.$size === 'medium' ? '80px' : '40px')};
    opacity: ${(props) => (props.$disabled ? '0.24' : null)};

    img {
        width: 100%;
        max-width: 100%;
        border-radius: 50%;
        height: 100%;
    }
`;
