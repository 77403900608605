import React, { MouseEventHandler } from 'react';

import { FloatingLabel, InputContainer, InputLabel, HintContainer, InputRightElement } from './styles';
import { FunctionActionTypeEnum, TUseFunction } from '@core/types';

export interface IInputWrapperProps {
    isError?: boolean;
    errorMessage?: string;
    helperText?: string;
    isFilled?: boolean;
    isFocused?: boolean;
    isDisabled?: boolean;
    isDropdownInput?: boolean;
    htmlFor?: string;
    labelText?: string;
    alignCenter?: boolean;
    marginTop?: number;
    marginRight?: number;
    marginBottom?: number;
    marginLeft?: number;
    children: React.ReactNode;
    leftInputComponent?: React.ReactNode;
    rightInputComponent?: React.ReactNode;
    setFocus?: MouseEventHandler;
    useFunction?: TUseFunction;
}

const InputWrapper: React.FC<IInputWrapperProps> = (props) => {
    const { getFunctionResult, hasFunctionForAction } = props.useFunction ?? {};
    const hideInput = hasFunctionForAction?.(FunctionActionTypeEnum.HIDE) ? getFunctionResult?.(FunctionActionTypeEnum.HIDE) : false;
    const isDisabled = hasFunctionForAction?.(FunctionActionTypeEnum.DISABLED) ? getFunctionResult?.(FunctionActionTypeEnum.DISABLED) : props.isDisabled;

    return (
        <>
            <InputContainer
                $isError={props.isError}
                $hide={hideInput}
                $isFilled={props.isFilled}
                $isFocused={props.isFocused}
                $isDisabled={isDisabled}
                $marginTop={props.marginTop}
                $marginRight={props.marginRight}
                $marginBottom={props.marginBottom}
                $marginLeft={props.marginLeft}
            >
                <InputLabel
                    htmlFor={props.htmlFor}
                    onClick={(e) => props.setFocus?.(e)}
                    $isError={props.isError}
                    $isDisabled={props.isDisabled}
                    $isDropdownInput={props.isDropdownInput}
                    $rightInputComponent={props.rightInputComponent}
                >
                    {props.leftInputComponent}
                    {props.children}
                    <FloatingLabel $isError={props.isError} $isFilled={props.isFilled} $isFocused={props.isFocused} $isDisabled={props.isDisabled}>
                        {props.labelText}
                    </FloatingLabel>
                </InputLabel>
                {props.rightInputComponent && <InputRightElement>{props.rightInputComponent}</InputRightElement>}
            </InputContainer>
            {!hideInput && props.helperText && (
                <HintContainer $isError={props.isError} $marginRight={props.marginRight} $marginLeft={props.marginLeft} $isDisabled={props.isDisabled}>
                    {props.helperText}
                </HintContainer>
            )}
            {!hideInput && props.isError && props.errorMessage && (
                <HintContainer
                    $marginRight={props.marginRight}
                    $marginLeft={props.marginLeft}
                    $isError={props.isError}
                    $isDisabled={props.isDisabled}
                    $alignCenter={props.alignCenter}
                >
                    {props.errorMessage}
                </HintContainer>
            )}
        </>
    );
};
export { InputWrapper };
