export default {
    navBar: '5',
    storiesButtons: '10',
    scrollableLayout: '1000',
    scrollableOverlay: '999',
    floatingLayoutTop: '500',
    modalIndex: '2000',
    floatingLayoutBottom: '1002',
    loaderLayout: '3000',
};
