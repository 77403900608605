import { ActionActionTypeEnum, TCommonData, TCommonDataObj, TCommonOption, TUseFetchDataHook, TWAction, TWidget } from '@core/types';
import produce from 'immer';
import { useCallback } from 'react';
import { selectCurrentPageId, selectMutableWidget, selectMutableWidgetsMap, selectScenarioId } from '../features/scenario.slice';
import { getWidgetDataOrUpdatePage } from '../services/apiService';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { fetchScenario } from '../features/utils/sharedActions';

export const useFetchData = (widget: TWidget<TCommonData>): TUseFetchDataHook<TCommonData, TCommonOption> => {
    const dispatch = useAppDispatch();
    const mutableWidgetsMap = useAppSelector(selectMutableWidgetsMap);
    const mutableWidget = useAppSelector((state) => selectMutableWidget(state, widget.wid));
    const scenarioId = useAppSelector(selectScenarioId);
    const currentPageId = useAppSelector(selectCurrentPageId);

    return {
        fetchData: useCallback(
            async (a: TWAction) => {
                return await getWidgetDataOrUpdatePage(a, widget.internalId, scenarioId, currentPageId, mutableWidgetsMap, dispatch);
            },
            [widget.internalId, scenarioId, currentPageId, mutableWidgetsMap, dispatch],
        ),
        fetchDataWidget: useCallback(
            async (a: TWAction, value: string, name?: string, isFrom?: boolean) => {
                const updatedMutableWidget = produce(mutableWidget, (draftMutableWidget) => {
                    if (name && typeof isFrom === 'boolean') {
                        (draftMutableWidget.data.value as TCommonDataObj)[name] = value;
                        (draftMutableWidget.data.value as TCommonDataObj)['isFrom'] = isFrom;
                    } else {
                        draftMutableWidget.data.value = value;
                    }
                });
                return await getWidgetDataOrUpdatePage(a, widget.internalId, scenarioId, currentPageId, { [widget.wid]: updatedMutableWidget }, dispatch);
            },
            [widget.internalId, widget.wid, scenarioId, currentPageId, mutableWidget, dispatch],
        ),
        fetchScenario: useCallback(
            (action: TWAction) => {
                if (action?.actionType === ActionActionTypeEnum[action?.actionType]) {
                    dispatch(fetchScenario({ action }));
                }
            },
            [dispatch],
        ),
    };
};
