import { EStatus, IRootState, TCommonData, TMutableWidgetsData, TMutableWidgetsOption, TStateScenario, TWidget } from '@core/types';
import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { isPendingAction, isRejectedAction } from './utils/actions';
import { fetchScenario, updateScenario } from './utils/sharedActions';

interface IUpdateWidgetValue extends TCommonData {
    wid: string;
}

interface IUpdateWidget {
    wid: string;
    widget: TWidget<TMutableWidgetsData, TMutableWidgetsOption>;
}

const initialState: TStateScenario = {
    status: EStatus.Idle,
    errors: {
        errorMessage: '',
    },
    scenarioId: '',
    currentPageId: '',
    mutableWidgetsMap: {},
    bottomSheetMap: {},
    submitWidget: undefined,
    pages: [],
};

const scenarioSlice = createSlice({
    name: 'scenario',
    initialState,
    reducers: {
        updateWidgetValue: (state, { payload }: PayloadAction<IUpdateWidgetValue>) => {
            if (state?.mutableWidgetsMap?.[payload.wid]?.data) {
                state.mutableWidgetsMap[payload.wid].data.value = payload.value;
            }
        },
        updateWidget: (state, { payload }: PayloadAction<IUpdateWidget>) => {
            if (state?.mutableWidgetsMap?.[payload.wid]) {
                state.mutableWidgetsMap[payload.wid] = payload.widget;
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(isAnyOf(fetchScenario.fulfilled, updateScenario.fulfilled), (state, { payload }) => {
                if (payload) {
                    state.status = EStatus.Succeeded;
                    state.scenarioId = payload.scenarioId;
                    state.currentPageId = payload.currentPageId;
                    state.mutableWidgetsMap = payload.mutableWidgetsMap;
                    state.bottomSheetMap = payload.bottomSheetMap;
                    state.submitWidget = payload.submitWidget;
                    state.pages = payload.pages;
                }
            })
            .addMatcher(isPendingAction, (state) => {
                state.status = EStatus.Loading;
            })
            .addMatcher(isRejectedAction, (state, { payload }) => {
                if (payload) {
                    state.status = EStatus.Failed;
                    state.errors = payload;
                }
            });
    },
});

export const { updateWidgetValue, updateWidget } = scenarioSlice.actions;
export const selectPage = (state: IRootState, id: number) => state?.scenario?.pages[id];
export const selectMutableWidgetsMap = (state: IRootState) => state?.scenario?.mutableWidgetsMap;
export const selectScenario = (state: IRootState) => state?.scenario;
export const selectScenarioId = (state: IRootState) => state?.scenario?.scenarioId;
export const selectCurrentPageId = (state: IRootState) => state?.scenario?.currentPageId;
export const selectMutableWidget = (state: IRootState, id: string) => state?.scenario?.mutableWidgetsMap?.[id];
export const selectBottomSheets = (state: IRootState) => state?.scenario?.bottomSheetMap;
export const selectSubmitWidget = (state: IRootState) => state?.scenario?.submitWidget;
export const selectError = (state: IRootState) => state?.scenario?.errors;
export const selectStatus = (state: IRootState) => state?.scenario?.status;
export default scenarioSlice;
