import { nanoid } from '@reduxjs/toolkit';
import React from 'react';
import { TCommonData, TCommonOption, TWidget } from '@core/types';
import Widget from './Widget';
import { hooks } from '../../hooks';

interface IWidgetsListProps {
    widgets?: TWidget<TCommonData, TCommonOption>[];
}

const WidgetsList: React.FC<IWidgetsListProps> = ({ widgets }) => {
    return widgets?.map((w) => <Widget widget={w} hooks={hooks} key={nanoid()} />);
};
export default WidgetsList;
