import React, { MouseEventHandler } from 'react';

import { FlagIcon, Badge } from '@consumer/ui';
import * as Svg from '@consumer/assets';
import { TWidget, THooks, TMargin, TVisualData, TVisualOption, FunctionActionTypeEnum } from '@core/types';
import { VisualContainer, IconContainer, BadgeContainer, AvatarContainer } from './styles/Visual';

type TVisualProps<TD, TO> = (
    | {
          isWidget: false;
          iconName?: React.ReactNode;
          iconVariant?: 'simple' | 'circle';
          iconHierarchy?: 'emphasised' | 'primary' | 'secondary' | 'secondaryAlt' | 'destructive';
          size?: 'small' | 'medium';
          flagIcon?: string;
          flagIconSmall?: string;
          avatar?: string;
          rightMargin?: boolean;
          margin?: TMargin;
          isEnabled?: boolean;
      }
    | {
          isWidget: true;
          widget: TWidget<TD, TO>;
          hooks: THooks<TD>;
      }
) & {
    handleClick?: MouseEventHandler;
};

const getFlagSize = (props: TVisualProps<TVisualData, TVisualOption>) => {
    if (props.isWidget) {
        return props.widget?.options.size === 'medium' ? '80px' : '40px';
    } else {
        return props.size === 'medium' ? '80px' : '40px';
    }
};

export function Visual(props: TVisualProps<TVisualData, TVisualOption>) {
    const { isWidget } = props;
    const iconName = isWidget ? props.widget?.data?.iconName : props.iconName;
    const flagIcon = isWidget ? props.widget?.data?.flagIcon : props.flagIcon;
    const flagIconSmall = isWidget ? props.widget?.data?.flagIconSmall : props.flagIconSmall;
    const avatar = isWidget ? props.widget?.data?.avatar : props.avatar;
    const base64 = isWidget ? props.widget?.data?.base64 : '';
    const IconComponent = Svg[iconName as keyof typeof Svg] ?? Svg['ic_check' as keyof typeof Svg];
    const useFunction = isWidget ? props.hooks?.useFunction(props.widget) : undefined;

    const isDisabled = () => {
        if (isWidget) {
            return useFunction?.hasFunctionForAction(FunctionActionTypeEnum.ENABLED) ? !useFunction?.getFunctionResult(FunctionActionTypeEnum.ENABLED) : false;
        } else {
            return !(typeof props.isEnabled === 'undefined' || props.isEnabled);
        }
    };
    return (
        <VisualContainer
            data-test={isWidget ? props.widget?.uid : ''}
            $rightMargin={isWidget ? props.widget?.options.rightMargin : props.rightMargin}
            $marginBottom={isWidget ? props.widget?.options?.margin?.bottom : props.margin?.bottom}
            $marginTop={isWidget ? props.widget?.options?.margin?.top : props.margin?.top}
            $marginLeft={isWidget ? props.widget?.options?.margin?.left : props.margin?.left}
            $marginRight={isWidget ? props.widget?.options?.margin?.right : props.margin?.right}
            onClick={props.handleClick}
        >
            {iconName && (
                <IconContainer
                    $iconHierarchy={isWidget ? props.widget?.options.iconHierarchy : props.iconHierarchy}
                    $iconVariant={isWidget ? props.widget?.options.iconVariant : props.iconVariant}
                    $disabled={isDisabled()}
                    $size={isWidget ? props.widget?.options.size : props.size}
                >
                    <IconComponent />
                </IconContainer>
            )}
            {flagIcon && <FlagIcon name={flagIcon} size={getFlagSize(props)} isDisabled={isDisabled()} />}
            {(base64 || avatar) && (
                <AvatarContainer $size={isWidget ? props.widget?.options.size : props.size} $disabled={isDisabled()}>
                    <img src={base64 || avatar} alt={''} />
                </AvatarContainer>
            )}
            {flagIconSmall && (
                <BadgeContainer $size={isWidget ? props.widget?.options.size : props.size}>
                    <Badge flagIcon={flagIconSmall} size={isWidget ? props.widget?.options.size : props.size} disabled={isDisabled()} />
                </BadgeContainer>
            )}
        </VisualContainer>
    );
}
