import styled from 'styled-components';
import { media, zIndex } from '@core/theme';
import { WithPrefix } from '@core/types';

interface IModalProps {
    isVerticalCentered?: boolean;
}

type StyledComponentProps = WithPrefix<IModalProps>;

export const ModalContainer = styled.div`
    background-color: ${(props) => props.theme.white};
    bottom: 0;
    left: 0;
    min-width: 288px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: ${zIndex.modalIndex};
`;
export const ModalViewport = styled.div`
    background-color: ${(props) => props.theme.white};
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    overflow-x: hidden;
    overflow-y: auto;
`;
export const ModalBody = styled.div<StyledComponentProps>`
    padding: 72px 0 64px 0;
    margin: 0 auto;
    max-width: 100%;
    min-height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: ${(props) => (props.$isVerticalCentered ? 'center' : 'flex-start')};

    ${media.mobile481} {
        max-width: 480px;
    }
    ${media.tablet708} {
        padding: 104px 0;
        max-width: calc(((100% - 168px) / 6) * 4 + 120px);
    }
    ${media.tablet889} {
        max-width: 600px;
    }
`;
