import { EventTypeEnum, TUseAppHook, TUseDetectDocumentScroll } from '@core/types';
import { useEffect, useState } from 'react';
import { eventBus } from '@core/utils';
import { usePortal } from '../components/common/PortalWrapper';

export const useDetectDocumentScroll = (uid: string): TUseDetectDocumentScroll => {
    const [scrolled, setScrolled] = useState(false);
    useEffect(() => {
        eventBus.subscribe(EventTypeEnum.SCROLLED, uid, (value) => setScrolled(!!value));
        return () => eventBus.unsubscribe(EventTypeEnum.SCROLLED, uid);
    }, [uid]);
    return { documentScrolled: scrolled };
};
export const useApp = (): TUseAppHook => {
    const { setModal, setPopup, setBottomSheet } = usePortal();
    return { setModal, setPopup, setBottomSheet };
};
