import { ImageEnum, TGroupWidgetProps, TVisualData, TVisualOption } from '@core/types';
import React from 'react';
import { ProfileImage } from './src/profile/ProfileImage';
import { Visual } from './src/Visual';

const TypeImage: React.FC<TGroupWidgetProps<TVisualData, TVisualOption>> = ({ widgetId, wid, hooks }) => {
    const { widget } = hooks.useGetWidget(wid);
    switch (widgetId) {
        case ImageEnum.VISUAL:
            return <Visual isWidget={true} widget={widget} hooks={hooks} />;
        case ImageEnum.PROFILE_IMAGE:
            return <ProfileImage widget={widget} hooks={hooks} />;
        default:
            return false;
    }
};

export { Visual };
export default TypeImage;
