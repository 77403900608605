import { Visual } from '@consumer/type-image';
import { TButtonData, TButtonOption, TWidgetProps } from '@core/types';
import React, { ElementType } from 'react';
import { CommonButtonRow } from './styles/ButtonRow';

type TButtonRowWidget<TD, TO> = {
    isWidget: true;
} & TWidgetProps<TD, TO>;

type TButtonRowComponent = {
    isWidget: false;
    hierarchy?: 'primary' | 'destructive';
    iconHierarchy?: 'emphasised' | 'primary' | 'destructive';
    iconVariant?: 'simple' | 'circle';
    iconName?: string;
    label?: string;
    isEnabled?: boolean;
    handleClick?: React.MouseEventHandler;
    marginBottom?: number;
    marginTop?: number;
    marginLeft?: number;
    marginRight?: number;
};

type TButtonRow<TD, TO> = (TButtonRowWidget<TD, TO> | TButtonRowComponent) & {
    as?: ElementType;
};

export function ButtonRow(props: TButtonRow<TButtonData, TButtonOption>) {
    const { isWidget } = props;
    const label = isWidget ? props.widget?.data?.label : props.label;
    const hook = isWidget ? props.hooks.useButton(props.widget) : undefined;
    const iconName = isWidget ? props.widget?.options?.iconName : props.iconName;

    const isDisabled = () => (isWidget ? !(props.widget.options?.enabled ?? true) : props.isEnabled === false || false);

    return (
        <CommonButtonRow
            as={props.as}
            $hierarchy={isWidget ? props.widget?.options?.hierarchy : props.hierarchy}
            $disabled={isDisabled()}
            $size={isWidget && props.widget?.options?.size ? props.widget?.options?.size : 'small'}
            $alignment={isWidget ? props.widget?.options?.alignment : ''}
            $hasIcon={iconName}
            data-test={isWidget ? props.widget?.uid : ''}
            $marginBottom={isWidget ? props.widget?.options?.margin?.bottom : props.marginBottom}
            $marginTop={isWidget ? props.widget?.options?.margin?.top : props.marginTop}
            $marginLeft={isWidget ? props.widget?.options?.margin?.left : props.marginLeft}
            $marginRight={isWidget ? props.widget?.options?.margin?.right : props.marginRight}
            onClick={isWidget ? hook?.handleClick : props.handleClick}
        >
            {iconName && (
                <Visual
                    isWidget={false}
                    isEnabled={!isDisabled()}
                    iconName={iconName}
                    rightMargin
                    iconHierarchy={isWidget ? props.widget?.options?.iconHierarchy : props.iconHierarchy}
                    iconVariant={isWidget ? props.widget?.options?.iconVariant : props.iconVariant}
                />
            )}
            {label}
        </CommonButtonRow>
    );
}
