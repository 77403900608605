import { ActionActionTypeEnum, RowEnum, TItem, TContacts, TRowComponentProps, TWAction } from '@core/types';
import React from 'react';

export const actionsCall = <TFunc extends (a: TWAction) => void>(type: ActionActionTypeEnum, func: TFunc, actionsArr?: Array<TWAction>) => {
    if (Array.isArray(actionsArr) && actionsArr.length > 0) {
        const arrActions = actionsArr.filter((a) => a?.actionType === type);
        if (arrActions.length > 0) {
            arrActions.forEach((a) => {
                func(a);
            });
        }
    }
};
export const debounce = <TTimout extends React.MutableRefObject<NodeJS.Timeout | null>, TFunc extends (...args: Parameters<TFunc>) => void>(
    timeoutRef: TTimout,
    func: TFunc,
    waitFor: number,
) => {
    return (...arg: Parameters<TFunc>) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            func.apply(this, arg);
        }, waitFor);
    };
};

export const convertItemToRowComponentProps = (item: TItem, id?: RowEnum.ROW_REGULAR | RowEnum.SELECTOR): TRowComponentProps => {
    return {
        id: id ?? RowEnum.ROW_REGULAR,
        title: item.title,
        metaText: item.metaText,
        flagIcon: item.icon,
        iconName: item.iconName,
        enabled: item.enabled,
        iconHierarchy: item.iconHierarchy,
        iconVariant: item.iconVariant,
        isSelected: item.selected,
        avatar: item.avatar,
    };
};
export const convertContactsToRowComponentProps = (
    contacts: TContacts[],
    id: RowEnum.ROW_REGULAR | RowEnum.SELECTOR,
    ref: React.MutableRefObject<string | null>,
): TRowComponentProps[] => {
    return contacts.map((contact) => {
        const blob = contact.icon[0];
        let avatar,
            iconName = 'ic_person';
        if (blob && blob instanceof Blob) {
            avatar = URL.createObjectURL(blob);
            ref.current = avatar;
            iconName = '';
        }
        return {
            id,
            title: contact.name[0],
            subtitle: contact.tel[0],
            iconVariant: 'circle',
            iconHierarchy: 'secondary',
            iconName,
            avatar,
        };
    });
};
