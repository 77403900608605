import { App } from '../App';
import ErrorBoundary from '../components/ErrorBoundary';

const router = [
    {
        path: '/',
        element: <App />,
        errorElement: <ErrorBoundary />,
    },
    {
        path: '*',
        element: <>Not found</>,
    },
];

export default router;
