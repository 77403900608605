type TFormatUtilsOptions = {
    style: 'decimal' | 'currency' | 'percent' | 'unit';
    currencyDisplay?: 'code' | 'symbol' | 'narrowSymbol' | 'name';
    currencySign?: 'standard' | 'accounting';
    currency?: string;
    maximumFractionDigits?: number;
    minimumIntegerDigits?: number;
};

const getNumber = (amount?: number | string) => (amount ? Number(amount) : 0);
const _currencyFormat = (number: number, currencyISO: string, currencyDisplay: TFormatUtilsOptions['currencyDisplay']): string => {
    const options: TFormatUtilsOptions = {
        style: 'currency',
        currency: currencyISO,
        currencyDisplay: currencyDisplay,
    };
    return Intl.NumberFormat(navigator?.language, options).format(getNumber(number));
};
export const currencyFormat = (
    amount?: number | string,
    currencyISO?: string,
    currencyDisplay?: TFormatUtilsOptions['currencyDisplay'],
): string | Intl.NumberFormatPart[] => {
    return currencyISO ? _currencyFormat(getNumber(amount), currencyISO, currencyDisplay ?? 'narrowSymbol') : numberFormat(amount);
};
export const numberFormat = (amount?: number | string, maximumFractionDigits = 2, useFormatToParts = false): string | Intl.NumberFormatPart[] => {
    const options: TFormatUtilsOptions = {
        style: 'decimal',
        maximumFractionDigits: maximumFractionDigits,
    };
    if (useFormatToParts) {
        return Intl.NumberFormat(navigator?.language, options).formatToParts(getNumber(amount));
    }
    return Intl.NumberFormat(navigator?.language, options).format(getNumber(amount));
};
