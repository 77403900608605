import { ic_chevron_down as ChevronDownIcon } from '@consumer/assets';
import { ListSelect } from '@consumer/type-list';
import { InputWrapper, Modal, TextCommon } from '@consumer/ui';
import {
    RowEnum,
    TDropdownData,
    TDropdownOption,
    THooks,
    TInputOption,
    TItem,
    TListData,
    TListOption,
    TUseAppHook,
    TUseListHook,
    TWidget,
    TWidgetProps,
} from '@core/types';
import { ConditionalRender } from '@core/utils';
import React, { useId } from 'react';
import { useTheme } from 'styled-components';

const InputDropdown = ({ widget, hooks }: TWidgetProps<TDropdownData, TDropdownOption>) => {
    const id = useId();
    const theme = useTheme();
    const { data, options, uid } = widget;
    const { handleClickDropdown, isFilled, isError, value } = hooks.useInput(widget);
    const { setModal, setBottomSheet } = hooks.useApp();
    const useFunction = hooks.useFunction(widget);
    const hasItems = Array.isArray(widget.data.items) && widget.data.items.length > 0;

    const useApp = (): TUseAppHook => {
        return { setModal, setBottomSheet };
    };

    const useList = (widget: TWidget<TListData, TInputOption>): TUseListHook => {
        return {
            handleClick: (_wid: string, item: TItem) => {
                if (hasItems && item) {
                    setModal(null);
                    handleClickDropdown(item);
                }
            },
            isVisible: true,
        };
    };
    const getTitleByValue = (selectedValue?: string): string => {
        if (selectedValue) {
            const title = data?.items?.filter((i) => i.value === selectedValue)[0]?.title;
            return title ?? value;
        }
        return '';
    };

    const modalContent = (
        <Modal isCanCloseModal={true} onHandleClick={() => setModal(null)} hooks={hooks}>
            <ConditionalRender condition={!!data.label}>
                <TextCommon options={{ variant: 'header', margin: { top: 16, left: 24, right: 24, bottom: 32 } }} content={data.label} />
            </ConditionalRender>
            <ListSelect
                rowId={RowEnum.ROW_REGULAR}
                widget={widget as TWidget<TListData, TListOption>}
                hooks={{ ...hooks, useApp, useList } as unknown as THooks<TListData, TListOption>}
                isOpenInModal={true}
            />
        </Modal>
    );

    return (
        <InputWrapper
            isDropdownInput
            htmlFor={id}
            useFunction={useFunction}
            labelText={data?.label}
            isError={isError}
            isFilled={isFilled}
            helperText={data?.helperText}
            isDisabled={options?.isDisabled}
            marginTop={options?.margin?.top}
            marginRight={options?.margin?.right}
            marginBottom={options?.margin?.bottom}
            marginLeft={options?.margin?.left}
            rightInputComponent={<ChevronDownIcon fill={options?.isDisabled ? theme.grey_40_lc : theme.black} width={24} height={24} />}
        >
            <input id={id} value={getTitleByValue(value)} readOnly={true} onClick={() => setModal(modalContent)} data-test={uid} type="text" />
        </InputWrapper>
    );
};
export { InputDropdown };
