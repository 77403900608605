import { EventTypeEnum, TCommonData, TLayout, TLayoutOption, TWidgetProps, WithPrefix } from '@core/types';
import { eventBus } from '@core/utils';
import React, { useLayoutEffect, useRef, useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { ISeparateLayoutProps } from '../interface/interface';

type TSeparateLayoutProps = {
    topWidget: React.ReactNode;
    bottomWidget: React.ReactNode;
} & TWidgetProps<TCommonData, TLayoutOption> &
    TLayout;

type StyledComponentProps = WithPrefix<ISeparateLayoutProps>;

const LayoutContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;
const Overlay = styled.div<StyledComponentProps>`
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    background-color: ${(props) => props.theme.black_48_tr_base};
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: -1;

    ${(props) => {
        if (props.$animationInProgress === 1 || props.$openedBottomLayout) {
            return css`
                opacity: 1;
                z-index: auto;
            `;
        }
    }}
`;
const LayoutBottom = styled.div<StyledComponentProps>`
    padding-bottom: 96px;
    border-radius: 12px 12px 0 0;
    overflow-y: ${(props) => (props.$animationInProgress === 0 ? 'auto' : 'hidden')};
    overflow-x: hidden;
    background-color: ${(props) => props.theme.white};
    transition: top 0.3s ease-out;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: ${(props) => `${props.$top}px`};

    &::-webkit-scrollbar {
        display: none;
    }

    &.bottom-move {
        top: 64px;
        overscroll-behavior: contain;
    }
`;

const LayoutBottomInner = styled.div`
    min-height: calc(100vh - 60px);
`;
const LayoutBottomHandler = styled.div`
    width: 100%;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
        content: '';
        display: inline-block;
        width: 32px;
        height: 4px;
        border-radius: 4px;
        background-color: ${(props) => props.theme.grey_16_tr};
    }
`;

const SeparateLayoutTransition = ({ widget, topWidget, bottomWidget }: TSeparateLayoutProps) => {
    const [classBottom, setClassBottom] = useState('');
    const [openedBottomLayout, setOpenedBottomLayout] = useState(false);
    const [overlayShouldBeClosed, setOverlayShouldBeClosed] = useState(false);
    const [topPosition, setTopPosition] = useState<number>();
    // animationInProgress: 0 - no animation, 1 - opening, -1 - closing
    const [animationInProgress, setAnimationInProgress] = useState(0);
    const ref = useRef<HTMLDivElement | null>(null);
    const refStartAnimation = useRef(false);
    const localTheme = useTheme();
    const scrollStep = 0.5;

    useLayoutEffect(() => {
        document.body.style.background = localTheme.grey_8_lc;
        const upContainer = document?.getElementById('top-container');
        const rect = upContainer?.getBoundingClientRect();
        const bottomLayoutPosition = rect?.bottom;
        setTopPosition(bottomLayoutPosition);

        return () => {
            document.body.style.background = localTheme.white;
        };
    }, [localTheme.grey_8_lc, localTheme.white]);

    const openBottomLayout = () => {
        refStartAnimation.current = true;
        if (animationInProgress === 0) {
            setOpenedBottomLayout(true);
            setOverlayShouldBeClosed(true);
        }
        setAnimationInProgress(1);
        setClassBottom('bottom-move');
        eventBus.emit(EventTypeEnum.SIZE, widget.internalId, 'fullScreen');
    };

    const closeBottomLayout = () => {
        refStartAnimation.current = true;
        setClassBottom('');
        setAnimationInProgress(-1);
        setOpenedBottomLayout(false);
        if (ref.current) {
            ref.current.scrollTop = 0;
        }
        eventBus.emit(EventTypeEnum.SIZE, widget.internalId, 'notFullScreen');
    };

    const resetScroll = () => {
        if (ref.current) {
            ref.current.scrollTop = scrollStep;
        }
    };

    const onScroll = () => {
        if (!refStartAnimation.current) {
            if (!openedBottomLayout) {
                openBottomLayout();
                return;
            }
            if (ref?.current?.scrollTop === 0) {
                if (overlayShouldBeClosed) {
                    closeBottomLayout();
                    return;
                }
                resetScroll();
                setOverlayShouldBeClosed(true);
            } else {
                if (ref.current && ref.current.scrollTop > scrollStep) {
                    setOverlayShouldBeClosed(false);
                }
            }
        }
    };

    const handleClickOutside = () => {
        if (animationInProgress === 0) {
            closeBottomLayout();
        }
    };

    return (
        <LayoutContainer data-test={widget.uid}>
            <Overlay $animationInProgress={animationInProgress} $openedBottomLayout={openedBottomLayout} onClick={handleClickOutside} />
            <div id="top-container">{topWidget}</div>
            <LayoutBottom
                id="bottom-container"
                ref={ref}
                onScroll={onScroll}
                className={`${classBottom}`}
                $animationInProgress={animationInProgress}
                $top={topPosition}
                onTransitionEnd={() => {
                    setAnimationInProgress(0);
                    refStartAnimation.current = false;
                }}
            >
                <LayoutBottomInner>
                    <LayoutBottomHandler />
                    {bottomWidget}
                </LayoutBottomInner>
            </LayoutBottom>
        </LayoutContainer>
    );
};

export { SeparateLayoutTransition };
