export type WidgetEnum =
    | ButtonEnum
    | LayoutEnum
    | TextEnum
    | TextInputEnum
    | CheckboxEnum
    | LottieEnum
    | DropdownEnum
    | AnimationIconEnum
    | ListEnum
    | RowEnum
    | CalculatorEnum
    | SeparatorEnum
    | CarouselEnum
    | ActionWidgetEnum
    | StoriesEnum
    | ImageEnum;

export enum ButtonEnum {
    BUTTON_PILL = 'ButtonPill',
    BUTTON_CIRCLE = 'ButtonCircle',
    BUTTON_ROW = 'ButtonRow',
    TIMER_BUTTON = 'TimerButton',
}

export enum LayoutEnum {
    VERTICAL_LAYOUT = 'VerticalLayout',
    VERTICAL_LAYOUT_WIDE = 'VerticalLayoutWide',
    HORIZONTAL_LAYOUT = 'HorizontalLayout',
    FLOATING_LAYOUT = 'FloatingLayout',
    SEPARATE_LAYOUT = 'SeparateContentLayout',
    BOTTOM_SHEET = 'BottomSheet',
    CALCULATOR_CONTENT_LAYOUT = 'CalculatorContentLayout',
}

export enum LottieEnum {}

export enum StoriesEnum {
    STORIES = 'Stories',
}

export enum TextEnum {
    TEXT = 'Text',
    TEXT_LINKED = 'LinkedText',
    BALANCE = 'Balance',
}

export enum CheckboxEnum {
    SWITCHER = 'Switcher',
    CHECKBOX = 'Checkbox',
}

export enum TextInputEnum {
    STROKED = 'Stroked',
    PHONE_TEXT_INPUT = 'PhoneTextInput',
    DATE_MASK_TEXT_INPUT = 'DateMaskedTextInput',
    AUTO_SUBMIT_TEXT_INPUT = 'AutoSubmitTextInput',
    ENTERPASSCODE = 'EnterPasscode',
}

export enum ActionWidgetEnum {
    ACTION_WIDGET = 'ActionWidget',
}
export enum DropdownEnum {
    DROPDOWN = 'Dropdown',
}

export enum ListEnum {
    SELECT = 'Select',
    TRANSACTIONS = 'TransactionsList',
    CURRENCY = 'CurrencyList',
    TEMPLATE = 'TemplateList',
    PROFILE_MENU = 'ProfileMenu',
    CONTACTS = 'ContactsList',
}

export enum RowEnum {
    ROW_REGULAR = 'RowRegular',
    SELECTOR = 'Selector',
}

export enum CalculatorEnum {
    CALCULATOR = 'Calculator',
}

export enum ActionTypeEnum {
    Scenario = 'Scenario',
    Player = 'Player',
    Widget = 'Widget',
}

export enum ActionActionTypeEnum {
    onClick = 'onClick',
    onHover = 'onHover',
    onRedirect = 'onRedirect',
    onFilled = 'onFilled',
    onTimer = 'onTimer',
    onRefresh = 'onRefresh',
    onEndOfList = 'onEndOfList',
    onItemSelected = 'onItemSelected',
    onCalculate = 'onCalculate',
    onUpdateProfilePhoto = 'onUpdateProfilePhoto',
    onChangeDeliveryOptions = 'onChangeDeliveryOptions',
    onChangeTransferOptions = 'onChangeTransferOptions',
}

export enum AnimationIconEnum {
    Success = 'Success',
    Error = 'Error',
}

export enum FunctionActionTypeEnum {
    HIDE = 'hide',
    SHOW = 'show',
    ENABLED = 'enabled',
    DISABLED = 'disabled',
    HIDDEN = 'hidden',
}

export enum CarouselEnum {
    CAROUSEL = 'Carousel',
}

export enum SeparatorEnum {
    SEPARATOR = 'Separator',
}

export enum ImageEnum {
    VISUAL = 'Visual',
    PROFILE_IMAGE = 'ProfileImage',
}
