const breakpointsValues = ['320px', '481px', '708px', '889px', '1440px', '1920px'];

export default {
    mobile320: breakpointsValues[0],
    mobile481: breakpointsValues[1],
    tablet708: breakpointsValues[2],
    tablet889: breakpointsValues[3],
    desktop1440: breakpointsValues[4],
    desktop1920: breakpointsValues[5],
};
