import { ActionActionTypeEnum, TInputData, TInputOption, TWAction, TWidget } from '@core/types';
import { actionsCall } from '@core/utils';

export const autoSubmitInputValidation = (value: string, input: TWidget<TInputData, TInputOption>, func: (a: TWAction) => void): void => {
    const { data, options } = input;
    const submitLength = data?.submitLength ?? options?.maxLength;
    if (value && submitLength && value.length === submitLength && input?.actions) {
        actionsCall(ActionActionTypeEnum.onFilled, func, input.actions);
    }
};
