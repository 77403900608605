import React, { useState, useDeferredValue, Suspense, useMemo } from 'react';
import ListItems, { TListItemProps } from '../ListItems';
import InputSearch from '../InputSearch';
import { TItem, TListData, TListOption, TWidgetProps } from '@core/types';
import { Loading } from '@consumer/ui';
import { NotFoundContainer } from '../styles';

type TListSearchProps = {
    closeModal: () => void;
    renderItem: React.FC<TListItemProps>;
} & TWidgetProps<TListData, TListOption>;

const filterBySearch = (search: string, items?: TItem[]) => {
    if (items) {
        const result = [];
        for (const item of items) {
            const byAlias = item.alias?.find((a) => a.toLowerCase().indexOf(search.toLowerCase()) >= 0);
            const byTitle = item.title?.toLowerCase().indexOf(search.toLowerCase()) >= 0;
            if (byAlias || byTitle) {
                const reg = new RegExp(`(${search})`, 'gi');
                const title = item.title.replace(reg, `<mark>$1</mark>`);
                result.push({
                    ...item,
                    title,
                });
            }
        }
        return result;
    }
    return [];
};
const ListSearch = ({ widget, renderItem, closeModal, hooks }: TListSearchProps) => {
    const { data } = widget;
    const [query, setQuery] = useState('');
    const [isFocused, setFocused] = useState(true);
    const deferredQuery = useDeferredValue(query);
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setQuery(e.target.value);

    const items = useMemo(() => (deferredQuery ? filterBySearch(deferredQuery, data?.items) : []), [data?.items, deferredQuery]);

    return (
        <>
            <InputSearch
                widget={widget}
                asButton={false}
                value={query}
                isFocused={isFocused}
                handleFocus={() => setFocused(true)}
                handleBlur={() => setFocused(false)}
                handleChange={handleChange}
                closeModal={closeModal}
                handleClickRightComponent={() => setQuery('')}
                hooks={hooks}
            />
            <Suspense fallback={<Loading />}>
                {!!deferredQuery && items.length === 0 && (
                    <NotFoundContainer>
                        <h2>{data?.emptySearchResultLabel}</h2>
                    </NotFoundContainer>
                )}
                {items.length > 0 && <ListItems items={items} renderItem={renderItem} />}
            </Suspense>
        </>
    );
};
export default ListSearch;
