import { ic_arrow_back as ChevronArrowBackIcon, ic_search as SearchIcon, ic_x_circle as CrossRoundedIcon } from '@consumer/assets';
import { InputWrapper } from '@consumer/ui';
import { TListData, TListOption, TWidgetProps } from '@core/types';
import React, { ChangeEvent, ChangeEventHandler, FocusEventHandler, MouseEvent, MouseEventHandler, useId } from 'react';
import { useTheme } from 'styled-components';
import { ClearIcon, IconWrapper } from './styles';

const focus = (e: MouseEvent<HTMLElement>) => {
    if (e.currentTarget) {
        const input = e.currentTarget.firstElementChild as HTMLElement;
        if (input) {
            input.focus();
        }
    }
};
type TOwnProps = {
    closeModal?: () => void;
    marginTop?: number;
    marginLeft?: number;
    marginRight?: number;
    marginBottom?: number;
};
type TInputSearchProps<TFunc = ChangeEventHandler> = (
    | {
          asButton: true;
          handleClick: MouseEventHandler;
      }
    | {
          asButton: false;
          value: string;
          handleChange?: TFunc;
          handleBlur?: FocusEventHandler;
          handleFocus?: FocusEventHandler;
          handleClickRightComponent?: MouseEventHandler;
          isFocused?: boolean;
      }
) &
    TOwnProps &
    TWidgetProps<TListData, TListOption>;
const InputSearch = <T extends (e: ChangeEvent<HTMLInputElement>) => void>({ widget, closeModal, ...props }: TInputSearchProps<T>) => {
    const { options, uid } = widget;
    const inputId = useId();
    const theme = useTheme();
    const isShowRightInputComponent = !props.asButton && props.value.length > 0;
    const placeholder = options.searchPlaceholder;

    return (
        <InputWrapper
            htmlFor={inputId}
            setFocus={focus}
            marginTop={props.marginTop ?? options?.margin?.top}
            marginRight={props.marginRight ?? options?.margin?.right}
            marginBottom={props.marginBottom ?? options?.margin?.bottom}
            marginLeft={props.marginLeft ?? options?.margin?.left}
            leftInputComponent={
                <IconWrapper>
                    {props.asButton ? <SearchIcon fill={theme.grey_72_lc} /> : <ChevronArrowBackIcon fill={theme.black} onClick={closeModal} />}
                </IconWrapper>
            }
            rightInputComponent={
                isShowRightInputComponent ? (
                    <ClearIcon>
                        <CrossRoundedIcon onClick={props.handleClickRightComponent} fill={theme.grey_72_lc} width={24} height={24} />
                    </ClearIcon>
                ) : null
            }
        >
            {props.asButton ? (
                <input placeholder={placeholder} type="search" data-test={uid} onClick={props.handleClick} />
            ) : (
                <input
                    id={inputId}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    onFocus={props.handleFocus}
                    value={props.value}
                    placeholder={placeholder}
                    type="search"
                    size={100}
                    autoComplete="off"
                    data-test={uid}
                    autoFocus={props.isFocused}
                />
            )}
        </InputWrapper>
    );
};
export default InputSearch;
