import { TextCommon } from '@consumer/ui';
import { TBalanceData, TextEnum, TGroupWidgetProps, THooks, TTextData, TTextOption } from '@core/types';
import React from 'react';
import Balance from './src/Balance';
import { TextLinked } from './src/TextLinked';

const TypeText: React.FC<TGroupWidgetProps<TTextData & TBalanceData, TTextOption>> = ({ widgetId, wid, hooks }) => {
    const { widget } = hooks.useGetWidget(wid);
    const { data, options, uid } = widget;
    switch (widgetId) {
        case TextEnum.BALANCE:
            return <Balance widget={widget} hooks={hooks as unknown as THooks<TBalanceData>} />;
        case TextEnum.TEXT:
            return <TextCommon options={options} content={data?.text} uid={uid} />;
        case TextEnum.TEXT_LINKED:
            return <TextLinked options={options} data={data} uid={uid} />;
        default:
            return false;
    }
};
export default TypeText;
