import { ic_check as CheckIcon } from '@consumer/assets';
import { TextCommon } from '@consumer/ui';
import { FunctionActionTypeEnum, TCheckboxOption, TInputData, TWidgetProps, WithPrefix } from '@core/types';
import React, { useId } from 'react';
import styled, { useTheme } from 'styled-components';
import { WidgetContainer } from './styles';

interface ICheckboxProps {
    isDisabled?: boolean;
}

type StyledComponentProps = WithPrefix<ICheckboxProps>;

const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
    opacity: 0;
    position: absolute;
    z-index: -1;
`;
const CheckboxIndicator = styled.span`
    border: 2px solid ${(props) => props.theme.grey_40_lc};
    border-radius: 3px;
    display: inline-block;
    height: 20px;
    width: 20px;
    min-width: 20px;
    box-sizing: border-box;
    position: relative;
`;
const CheckboxTickWrap = styled.span`
    position: absolute;
    left: -2px;
    top: -2px;
    display: none;
`;
const CheckboxLabel = styled.label<StyledComponentProps>`
    display: inline-flex;
    align-items: center;
    cursor: ${(props) => (props.$isDisabled ? 'default' : 'pointer')};

    &:hover {
        ${CheckboxIndicator} {
            border-color: ${(props) => props.theme.grey_72_lc};
        }

        ${CheckboxInput}:checked + ${CheckboxIndicator} {
            background: ${(props) => props.theme.purple_72_lc};
            border-color: ${(props) => props.theme.purple_72_lc};
        }
    }

    ${CheckboxInput}:checked + ${CheckboxIndicator} {
        background: ${(props) => props.theme.purple_100};
        border-color: ${(props) => props.theme.purple_100};

        ${CheckboxTickWrap} {
            display: inline-block;
        }
    }

    ${CheckboxInput}[disabled] + ${CheckboxIndicator} {
        border-color: ${(props) => props.theme.grey_8_lc};
    }

    ${CheckboxInput}[disabled]:checked + ${CheckboxIndicator} {
        background: ${(props) => props.theme.purple_40_tr};
        border-color: transparent;
    }
`;

const Checkbox = ({ widget, hooks }: TWidgetProps<TInputData, TCheckboxOption>) => {
    const id = useId();
    const localTheme = useTheme();
    const { data, uid, options } = widget;
    const { handleChange, isChecked } = hooks.useCheckBox(widget);
    const { getFunctionResult, hasFunctionForAction } = hooks.useFunction(widget);
    const hideInput = hasFunctionForAction(FunctionActionTypeEnum.HIDE) ? getFunctionResult(FunctionActionTypeEnum.HIDE) : false;
    const isDisabled = hasFunctionForAction?.(FunctionActionTypeEnum.DISABLED)
        ? getFunctionResult?.(FunctionActionTypeEnum.DISABLED)
        : !(options.enabled ?? true);

    return (
        <WidgetContainer
            $hide={hideInput}
            $marginBottom={options?.margin?.bottom}
            $marginTop={options?.margin?.top}
            $marginLeft={options?.margin?.left}
            $marginRight={options?.margin?.right}
            data-test={uid}
        >
            <CheckboxLabel htmlFor={id} $isDisabled={isDisabled}>
                <CheckboxInput id={id} name={data?.label} type="checkbox" onChange={handleChange} checked={isChecked} disabled={isDisabled} />
                <CheckboxIndicator>
                    <CheckboxTickWrap>
                        <CheckIcon fill={localTheme.white_base} width={20} height={20} />
                    </CheckboxTickWrap>
                </CheckboxIndicator>
                {widget?.props?.label && (
                    <TextCommon content={widget?.props?.label} options={{ textColor: isDisabled ? 'grey_16_lc' : 'grey_72_lc', margin: { left: 16 } }} />
                )}
            </CheckboxLabel>
        </WidgetContainer>
    );
};
export { Checkbox };
