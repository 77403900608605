import React from 'react';
import { TextCommon } from '@consumer/ui';
import { FeePanelBlock, FeePanelBlockBottom } from '../styles';

type TFeeBlockProps = {
    title?: string;
    text?: string;
    rate?: string;
    icon?: React.ReactNode;
};

const FeeBlock = React.memo(({ title, text, rate, icon }: TFeeBlockProps) => {
    return (
        <FeePanelBlock>
            <TextCommon content={title} options={{ variant: 'secondary', textColor: 'grey_72_lc', textAlign: 'center', margin: { bottom: 8 } }} />
            <FeePanelBlockBottom>
                <TextCommon content={text} options={{ variant: 'secondary', textColor: 'black', textAlign: 'center', margin: { right: 3 } }} />
                <TextCommon content={rate?.toLowerCase()} options={{ variant: 'secondary', textColor: 'black', textAlign: 'center', smallCaps: true }} />
                {icon}
            </FeePanelBlockBottom>
        </FeePanelBlock>
    );
});

export { FeeBlock };
