import { TCommonDataObj, TItem, TCalculatorError } from '@core/types';

export enum InputEnum {
    FROM_INPUT = 'fromInput',
    TO_INPUT = 'toInput',
}
export enum CurrencyDropdownEnum {
    FROM_CURRENCY = 'fromCurrency',
    TO_CURRENCY = 'toCurrency',
}
export type TName = CurrencyDropdownEnum.TO_CURRENCY | CurrencyDropdownEnum.FROM_CURRENCY;
export type TServerCalculationFunc = (value: string, name: string, isFrom: boolean) => void;
export type THandleUpdateValue = (clearValue: string, name: string, isFromFlag: boolean) => void;
export const getDataValueFromWidget = (value: TCommonDataObj) => {
    const fromInputValue = value?.fromInput ?? '0';
    const toInputValue = value?.toInput ?? '0';
    const fromCurrencyValue = value?.fromCurrency ?? '';
    const toCurrencyValue = value?.toCurrency ?? '';
    return {
        fromInputValue,
        toInputValue,
        fromCurrencyValue,
        toCurrencyValue,
    } as Record<string, string>;
};

type TResult = {
    fromCurrencyList: TItem[];
    toCurrencyList: TItem[];
};
export const getListsCurrency = (list: TItem[]) => {
    const result: TResult = {
        fromCurrencyList: [],
        toCurrencyList: [],
    };
    if (list && Array.isArray(list) && list.length > 0) {
        return list.reduce((acc: typeof result, item: TItem) => {
            if (item.from) {
                acc.fromCurrencyList.push(item);
            }
            if (item.to) {
                acc.toCurrencyList.push(item);
            }
            return acc;
        }, result);
    }
    return result;
};
export const getCurrency = (value: string, list: TItem[]) => {
    const defaultResult = {
        currency: undefined,
        currencyIndex: 0,
    };
    if (value && list && Array.isArray(list) && list.length > 0) {
        const currencyIndex = list.findIndex((currency, index) => currency.id === value);
        if (currencyIndex !== -1) {
            return {
                currency: list[currencyIndex],
                currencyIndex,
            };
        }
        return defaultResult;
    }
    return defaultResult;
};
export type TErrorsResult = {
    fromInput?: TCalculatorError;
    toInput?: TCalculatorError;
};
export const getErrors = (errors: TCalculatorError[]) => {
    const result = {
        fromInput: undefined,
        toInput: undefined,
    };
    if (errors.length > 0) {
        return errors.reduce((acc: TErrorsResult, error: TCalculatorError) => {
            if (error.from) {
                acc.fromInput = error;
            } else {
                acc.toInput = error;
            }
            return acc;
        }, result);
    }
    return result;
};
